import React, { useState , createContext } from 'react';
import axios from "axios";

import AuthService from './../Services/AuthService';


export const GEHerdPopulationContext = createContext()

const GEHerdPopulationContextProvider = (props) => {
    var populationDs = {};
    const [populationCombo,setPopulationCombo] = useState({});
    const [HerdIdPopulation,setHerdIdPopulation] = useState();


    const [evalDate,setEvalDate] = useState('');

    const authservice = new AuthService();

    React.useEffect(async () => {


        var authuser = await authservice.AuthGetUser();

        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authuser.access_token
          };

          var EvalDate = await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/EvaluationDate`,{headers});

          setEvalDate(`${EvalDate.data.month} ${EvalDate.data.year}`);
        

        if(typeof HerdIdPopulation !== 'undefined' && HerdIdPopulation != null){
            
            
            await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/HerdReport/population?HerdId=${HerdIdPopulation}`,{headers}).then(res => {
            
                
                setPopulationCombo(res.data.data);

                
            
            });
        }
        
    },[HerdIdPopulation]);


    return ( 

        <GEHerdPopulationContext.Provider

            value={{
                
                populationCombo,
                setPopulationCombo,
                populationDs,
                HerdIdPopulation,
                setHerdIdPopulation,
                evalDate

            }}
        
        >
            {props.children}
        </GEHerdPopulationContext.Provider>

     );
}
 
export default GEHerdPopulationContextProvider;
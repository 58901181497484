import React , {useContext} from 'react';
import DifferemceProgress from '../Components/DifferenceProgress';
import HerdCombo from '../Components/HerdCombo';
import NormalDistributionDash from '../Components/NormalDistributionDash';
import { AuthContext } from '../Context/AuthContext';
import { GEHerdDistributionContext } from '../Context/GEHerdDistributionContext';
import { GEHerdPopulationContext } from '../Context/GEHerdPopulationContext';
import { GEHerdProgressContext } from '../Context/GEHerdProgressContext';
import { Box, CircularProgress} from '@mui/material';




const TableData= [

    {"index":"LNM"},
    {"index":"LFM"},
    {"index":"MILK"},
    {"index":"PL"}

];

const GE_HerdDashboard = () => {


    const {userInfo} = useContext(AuthContext);


    const { populationCombo,setHerdIdPopulation,evalDate } = useContext(GEHerdPopulationContext);
    setHerdIdPopulation(userInfo.Herd);




    const {normDistribution,setHerdIdDistributionDash} = useContext(GEHerdDistributionContext);
    setHerdIdDistributionDash(userInfo.Herd);

    const {progress,years,setHerdIdProgrress} = useContext(GEHerdProgressContext);
    setHerdIdProgrress(userInfo.Herd);
    
    

    
    



    return ( 

        <>


            {Object.keys(populationCombo).length !== 0 ? <HerdCombo combination={populationCombo.combination} means={populationCombo.means} evalDate={evalDate}/> 
            : 
            <Box textAlign='center'>
                <CircularProgress disableShrink />
            </Box>}


            {Object.keys(normDistribution).length !== 0 ? <NormalDistributionDash normalDistribution={normDistribution} evalDate={evalDate}/> 
            : 
            <Box textAlign='center'>
                <CircularProgress disableShrink />
            </Box>}
        
            {Object.keys(progress).length !== 0 ? <DifferemceProgress TableData = {TableData} ProgressDs={progress} years={years} evalDate={evalDate}/> 
            : 
            <Box textAlign='center'>
                <CircularProgress disableShrink />
            </Box>}

        </>

     );
}
 
export default GE_HerdDashboard;
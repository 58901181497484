import React, { useState , createContext, useEffect } from 'react';
import axios from "axios";
import AuthService from './../Services/AuthService';

export const GEYearLactContext = createContext();

const GEYearLactContextProvider = (props) => {

  const authservice = new AuthService();

    const [yearLacIndexSelect,setYearLacIndexSelect] = useState("NET_MERIT");
    const [yearLacIndexList,setYearLacIndexList] = useState([]);

    const [HerdIdYearLac,setHerdIdHerdIdYearLac] = useState();

    useEffect( async () => {

      var authuser = await authservice.AuthGetUser();

      const headers = {
          Accept: 'application/json',
          Authorization: 'Bearer ' + authuser.access_token
      };

      await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/ReportConfiguration?section=BirthYearLactationReport`,{headers}).then(res => {
          setYearLacIndexList(res.data.activeIndex);
          setYearLacIndexSelect(res.data.activeIndex[0]);
        });
    }, []);


    const [yearLacYearsList, setYearLacYearsList] = useState([]);
    const [yearLacYearSelect,setYearLacYearSelect] = useState("ALL");

    const [evalDate,setEvalDate] = useState('');

    useEffect( async () => {

      


      if(typeof HerdIdYearLac !== 'undefined' && HerdIdYearLac != null){

        var authuser = await authservice.AuthGetUser();

        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authuser.access_token
        };

        var EvalDate = await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/EvaluationDate`,{headers});

        setEvalDate(`${EvalDate.data.month} ${EvalDate.data.year}`);

        await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/HerdReport/herdAllBirthYear?HerdId=${HerdIdYearLac}`,{headers}).then(res => {
          
          setYearLacYearsList(res.data);
          setYearLacYearSelect("ALL");
  
        });
      }
        
      }, [HerdIdYearLac]);


    const [yearLacData,setYearLacData] = useState([]);

    useEffect( async () => {

      

      if(typeof HerdIdYearLac !== 'undefined' && HerdIdYearLac != null){

        var authuser = await authservice.AuthGetUser();

        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authuser.access_token
        };

        await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/HerdReport/herdAllBirthYear?HerdId=${HerdIdYearLac}`,{headers}).then(res => {
  
          setYearLacYearsList(res.data);
          setYearLacYearSelect("ALL");
  
        });
      }

        
      }, [HerdIdYearLac]);



    const getDataset = async (Id,index,year) => {
        
      var authuser = await authservice.AuthGetUser();

      const headers = {
          Accept: 'application/json',
          Authorization: 'Bearer ' + authuser.access_token
      };

      //check for null values
      if(Id !== undefined && index != undefined && year !== undefined)
      {
        await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/HerdReport/birthyearlactation?HerdId=${Id}&Index=${index}&Year=${year}`,{headers}).then(res => {
            setYearLacData(res.data);
        });
      }
    }


    React.useEffect(() => {

        getDataset(HerdIdYearLac,yearLacIndexSelect,yearLacYearSelect);

    },[yearLacIndexSelect,yearLacYearSelect,HerdIdYearLac]);


    

    const handleChangeYearLacIndex = (e) => {
        setYearLacIndexSelect(e.target.value);

      }
  
  
      const handleChangeYearLacYears = (e) => {
        setYearLacYearSelect(e.target.value);
      }


    return ( 

        <GEYearLactContext.Provider

            value={{
                yearLacData,
                yearLacIndexSelect,
                setYearLacIndexSelect,
                yearLacIndexList,
                yearLacYearsList,
                yearLacYearSelect,
                setYearLacYearSelect,
                getDataset,
                handleChangeYearLacIndex,
                handleChangeYearLacYears,
                setHerdIdHerdIdYearLac,
                evalDate
            }}
        
        >
            {props.children}
        </GEYearLactContext.Provider>

     );

}

export default GEYearLactContextProvider;
import { Container, Divider, Grid, Paper, Stack, Table, Typography } from '@mui/material';
import React, { useContext , useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';



import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';

const populationPercent = (partial,total) => {
  var perc = (100 * partial) / total;
  return perc.toFixed(2);
}
  
const HerdCombo = (props) => {

  var evalD = props.evalDate;

  const [chartDataTableOpen,setChartDataTableOpen] = useState(false);

  const exportToExcel = () => {
    const table = document.getElementById('combochart');
    const worksheet = utils.table_to_sheet(table);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = write(workbook, { type: 'array', bookType: 'xlsx' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, 'Poplation_Combinations.xlsx');
  };

  useEffect(() => {

    const buttonElement = document.getElementById('combochartExport');
    if (buttonElement) {
      buttonElement.addEventListener('click', exportToExcel);
    }

  },[chartDataTableOpen]);

  var colorPalette = ['#00b894', '#157A65', '#f39c12','#00a8ff', '#3498db', '#0097e6','#2980b9', '#0652DD']

  const herdComboPieOptions = {
    title: {
      right:'right',
      text: 'ترکیب جمعیت {a|            }',
      subtext: evalD+' ارزیابی ',
      textStyle:{
  
    
        rich: {

          a: {
              backgroundColor: {
                  image: './logo.png'
              },
              height: 50
          },

          
        }
      }
    },
    toolbox: {
      left:'auto',
      show: true,
      feature: {
        dataView: {
          readOnly: false,
          optionToContent: function(opt){

            var series = opt.series;

            var totalPopulation = series[0].data[0].value + 
                                  series[0].data[1].value + 
                                  series[0].data[2].value +
                                  series[0].data[3].value +
                                  series[0].data[4].value +
                                  series[0].data[5].value +
                                  series[0].data[6].value +
                                  series[0].data[7].value ;
            

            setChartDataTableOpen(true);
            
            var table = "<button id='combochartExport'> Export excel </button>";
             
            table += '<table style="width:100%;text-align:center;" id="combochart" ref={tableRef}><tbody><tr>'
                   + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;">گروه شکمی</th>'
                   + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;">جمعیت</th>'
                   + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;"> LNM$ </th>'
                   + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;"> LFM$ </th>'
                   + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;"> MILK </th>'
                   + '</tr>'
            
                   +'<tr style="background:#E7EAEF">'
                   + '<td>' + series[0].data[0].name + '</td>'
                   + '<td>' + series[0].data[0].value + ' ( '+ populationPercent(series[0].data[0].value,totalPopulation) + ' % )</td>'
                   + '<td>' + props.means[8].lnmMean.toFixed(1) + '</td>'
                   + '<td>' + props.means[8].lfmMean.toFixed(1) + '</td>'
                   + '<td>' + props.means[8].milk.toFixed(1) + '</td>'
                   + '</tr>'

                   +'<tr style="background:#E7EAEF">'
                   + '<td>' + series[0].data[1].name + '</td>'
                   + '<td>' + series[0].data[1].value+ ' ( '+ populationPercent(series[0].data[1].value,totalPopulation)  + ' %  )</td>'
                   + '<td>' + props.means[9].lnmMean.toFixed(1) + '</td>'
                   + '<td>' + props.means[9].lfmMean.toFixed(1) + '</td>'
                   + '<td>' + props.means[9].milk.toFixed(1) + '</td>'
                   + '</tr>'

                   +'<tr style="background:#E7EAEF">'
                   + '<td>' + series[0].data[2].name + '</td>'
                   + '<td>' + series[0].data[2].value+ ' ( '+ populationPercent(series[0].data[2].value,totalPopulation)  + ' % )</td>'
                   + '<td>' + props.means[10].lnmMean.toFixed(1) + '</td>'
                   + '<td>' + props.means[10].lfmMean.toFixed(1) + '</td>'
                   + '<td>' + props.means[10].milk.toFixed(1) + '</td>'
                   + '</tr>'

                   +'<tr style="background:#E7EAEF">'
                   + '<td>' + series[0].data[3].name + '</td>'
                   + '<td>' + series[0].data[3].value+ ' ( '+ populationPercent(series[0].data[3].value,totalPopulation)  + ' % )</td>'
                   + '<td>' + props.means[0].lnmMean.toFixed(1) + '</td>'
                   + '<td>' + props.means[0].lfmMean.toFixed(1) + '</td>'
                   + '<td>' + props.means[0].milk.toFixed(1) + '</td>'
                   + '</tr>'

                   +'<tr style="background:#E7EAEF">'
                   + '<td>' + series[0].data[4].name + '</td>'
                   + '<td>' + series[0].data[4].value+ ' ( '+ populationPercent(series[0].data[4].value,totalPopulation)  + ' % )</td>'
                   + '<td>' + props.means[1].lnmMean.toFixed(1) + '</td>'
                   + '<td>' + props.means[1].lfmMean.toFixed(1) + '</td>'
                   + '<td>' + props.means[1].milk.toFixed(1) + '</td>'
                   + '</tr>'

                   +'<tr style="background:#E7EAEF">'
                   + '<td>' + series[0].data[5].name + '</td>'
                   + '<td>' + series[0].data[5].value+ ' ( '+ populationPercent(series[0].data[5].value,totalPopulation)  + ' % )</td>'
                   + '<td>' + props.means[2].lnmMean.toFixed(1) + '</td>'
                   + '<td>' + props.means[2].lfmMean.toFixed(1) + '</td>'
                   + '<td>' + props.means[2].milk.toFixed(1) + '</td>'
                   + '</tr>'

                   +'<tr style="background:#E7EAEF">'
                   + '<td>' + series[0].data[6].name + '</td>'
                   + '<td>' + series[0].data[6].value+ ' ( '+ populationPercent(series[0].data[6].value,totalPopulation)  + ' % )</td>'
                   + '<td>' + props.means[3].lnmMean.toFixed(1) + '</td>'
                   + '<td>' + props.means[3].lfmMean.toFixed(1) + '</td>'
                   + '<td>' + props.means[3].milk.toFixed(1) + '</td>'
                   + '</tr>'

                   +'<tr style="background:#E7EAEF">'
                   + '<td>' + series[0].data[7].name + '</td>'
                   + '<td>' + series[0].data[7].value+ ' ( '+ populationPercent(series[0].data[7].value,totalPopulation)  + ' % )</td>'
                   + '<td>' + props.means[4].lnmMean.toFixed(1) + '</td>'
                   + '<td>' + props.means[4].lfmMean.toFixed(1) + '</td>'
                   + '<td>' + props.means[4].milk.toFixed(1) + '</td>'
                   + '</tr>'
                   
                   +'<tr>'
                   + '<td style="background:#41ada7"> Total </td>'
                   + '<td style="background:#E7EAEF">' + totalPopulation  + '</td>'
                   + '<td></td>'
                   + '<td></td>'
                   + '<td></td>'
                   + '</tr>';


                
                
            table += '</tbody></table>';
            return table;
          }
        },
        saveAsImage: {
          name: 'ترکیب جمعیت'
        }
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '8%',
      top: '18%',
      containLabel: true
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)'
    },
    legend: {
      top: '30%',
      orient: 'vertical',
      left: 'left'
    },
    graph:{
      color : colorPalette
    },
    series: [
      {
        color: colorPalette,
        name: 'Herd Combination',
        type: 'pie',
        radius: ['60%', '90%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
          
        },
        label: {
          show: false,
          position: 'center',
          
          formatter : function (params){
            
            
            var groupRecord = props.means && props.means.filter(x=>x.individualType == params.name);
  

            return(
              ['{groupHead|'+params.name+'}\n','{indexHead|LNM$}{valueHead|'+groupRecord[0].lnmMean.toFixed(1)+'}','{indexHead|LFM$}{valueHead|'+groupRecord[0].lfmMean.toFixed(1)+'}','{indexHead|MILK}{valueHead|'+groupRecord[0].milk.toFixed(1)+'}',].join('\n')

            );

          },
          rich : {
            
            groupHead:{
              color: '#333',
              height: 24,
              align: 'center',
              fontSize:15,
              fontWeight: 'bold'
            },

            indexHead: {
              color: '#333',
              height: 10,
              align: 'center',
              fontSize:18
            },
            value: {
              width: 20,
              align: 'center',
              fontSize:12
            },
            
          }
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '40',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: true
        },
        data: props.combination && [
          { value: props.combination.calvs1, name: 'Calves 0-6'},
          { value: props.combination.calvs2, name: 'Calves 7-12'},
          { value: props.combination.heiferCounts, name: 'Heifer'},
          { value: props.combination.laC1, name: 'Lac-1'},
          { value: props.combination.laC2, name: 'Lac-2'},
          { value: props.combination.laC3, name: 'Lac-3'},
          { value: props.combination.laC4, name: 'Lac-4'},
          { value: props.combination.lacGreaterThan4, name: 'Lac>4'},
        ]
      }
    ]
  };

  const AllCalvesMean = props.means && props.means.filter(x=>x.individualType == "Calves");
  const AllHeiferMean = props.means && props.means.filter(x=>x.individualType == "Heifer");
  const AllCowMean = props.means && props.means.filter(x=>x.individualType == "COW");

    

    return ( 

        <Container>
            <Grid container spacing={2}>

            <Grid md={4} item>
                
                


                  <Grid item md={12} >
                    <Paper elevation={3} style={{height:133,background:"#157A65"}}>

                      
                      <div style={{display:'flex',justifyContent:'space-around'}}>
                        <Typography variant='h5' component='h5' color="#fff" style={{marginRight:10,marginTop:10}}>
                          گوساله
                        </Typography>
                        <Typography variant='h5' component='h5' color="#fff" style={{marginLeft:10,marginTop:10}}>
                          {props.combination && props.combination.calvsCount}
                        </Typography>
                      </div>
                      
                      <Divider sx={{background:'#f4f4f4'}} />
                      
                      

                      <div style={{display:'flex',justifyContent:'space-around',paddingTop:10}}>
                        
                          <Stack style={{background:'#00b894',paddingTop:10,paddingBottom:10,paddingLeft:20,paddingRight:20,textAlign:'center',borderRadius:25}}>
                            <Typography variant='body1' style={{direction:'ltr'}} component='p' color="#fff" >
                              LNM$
                            </Typography>
                            <Typography variant='body1' style={{direction:'ltr'}} component='p' color="#fff" >
                            {AllCalvesMean && AllCalvesMean[0].lnmMean.toFixed(1)}
                            </Typography>
                          </Stack>

                          <Stack style={{background:'#00b894',paddingTop:10,paddingBottom:10,paddingLeft:20,paddingRight:20,textAlign:'center',borderRadius:25}}>
                            <Typography variant='body1' style={{direction:'ltr'}} component='p' color="#fff" >
                              LFM$
                            </Typography>
                            <Typography variant='body1' style={{direction:'ltr'}} component='p' color="#fff" >
                            {AllCalvesMean && AllCalvesMean[0].lfmMean.toFixed(1)}
                            </Typography>
                          </Stack>

                          <Stack style={{background:'#00b894',paddingTop:10,paddingBottom:10,paddingLeft:20,paddingRight:20,textAlign:'center',borderRadius:25}}>
                            <Typography variant='body1' style={{direction:'ltr'}} component='p' color="#fff" >
                              MILK
                            </Typography>
                            <Typography variant='body1' style={{direction:'ltr'}} component='p' color="#fff" >
                            {AllCalvesMean && AllCalvesMean[0].milk.toFixed(1)}
                            </Typography>
                          </Stack>
                          

                        </div>

                    </Paper>
                  </Grid>

                  <Grid item md={12} style={{paddingTop:5}}>
                    <Paper  elevation={3} style={{height:133,paddingTop:5,background:"#f39c12"}}>
                        <div style={{display:'flex',justifyContent:'space-around'}}>
                          <Typography variant='h5' component='h5' color="#fff" style={{marginRight:10,marginTop:10}}>
                            تلیسه
                          </Typography>
                          <Typography variant='h5' component='h5' color="#fff" style={{marginRight:10,marginTop:10}}>
                            {props.combination && props.combination.heiferCounts}
                          </Typography>
                        </div>
                      <Divider sx={{background:'#f4f4f4'}} />

                        <div style={{display:'flex',justifyContent:'space-around',paddingTop:10}}>
                        
                          <Stack style={{background:'#e67e22',paddingTop:10,paddingBottom:10,paddingLeft:20,paddingRight:20,textAlign:'center',borderRadius:25}}>
                            <Typography variant='body1' style={{direction:'ltr'}} component='p' color="#fff" >
                              LNM$
                            </Typography>
                            <Typography variant='body1' style={{direction:'ltr'}} component='p' color="#fff" >
                            {AllHeiferMean && AllHeiferMean[0].lnmMean.toFixed(1)}
                            </Typography>
                          </Stack>

                          <Stack style={{background:'#e67e22',paddingTop:10,paddingBottom:10,paddingLeft:20,paddingRight:20,textAlign:'center',borderRadius:25}}>
                            <Typography variant='body1' style={{direction:'ltr'}} component='p' color="#fff" >
                            LFM$
                            </Typography>
                            <Typography variant='body1' style={{direction:'ltr'}} component='p' color="#fff" >
                            {AllHeiferMean && AllHeiferMean[0].lfmMean.toFixed(1)}
                            </Typography>
                          </Stack>

                          <Stack style={{background:'#e67e22',paddingTop:10,paddingBottom:10,paddingLeft:20,paddingRight:20,textAlign:'center',borderRadius:25}}>
                            <Typography variant='body1' style={{direction:'ltr'}} component='p' color="#fff" >
                              MILK
                            </Typography>
                            <Typography variant='body1' style={{direction:'ltr'}} component='p' color="#fff" >
                            {AllHeiferMean && AllHeiferMean[0].milk.toFixed(1)}
                            </Typography>
                          </Stack>
                        

                      </div>


                    </Paper>
                  </Grid>

                  
                  <Grid item md={12} style={{paddingTop:5}}>
                    <Paper  elevation={3} style={{height:133,paddingTop:5,background:"#3498db"}}>
                      <Stack>

                        <div style={{display:'flex',justifyContent:'space-around'}}>
                          <Typography variant='h5' component='h5' color="#fff" style={{marginRight:10,marginTop:10}}>
                            گاو
                          </Typography>
                          <Typography variant='h5' component='h5' color="#fff" style={{marginRight:10,marginTop:10}}>
                            {props.combination && props.combination.cow}
                          </Typography>
                        </div>
                        <Divider sx={{background:'#f4f4f4'}} />

                        <div style={{display:'flex',justifyContent:'space-around',paddingTop:10}}>
                        
                          <Stack style={{background:'#2980b9',paddingTop:10,paddingBottom:10,paddingLeft:20,paddingRight:20,textAlign:'center',borderRadius:25}}>
                            <Typography variant='body1' style={{direction:'ltr'}} component='p' color="#fff" >
                              LNM$
                            </Typography>
                            <Typography variant='body1' style={{direction:'ltr'}} component='p' color="#fff" >
                            {AllHeiferMean && AllCowMean[0].lnmMean.toFixed(1)}
                            </Typography>
                          </Stack>

                          <Stack style={{background:'#2980b9',paddingTop:10,paddingBottom:10,paddingLeft:20,paddingRight:20,textAlign:'center',borderRadius:25}}>
                            <Typography variant='body1' style={{direction:'ltr'}} component='p' color="#fff" >
                              LFM$
                            </Typography>
                            <Typography variant='body1' style={{direction:'ltr'}} component='p' color="#fff" >
                            {AllHeiferMean && AllCowMean[0].lfmMean.toFixed(1)}
                            </Typography>
                          </Stack>

                          <Stack style={{background:'#2980b9',paddingTop:10,paddingBottom:10,paddingLeft:20,paddingRight:20,textAlign:'center',borderRadius:25}}>
                            <Typography variant='body1' style={{direction:'ltr'}} component='p' color="#fff" >
                              MILK
                            </Typography>
                            <Typography variant='body1' style={{direction:'ltr'}} component='p' color="#fff" >
                            {AllHeiferMean && AllCowMean[0].milk.toFixed(1)}
                            </Typography>
                          </Stack>
                        

                      </div>
  
                      </Stack>

                    </Paper>
                </Grid>
                  

              

                

              

            </Grid>


            <Grid md={8} item style={{direction:"ltr"}}>
                <Paper elevation={3} style={{height:408,paddingTop:5}}>
                    <ReactECharts option={herdComboPieOptions} style={{height:'100%'}}/>
                </Paper>
            </Grid>

              

          </Grid>
        </Container>
        

     );
}
 
export default HerdCombo;

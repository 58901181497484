import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import FeedIcon from '@mui/icons-material/Feed';
import GridViewIcon from '@mui/icons-material/GridView';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TableChartIcon from '@mui/icons-material/TableChart';
import CalculateIcon from '@mui/icons-material/Calculate';
import GroupIcon from '@mui/icons-material/Group';
import { Icon } from '@iconify/react';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IranSans from '../font/IranSans.ttf';
import { Link } from "react-router-dom";


const fontTheme = createTheme({
    typography: {
      fontFamily: 'IranSans',
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'IranSans';
            src: local('IranSans'), url(${IranSans}) format('truetype');
          }
        `,
      },
    },
  });

const  DrawerList = () => {
    return ( 

        <List>
            
            <ListItem button component={Link} to="/">
                <ListItemIcon>
                    <DashboardIcon/>
                </ListItemIcon>
                <ListItemText>
                    <Typography sx={{fontSize:'11px' , fontWeight:'bold'}} variant='h1' theme={fontTheme}>
                        داشبورد
                    </Typography>
                </ListItemText>
            </ListItem>

            <ListItem button component={Link} to="/herd">
                <ListItemIcon>
                    <Icon icon="healthicons:animal-cow" width="24" height="24" />
                </ListItemIcon>
                <ListItemText>
                    <Typography sx={{fontSize:'11px' , fontWeight:'bold'}} variant='h1' theme={fontTheme}>
                        لیست گله
                    </Typography>
                </ListItemText>
            </ListItem>

            <ListItem button component={Link} to="/evaluation">
                <ListItemIcon>
                    <Icon icon="mdi:dna" width="25" height="25" />
                </ListItemIcon>
                <ListItemText>
                    <Typography sx={{fontSize:'11px' , fontWeight:'bold'}} variant='h1' theme={fontTheme}>
                    ارزیابی ژنتیکی
                    </Typography>
                </ListItemText>
            </ListItem>

            <ListItem button  component={Link} to="/report">
                <ListItemIcon>
                    <Icon icon="bxs:report" color="#004c44" width="24" height="24"/>
                </ListItemIcon>
                <ListItemText>
                    <Typography sx={{fontSize:'11px' , fontWeight:'bold'}} variant='h1' theme={fontTheme}>
                        گزارش ژنتیکی
                    </Typography>
                </ListItemText>
            </ListItem>
            
            <Divider/>
            <ListItem button component={Link} to="/sperms/beef">
                <ListItemIcon>
                    <Icon icon="healthicons:animal-cow" width="24" height="24" />
                </ListItemIcon>
                <ListItemText>
                    <Typography sx={{fontSize:'11px' , fontWeight:'bold'}} variant='h1' theme={fontTheme}>
                        کاتالوگ اسپرم گوشتی
                    </Typography>
                </ListItemText>
            </ListItem>

            <ListItem button component={Link} to="/sperms/dairy">
                <ListItemIcon>
                    <Icon icon="healthicons:animal-cow" width="24" height="24" />
                </ListItemIcon>
                <ListItemText>
                    <Typography sx={{fontSize:'11px' , fontWeight:'bold'}} variant='h1' theme={fontTheme}>
                        کاتالوگ اسپرم شیری
                    </Typography>
                </ListItemText>
            </ListItem>

            

            
            
            

        </List>

     );
}
 
export default DrawerList;
import React, {useContext} from 'react';
import {Box, Container, Grid, Paper, Typography, Table, Stack, CircularProgress } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';


import IndividualInfo from '../Components/IndividualInfo';
import IndividualTipInfo from '../Components/IndividualTipInfo';
import { IndividualDashboardContext } from '../Context/IndividualDashboardContext';
import HerdContactInfo from '../Components/HerdContactInfo';

import { AuthContext } from '../Context/AuthContext';

import IndividualPedigree from '../Components/IndividualPedigree';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#34495e",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      width:"50%"
      
      
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));



  
const IndividualDashboard = () => {


    const {individualData,Tag,HerdId,handleInit} = useContext(IndividualDashboardContext); 

    let search = window.location.search;
    let params = new URLSearchParams(search);
   
    let paramTag = params.get('Tag');

    const {userInfo} = useContext(AuthContext);

    
    handleInit(userInfo.Herd,paramTag);


    return (
        <Container>
            {individualData !== undefined ?
            <Grid  container spacing={2} sx={{marginBottom:10}}>

                <Grid item md={12} container spacing={2}>
                    <Grid item md={7}>
                        <IndividualPedigree/>
                    </Grid>

                    <Grid item md={5}>
                        <IndividualInfo/>
                    </Grid> 
                </Grid>

                <Grid item md={7} style={{paddingTop:20,direction:'ltr'}}>

                    <IndividualTipInfo/>
                    

                </Grid>

                <Grid item md={5} style={{paddingTop:20,direction:'ltr'}}>

                <>
                    
                    <Grid item ms={12}>
                        <Box component="div" style={{background:"#34495e",width:"100%",borderTopLeftRadius:15,borderTopRightRadius:15,paddingTop:10,paddingBottom:10,display:'flex',justifyContent:'center'}}>
                            <Typography variant="h6" color="#fff">
                            Selection Index

                            </Typography>
                        </Box>
                        <Paper>
                            <Table aria-label="simple table" sx={{paddingRight:1,paddingLeft:1}}>
                                <TableBody>
                                    <StyledTableRow
                                    key='1'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    > 
                                        <StyledTableCell style={{textAlign:"left"}}>LNM</StyledTableCell>
                                        <StyledTableCell style={{textAlign:"center"}}>{individualData.neT_MERIT.toFixed(1)}</StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow
                                    key='1'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    > 
                                        <StyledTableCell style={{textAlign:"left"}}>LFM</StyledTableCell>
                                        <StyledTableCell style={{textAlign:"center"}}>{individualData.fluiD_MERIT.toFixed(1)}</StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow
                                    key='1'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    > 
                                        <StyledTableCell style={{textAlign:"left"}}>LCM</StyledTableCell>
                                        <StyledTableCell style={{textAlign:"center"}}>{individualData.cheesE_MERIT.toFixed(1)}</StyledTableCell>
                                    </StyledTableRow>


                                    <StyledTableRow
                                    key='1'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    > 
                                        <StyledTableCell style={{textAlign:"left"}}>GM</StyledTableCell>
                                        <StyledTableCell style={{textAlign:"center"}}>{individualData.gm.toFixed(1)}</StyledTableCell>
                                    </StyledTableRow>

                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>

                    <Grid item ms={12} style={{paddingTop:20}}>
                        <Box component="div" style={{background:"#34495e",width:"100%",borderTopLeftRadius:15,borderTopRightRadius:15,paddingTop:10,paddingBottom:10,display:'flex',justifyContent:'center'}}>
                            <Typography variant="h6" color="#fff">
                                Production Traits
                            </Typography>
                        </Box>
                        <Paper>

                            <Table aria-label="simple table" sx={{paddingRight:1,paddingLeft:1}}>
                                <TableBody>
                                    <StyledTableRow
                                    key='1'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    > 
                                        <StyledTableCell style={{textAlign:"left"}}>MILK</StyledTableCell>
                                        <StyledTableCell style={{textAlign:"center"}}>{individualData.milk.toFixed(1)}</StyledTableCell>
                                    </StyledTableRow>


                                    <StyledTableRow
                                    key='1'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    > 
                                        <StyledTableCell style={{textAlign:"left"}}>SCS</StyledTableCell>
                                        <StyledTableCell style={{textAlign:"center"}}>{individualData.scs.toFixed(1)}</StyledTableCell>
                                    </StyledTableRow>


                                    <StyledTableRow
                                    key='1'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    > 
                                        <StyledTableCell style={{textAlign:"left"}}>FAT</StyledTableCell>
                                        <StyledTableCell style={{textAlign:"center"}}>{individualData.fat.toFixed(1)}</StyledTableCell>
                                    </StyledTableRow>


                                    <StyledTableRow
                                    key='1'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    > 
                                        <StyledTableCell style={{textAlign:"left"}}>FAT %</StyledTableCell>
                                        <StyledTableCell style={{textAlign:"center"}}>{individualData.faT_P.toFixed(1)}</StyledTableCell>
                                    </StyledTableRow>


                                    <StyledTableRow
                                    key='1'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    > 
                                        <StyledTableCell style={{textAlign:"left"}}>PROTEIN</StyledTableCell>
                                        <StyledTableCell style={{textAlign:"center"}}>{individualData.protein.toFixed(1)}</StyledTableCell>
                                    </StyledTableRow>


                                    <StyledTableRow
                                    key='1'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    > 
                                        <StyledTableCell style={{textAlign:"left"}}>PROTEIN %</StyledTableCell>
                                        <StyledTableCell style={{textAlign:"center"}}>{individualData.proteiN_P.toFixed(1)}</StyledTableCell>
                                    </StyledTableRow>

                                </TableBody>
                            </Table>


                        </Paper>
                    </Grid>

                    <Grid item ms={12} style={{paddingTop:20}}>
                        <Box component="div" style={{background:"#34495e",width:"100%",borderTopLeftRadius:15,borderTopRightRadius:15,paddingTop:10,paddingBottom:10,display:'flex',justifyContent:'center'}}>
                            <Typography variant="h6" color="#fff">
                                Calving Traits
                            </Typography>
                        </Box>
                        <Paper>
                        <Table aria-label="simple table" sx={{paddingRight:1,paddingLeft:1}}>
                            <TableBody>
                                <StyledTableRow
                                key='1'
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                > 
                                    <StyledTableCell style={{textAlign:"left"}}>PL</StyledTableCell>
                                    <StyledTableCell style={{textAlign:"center"}}>{individualData.pl.toFixed(1)}</StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow
                                key='1'
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                > 
                                    <StyledTableCell style={{textAlign:"left"}}>DPR</StyledTableCell>
                                    <StyledTableCell style={{textAlign:"center"}}>{individualData.dpr.toFixed(1)}</StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow
                                key='1'
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                > 
                                    <StyledTableCell style={{textAlign:"left"}}>CCR</StyledTableCell>
                                    <StyledTableCell style={{textAlign:"center"}}>{individualData.ccr.toFixed(1)}</StyledTableCell>
                                </StyledTableRow>


                                <StyledTableRow
                                key='1'
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                > 
                                    <StyledTableCell style={{textAlign:"left"}}>HCR</StyledTableCell>
                                    <StyledTableCell style={{textAlign:"center"}}>{individualData.hcr.toFixed(1)}</StyledTableCell>
                                </StyledTableRow>
                                

                                <StyledTableRow
                                key='1'
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                > 
                                    <StyledTableCell style={{textAlign:"left"}}>Feed Save</StyledTableCell>
                                    <StyledTableCell style={{textAlign:"center"}}>{individualData.feedSaved.toFixed(1)}</StyledTableCell>
                                </StyledTableRow>

                                

                                <StyledTableRow
                                key='1'
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                > 
                                    <StyledTableCell style={{textAlign:"left"}}>Mastitis</StyledTableCell>
                                    <StyledTableCell style={{textAlign:"center"}}>{individualData.mastitis.toFixed(1)}</StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow
                                key='1'
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                > 
                                    <StyledTableCell style={{textAlign:"left"}}>Metritis</StyledTableCell>
                                    <StyledTableCell style={{textAlign:"center"}}>{individualData.metritis.toFixed(1)}</StyledTableCell>
                                </StyledTableRow>


                                <StyledTableRow
                                key='1'
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                > 
                                    <StyledTableCell style={{textAlign:"left"}}>Retained Placenta</StyledTableCell>
                                    <StyledTableCell style={{textAlign:"center"}}>{individualData.retainedPlacenta.toFixed(1)}</StyledTableCell>
                                </StyledTableRow>


                                <StyledTableRow
                                key='1'
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                > 
                                    <StyledTableCell style={{textAlign:"left"}}>Milk Fever</StyledTableCell>
                                    <StyledTableCell style={{textAlign:"center"}}>{individualData.milkFever.toFixed(1)}</StyledTableCell>
                                </StyledTableRow>


                                <StyledTableRow
                                key='1'
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                > 
                                    <StyledTableCell style={{textAlign:"left"}}>Displaced Abomasum</StyledTableCell>
                                    <StyledTableCell style={{textAlign:"center"}}>{individualData.displacedAbomasum.toFixed(1)}</StyledTableCell>
                                </StyledTableRow>

                                

                                </TableBody>
                            </Table>
                        </Paper>

                        
                        
                    </Grid>
                    <Grid item md={12} style={{paddingTop:20}}>
                        <Box component="div" style={{background:"#34495e",width:"100%",borderTopLeftRadius:15,borderTopRightRadius:15,paddingTop:10,paddingBottom:10,display:'flex',justifyContent:'center'}}>
                            <Typography variant="h6" color="#fff">
                                Contact Info
                            </Typography>
                        </Box>
                        <HerdContactInfo />
                    </Grid>
                </>
                        
                    

                </Grid>

            </Grid>


            
        :   
        
        <Grid 
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
        >
            <Box textAlign='center'>
                <CircularProgress disableShrink  />
            </Box>
        </Grid>
        
        }
        </Container> 
     );
}
 
export default IndividualDashboard;
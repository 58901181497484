import logo from './logo.svg';
import './App.css';
import AuthContextProvider from './Context/AuthContext';
import AnalyticsRout from './routes/AnalyticsRout';
import GenaralRoutes from './routes/GeneralRoutes';
import GEHerdInfoContextProvider from './Context/GEHerdInfoContext';






function App() {
  return (

    <AuthContextProvider>
      <GenaralRoutes/>

      <GEHerdInfoContextProvider>
        <AnalyticsRout/>
      </GEHerdInfoContextProvider>
      
      
    </AuthContextProvider>
    
  );
}

export default App;

import React, { useState , createContext } from 'react';
import axios from "axios";
import { useEffect } from 'react';
import { UserManager } from 'oidc-react';
import { WebStorageStateStore } from 'oidc-client-ts';
import AuthService from './../Services/AuthService';



export const AuthContext = createContext();

const AuthContextProvider = (props) => {

    const authservice = new AuthService();

    var config = {
        userStore: new WebStorageStateStore({store: window.localStorage}),
        authority: process.env.REACT_APP_IDENTITY_BASE_URL,
        client_id: process.env.REACT_APP_CLIENT_ID,
        redirect_uri: `${process.env.REACT_APP_CLIENT_URL}/signincallback`,
        client_secret: process.env.REACT_APP_CLIENT_CODE,
        response_type: "code",
        scope:"openid profile HerdManagementApi",
        post_logout_redirect_uri : `${process.env.REACT_APP_CLIENT_URL}/logout`,
        loadUserInfo: true
        };

    
    const [userInfo,setUserInfo] = useState();
    const [accessToken,setAccessToken] = useState();

    const mgr = new UserManager(config);

    const isUser = () => {
        mgr.getUser().then(function (user) {

        
            if (user) {
                

                if(user.profile.Herd == undefined || user.profile.Herd == ""){

                    window.location.href = `${process.env.REACT_APP_CLIENT_URL}/logout`;

                }

                setUserInfo(user.profile);
                setAccessToken(user.access_token);

                return user.profile;

                
            }
            else {
                
                mgr.signinRedirect();
                
            }
        });

    }


    const UserSigninSilentCallback = () => {
        mgr.signinSilentCallback();
    };

    const UserScilentSignIn = () => {
        mgr.signinSilent()
            .then((user) => {
            })
            .catch((err) => {
                UserSigninSilentCallback();
            });
    }


    
    
    useEffect(() => {
        
        isUser();
    },[]);


    const handelPageAccess = async (area) => {
        var result = await authservice.CheckLiveAccess(area);
        
        return result;
    }

    const GetProfilePic = async (email) => {
        var profileimage = await axios.get(`${process.env.REACT_APP_IDENTITY_BASE_URL}api/UserComon`);
    }


    return ( 

        <AuthContext.Provider

            value={{
    
                userInfo,
                accessToken,
                UserScilentSignIn,
                UserSigninSilentCallback,
                handelPageAccess
            }}
        
        >
            {props.children}
        </AuthContext.Provider>
     );
}
 
export default AuthContextProvider;
import { Grid,Container } from '@mui/material';
import React, { useContext } from 'react';
import GeReportPaperList from '../Components/GEReportPaperList';
import GEReportSireLine from '../Components/GEReportSireLine';
import { GEReportContext } from '../Context/GEReportContext';
import { GESireLineReportContext } from '../Context/GESireLineReportContext';
import { AuthContext } from '../Context/AuthContext';



const Report = () => {


    const {userInfo} = useContext(AuthContext);

    const {handleOpenReportEditorDialog,setReportHerdId,reportPaperList} = useContext(GEReportContext);
    const {setSireLineHerdId,SireLineData} = useContext(GESireLineReportContext);

    setReportHerdId(userInfo.Herd);
    setSireLineHerdId(userInfo.Herd);


    return ( 

        <>

            <Container>

                <Grid container>

                    <Grid item lg={12} md={12} style={{marginBottom:20}}>
                        
                        <GEReportSireLine SireLineData = {SireLineData}/>
                    </Grid>

                    <Grid item lg={12} md={12}>
                        <GeReportPaperList herd={userInfo.Herd}/>

                    </Grid>

                </Grid>

                    
            
            
            </Container>

        </>

     );
}
 
export default Report;
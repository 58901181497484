import React, {useContext} from 'react';
import { Box , CircularProgress, Container } from '@mui/material';

import { styled } from '@mui/material/styles';
import YearLactationComparsion from './../Components/YearLactationComparsion';
import BenchmarkComparsion from './../Components/BenchmarkComparsion';
import DistributionComparsion from './../Components/DistributionComparsion';
import PercentileTable from './../Components/PercentileTable';
import { GEHerdPercentileContext } from './../Context/GEHerdPercentileContext';
import { GEYearLactContext } from './../Context/GEYearLactContext';
import { AuthContext } from '../Context/AuthContext';



const GE_HerdEvaluation = () => {

    const {userInfo} = useContext(AuthContext);

    const {yearLacData,setHerdIdHerdIdYearLac} = useContext(GEYearLactContext);
    setHerdIdHerdIdYearLac(userInfo.Herd);

    const {PercentileDataTable,setHerdIdHerdIdPercentile} = useContext(GEHerdPercentileContext);
    setHerdIdHerdIdPercentile(userInfo.Herd);


    

    return ( 

        <>
            
            {Object.keys(yearLacData).length !== 0 ? <YearLactationComparsion data={yearLacData}/> 
            : 
            <Box textAlign='center'>
                <CircularProgress disableShrink />
            </Box>}

            <BenchmarkComparsion HerdId={userInfo.Herd}/>
            <DistributionComparsion  HerdId={userInfo.Herd}/>

            {PercentileDataTable.length !== 0 ? <PercentileTable data={PercentileDataTable} Herd={userInfo.Herd}/> 
            : <Box textAlign='center'>
                <CircularProgress disableShrink />
            </Box>}
            
        </>

     );
}
 
export default GE_HerdEvaluation;
import React , {useContext, useState, useEffect}  from 'react';
import { Paper, Box, Typography, Stack, Grid, TextField, Avatar, FormControl, FormLabel, InputLabel, Select, MenuItem, Switch, FormGroup, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Container } from '@mui/material';
import IranSans from '../font/IranSans.ttf';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { GEReportContext } from '../Context/GEReportContext';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const fontTheme = createTheme({
    typography: {
      fontFamily: 'IranSans',
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'IranSans';
            src: local('IranSans'), url(${IranSans}) format('truetype');
          }
        `,
      },
    },
  });

const GE_ViewReportPaperDialog = (props) => {

    const {openViewReportDialog,handleCloseViewReportDialog,SelectedReport} = useContext(GEReportContext);



    let title = "";
    let publishDate = "";
    let Content = "";

    if(SelectedReport !== undefined){

        title = SelectedReport[0].title;
        publishDate = SelectedReport[0].publishDate.split('T')[0];
        Content = SelectedReport[0].content;
    }


    return ( 

        <Dialog
        fullScreen
        open={openViewReportDialog}
        onClose={handleCloseViewReportDialog}
        TransitionComponent={Transition}
        PaperProps={{
            style: {
            backgroundColor: '#f4f4f4',
            
            },
        }}
    >

        <AppBar sx={{ position: "relative", background: "#00a594"}}>
            <Toolbar>
                <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseViewReportDialog}
                aria-label="close"
                >
                <CloseIcon />
                </IconButton>
                <Typography
                
                sx={{ ml: 2, flex: 1 }}
                variant="h6"
                component="div"
                >
                    {title}  {publishDate}
                </Typography>
                
            </Toolbar>
        </AppBar>


        <Container  component="form" noValidate autoComplete="off" sx={{paddingTop:7}}>
                

                
            <Grid container spacing={3} sx={{paddingTop:3}}>

                {SelectedReport !== undefined ? <div dangerouslySetInnerHTML={{ __html: Content }} /> : <></>}

            </Grid>
                

        </Container>
    </Dialog>

     );
}
 
export default GE_ViewReportPaperDialog;
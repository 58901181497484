import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar, Container, Grid, IconButton, Stack ,Typography,TextField} from '@mui/material';
import { Link } from "react-router-dom";
import IranSans from '../../../font/IranSans.ttf';

import { ThemeProvider, createTheme } from "@mui/material/styles";


const fontTheme = createTheme({
  typography: {
    fontFamily: 'IranSans',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'IranSans';
          src: local('IranSans'), url(${IranSans}) format('truetype');
        }
      `,
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#555555',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const BeefSpermCatalogTable = (props) => {


  return (
    <div>
    <TableContainer component={Paper}>

        <Table sx={{width:'100%'}} aria-label="customized table">
            <TableHead>
            <TableRow>
                

              {props.SpermTableHeaders.map(item =>(
                  <StyledTableCell key={item} align="right">
                    <Typography variant='p' theme={fontTheme}>
                       {item}
                    </Typography>
                  </StyledTableCell>
              ))
              }
              
                
            </TableRow>
            </TableHead>
            <TableBody>
              {props.spermList.map((row,index) => (
                <StyledTableRow key={row.id}>

                  {/* <StyledTableCell>
                    {index+1}
                  </StyledTableCell> */}
                  
                  <StyledTableCell>
                    {row.regNo}
                  </StyledTableCell>
                  
                  <StyledTableCell>
                    {row.breed}
                  </StyledTableCell>
                  
                  <StyledTableCell>
                    {row.name}
                  </StyledTableCell>
                  
                  <StyledTableCell>
                    {row.sce}
                  </StyledTableCell>
                  
                  <StyledTableCell>
                    {row.cr}
                  </StyledTableCell>
                                    
                  <StyledTableCell>
                    {row.dm}
                  </StyledTableCell>
                                    
                  <StyledTableCell>
                    {row.pcar}
                  </StyledTableCell>
                                    
                  <StyledTableCell>
                    {row.rdt}
                  </StyledTableCell>
                                    
                  <StyledTableCell>
                    {row.conf}
                  </StyledTableCell>
                                    
                  <StyledTableCell>
                    {row.coul}
                  </StyledTableCell>
                                    
                  <StyledTableCell>
                    {row.gras}
                  </StyledTableCell>
                                    
                  <StyledTableCell>
                    {row.iab}
                  </StyledTableCell>
                                    
                  <StyledTableCell>
                    {row.icrc}
                  </StyledTableCell>
                                                                                   
                  <StyledTableCell>
                    {row.sire}
                  </StyledTableCell>
                          
                  <StyledTableCell>
                    {row.mgs}
                  </StyledTableCell>

                                                      
                  <StyledTableCell>
                    {row.price}
                  </StyledTableCell>

                                                      
                 


                </StyledTableRow>
                

              ))}
            </TableBody>
        </Table>
    </TableContainer>


    


</div>
  );
};

export default BeefSpermCatalogTable;

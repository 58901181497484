import React, { useState } from 'react'
import { Container, Grid, Paper, Table ,Box} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useTheme } from '@mui/material/styles';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import SwipeableViews from 'react-swipeable-views';
import HeaderDictionary from '../Helper/HeaderDictionary';


function TabPanel(props) {
    

    const { children, value, index, ...other } = props;
  
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box style={{height:'100%'}}>
            {children}
          </Box>
        )}
      </div>
    );
  }

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#34495e",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


const PercentileTable = (props) => {




    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        
    };

    const handleChangeIndex = (index) => {
        setValue(index);
        
    };

    return ( 


        <Grid container spacing={2} sx={{marginTop:5}}>

            <Grid md={12}>
                <Paper elevation={3} style={{marginBottom:20}}>

                    <Tabs value={value} onChange={handleChange} aria-label="tabs example">
                
                        {props.data.map((ind) => (

                            <Tab style={{direction:'ltr'}} key={ind.index+"_HTAB"} label={HeaderDictionary.Headers[ind.index]} />

                        ) )}

                    </Tabs>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        
                        {props.data.map((ind,i) => (
                            

                            <TabPanel key={ind.index} value={value} index={i} dir={theme.direction}>
                                
                                <Table aria-label="progressTable" >

                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell>Percentile</StyledTableCell>
                                            <StyledTableCell>0</StyledTableCell>
                                            <StyledTableCell>1</StyledTableCell>
                                            <StyledTableCell>2</StyledTableCell>
                                            <StyledTableCell>3</StyledTableCell>
                                            <StyledTableCell>4</StyledTableCell>
                                            <StyledTableCell>5</StyledTableCell>
                                            <StyledTableCell>6</StyledTableCell>
                                            <StyledTableCell>7</StyledTableCell>
                                            <StyledTableCell>8</StyledTableCell>
                                            <StyledTableCell>9</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>

                                    <TableBody>
                                        <StyledTableRow
                                            key='1'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                
                                                <StyledTableCell sx={{background:"#34495e",color:"#fff"}} component="th" scope="row">
                                                    <a style={{color:'#fff'}}  href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${ind.percentiles[100].value.toFixed(1)}`}>100</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a  href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[99].value + 0.1).toFixed(1)}`}>{ind.percentiles[100].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    
                                                </StyledTableCell>


                                            </StyledTableRow>

                                            <StyledTableRow
                                            key='2'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                
                                                <StyledTableCell sx={{background:"#34495e",color:"#fff"}} component="th" scope="row">
                                                <a style={{color:'#fff'}}  href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${ind.percentiles[90].value.toFixed(1)}`}>90</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[89].value + 0.1).toFixed(1)}&max=${(ind.percentiles[91].value - 0.1).toFixed(1)}`}>{ind.percentiles[90].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[90].value + 0.1).toFixed(1)}&max=${(ind.percentiles[92].value - 0.1).toFixed(1)}`}>{ind.percentiles[91].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[91].value + 0.1).toFixed(1)}&max=${(ind.percentiles[93].value - 0.1).toFixed(1)}`}>{ind.percentiles[92].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[92].value + 0.1).toFixed(1)}&max=${(ind.percentiles[94].value - 0.1).toFixed(1)}`}>{ind.percentiles[93].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[93].value + 0.1).toFixed(1)}&max=${(ind.percentiles[95].value - 0.1).toFixed(1)}`}>{ind.percentiles[94].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[94].value + 0.1).toFixed(1)}&max=${(ind.percentiles[96].value - 0.1).toFixed(1)}`}>{ind.percentiles[95].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${ind.percentiles[95].value.toFixed(1)}&max=${(ind.percentiles[97].value - 0.1).toFixed(1)}`}>{ind.percentiles[96].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[96].value + 0.1).toFixed(1)}&max=${(ind.percentiles[98].value - 0.1).toFixed(1)}`}>{ind.percentiles[97].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[97].value + 0.1).toFixed(1)}&max=${(ind.percentiles[99].value - 0.1).toFixed(1)}`}>{ind.percentiles[98].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[98].value + 0.1).toFixed(1)}&max=${(ind.percentiles[100].value - 0.1).toFixed(1)}`}>{ind.percentiles[99].value.toFixed(1)}</a>
                                                </StyledTableCell>


                                            </StyledTableRow>


                                            <StyledTableRow
                                            key='3'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                
                                                <StyledTableCell sx={{background:"#34495e",color:"#fff"}} component="th" scope="row">
                                                    <a style={{color:'#fff'}} href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${ind.percentiles[80].value.toFixed(1)}`}>80</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[79].value + 0.1).toFixed(1)}&max=${(ind.percentiles[81].value - 0.1).toFixed(1)}`}>{ind.percentiles[80].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[80].value + 0.1).toFixed(1)}&max=${(ind.percentiles[82].value - 0.1).toFixed(1)}`}>{ind.percentiles[81].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[81].value + 0.1).toFixed(1)}&max=${(ind.percentiles[83].value - 0.1).toFixed(1)}`}>{ind.percentiles[82].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[82].value + 0.1).toFixed(1)}&max=${(ind.percentiles[84].value - 0.1).toFixed(1)}`}>{ind.percentiles[83].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[83].value + 0.1).toFixed(1)}&max=${(ind.percentiles[85].value - 0.1).toFixed(1)}`}>{ind.percentiles[84].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[84].value + 0.1).toFixed(1)}&max=${(ind.percentiles[86].value - 0.1).toFixed(1)}`}>{ind.percentiles[85].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[85].value + 0.1).toFixed(1)}&max=${(ind.percentiles[87].value - 0.1).toFixed(1)}`}>{ind.percentiles[86].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[86].value + 0.1).toFixed(1)}&max=${(ind.percentiles[88].value - 0.1).toFixed(1)}`}>{ind.percentiles[87].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[87].value + 0.1).toFixed(1)}&max=${(ind.percentiles[89].value - 0.1).toFixed(1)}`}>{ind.percentiles[88].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[88].value + 0.1).toFixed(1)}&max=${(ind.percentiles[90].value - 0.1).toFixed(1)}`}>{ind.percentiles[89].value.toFixed(1)}</a>
                                                </StyledTableCell>


                                            </StyledTableRow>


                                            <StyledTableRow
                                            key='4'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                
                                                <StyledTableCell sx={{background:"#34495e",color:"#fff"}} component="th" scope="row">
                                                    <a style={{color:'#fff'}} href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${ind.percentiles[70].value.toFixed(1)}`}>70</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[69].value + 0.1).toFixed(1)}&max=${(ind.percentiles[71].value - 0.1).toFixed(1)}`}>{ind.percentiles[70].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[70].value + 0.1).toFixed(1)}&max=${(ind.percentiles[72].value - 0.1).toFixed(1)}`}>{ind.percentiles[71].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[71].value + 0.1).toFixed(1)}&max=${(ind.percentiles[73].value - 0.1).toFixed(1)}`}>{ind.percentiles[72].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[12].value + 0.1).toFixed(1)}&max=${(ind.percentiles[74].value - 0.1).toFixed(1)}`}>{ind.percentiles[73].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[73].value + 0.1).toFixed(1)}&max=${(ind.percentiles[75].value - 0.1).toFixed(1)}`}>{ind.percentiles[74].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[74].value + 0.1).toFixed(1)}&max=${(ind.percentiles[76].value - 0.1).toFixed(1)}`}>{ind.percentiles[75].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[75].value + 0.1).toFixed(1)}&max=${(ind.percentiles[77].value - 0.1).toFixed(1)}`}>{ind.percentiles[76].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[76].value + 0.1).toFixed(1)}&max=${(ind.percentiles[78].value - 0.1).toFixed(1)}`}>{ind.percentiles[77].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[77].value + 0.1).toFixed(1)}&max=${(ind.percentiles[79].value - 0.1).toFixed(1)}`}>{ind.percentiles[78].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[78].value + 0.1).toFixed(1)}&max=${(ind.percentiles[80].value - 0.1).toFixed(1)}`}>{ind.percentiles[79].value.toFixed(1)}</a>
                                                </StyledTableCell>


                                            </StyledTableRow>


                                            <StyledTableRow
                                            key='5'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                
                                                <StyledTableCell sx={{background:"#34495e",color:"#fff"}} component="th" scope="row">
                                                    <a style={{color:'#fff'}} href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${ind.percentiles[60].value.toFixed(1)}`}>60</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[59].value + 0.1).toFixed(1)}&max=${(ind.percentiles[61].value - 0.1).toFixed(1)}`}>{ind.percentiles[60].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[61].value + 0.1).toFixed(1)}&max=${(ind.percentiles[62].value - 0.1).toFixed(1)}`}>{ind.percentiles[61].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[62].value + 0.1).toFixed(1)}&max=${(ind.percentiles[63].value - 0.1).toFixed(1)}`}>{ind.percentiles[62].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[62].value + 0.1).toFixed(1)}&max=${(ind.percentiles[64].value - 0.1).toFixed(1)}`}>{ind.percentiles[63].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[63].value + 0.1).toFixed(1)}&max=${(ind.percentiles[65].value - 0.1).toFixed(1)}`}>{ind.percentiles[64].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[64].value + 0.1).toFixed(1)}&max=${(ind.percentiles[66].value - 0.1).toFixed(1)}`}>{ind.percentiles[65].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[65].value + 0.1).toFixed(1)}&max=${(ind.percentiles[67].value - 0.1).toFixed(1)}`}>{ind.percentiles[66].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[66].value + 0.1).toFixed(1)}&max=${(ind.percentiles[68].value - 0.1).toFixed(1)}`}>{ind.percentiles[67].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[67].value + 0.1).toFixed(1)}&max=${(ind.percentiles[69].value - 0.1).toFixed(1)}`}>{ind.percentiles[68].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[68].value + 0.1).toFixed(1)}&max=${(ind.percentiles[70].value - 0.1).toFixed(1)}`}>{ind.percentiles[69].value.toFixed(1)}</a>
                                                </StyledTableCell>


                                            </StyledTableRow>

                                            <StyledTableRow
                                            key='6'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                
                                                <StyledTableCell sx={{background:"#34495e",color:"#fff"}} component="th" scope="row">
                                                    <a style={{color:'#fff'}} href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${ind.percentiles[50].value.toFixed(1)}`}>50</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[49].value + 0.1).toFixed(1)}&max=${(ind.percentiles[51].value - 0.1).toFixed(1)}`}>{ind.percentiles[50].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[50].value + 0.1).toFixed(1)}&max=${(ind.percentiles[52].value - 0.1).toFixed(1)}`}>{ind.percentiles[51].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[51].value + 0.1).toFixed(1)}&max=${(ind.percentiles[53].value - 0.1).toFixed(1)}`}>{ind.percentiles[52].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[52].value + 0.1).toFixed(1)}&max=${(ind.percentiles[54].value - 0.1).toFixed(1)}`}>{ind.percentiles[53].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[53].value + 0.1).toFixed(1)}&max=${(ind.percentiles[55].value - 0.1).toFixed(1)}`}>{ind.percentiles[54].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[54].value + 0.1).toFixed(1)}&max=${(ind.percentiles[56].value - 0.1).toFixed(1)}`}>{ind.percentiles[55].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[55].value + 0.1).toFixed(1)}&max=${(ind.percentiles[57].value - 0.1).toFixed(1)}`}>{ind.percentiles[56].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[56].value + 0.1).toFixed(1)}&max=${(ind.percentiles[58].value - 0.1).toFixed(1)}`}>{ind.percentiles[57].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[57].value + 0.1).toFixed(1)}&max=${(ind.percentiles[59].value - 0.1).toFixed(1)}`}>{ind.percentiles[58].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[58].value + 0.1).toFixed(1)}&max=${(ind.percentiles[60].value - 0.1).toFixed(1)}`}>{ind.percentiles[59].value.toFixed(1)}</a>
                                                </StyledTableCell>


                                            </StyledTableRow>

                                            <StyledTableRow
                                            key='7'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                
                                                <StyledTableCell sx={{background:"#34495e",color:"#fff"}} component="th" scope="row">
                                                    <a style={{color:'#fff'}} href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${ind.percentiles[40].value.toFixed(1)}`}>40</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[39].value + 0.1).toFixed(1)}&max=${(ind.percentiles[41].value - 0.1).toFixed(1)}`}>{ind.percentiles[40].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[40].value + 0.1).toFixed(1)}&max=${(ind.percentiles[42].value - 0.1).toFixed(1)}`}>{ind.percentiles[41].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[41].value + 0.1).toFixed(1)}&max=${(ind.percentiles[43].value - 0.1).toFixed(1)}`}>{ind.percentiles[42].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[42].value + 0.1).toFixed(1)}&max=${(ind.percentiles[44].value - 0.1).toFixed(1)}`}>{ind.percentiles[43].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[43].value + 0.1).toFixed(1)}&max=${(ind.percentiles[45].value - 0.1).toFixed(1)}`}>{ind.percentiles[44].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[44].value + 0.1).toFixed(1)}&max=${(ind.percentiles[46].value - 0.1).toFixed(1)}`}>{ind.percentiles[45].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[45].value + 0.1).toFixed(1)}&max=${(ind.percentiles[47].value - 0.1).toFixed(1)}`}>{ind.percentiles[46].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[46].value + 0.1).toFixed(1)}&max=${(ind.percentiles[48].value - 0.1).toFixed(1)}`}>{ind.percentiles[47].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[47].value + 0.1).toFixed(1)}&max=${(ind.percentiles[49].value - 0.1).toFixed(1)}`}>{ind.percentiles[48].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[48].value + 0.1).toFixed(1)}&max=${(ind.percentiles[50].value - 0.1).toFixed(1)}`}>{ind.percentiles[49].value.toFixed(1)}</a>
                                                </StyledTableCell>


                                            </StyledTableRow>


                                            <StyledTableRow
                                            key='8'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                
                                                <StyledTableCell sx={{background:"#34495e",color:"#fff"}} component="th" scope="row">
                                                    <a style={{color:'#fff'}} href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${ind.percentiles[30].value.toFixed(1)}`}>30</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[29].value + 0.1).toFixed(1)}&max=${(ind.percentiles[31].value - 0.1).toFixed(1)}`}>{ind.percentiles[30].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[30].value + 0.1).toFixed(1)}&max=${(ind.percentiles[32].value - 0.1).toFixed(1)}`}>{ind.percentiles[31].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[31].value + 0.1).toFixed(1)}&max=${(ind.percentiles[33].value - 0.1).toFixed(1)}`}>{ind.percentiles[32].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[32].value + 0.1).toFixed(1)}&max=${(ind.percentiles[34].value - 0.1).toFixed(1)}`}>{ind.percentiles[33].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[33].value + 0.1).toFixed(1)}&max=${(ind.percentiles[35].value - 0.1).toFixed(1)}`}>{ind.percentiles[34].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[34].value + 0.1).toFixed(1)}&max=${(ind.percentiles[36].value - 0.1).toFixed(1)}`}>{ind.percentiles[35].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[35].value + 0.1).toFixed(1)}&max=${(ind.percentiles[37].value - 0.1).toFixed(1)}`}>{ind.percentiles[36].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[36].value + 0.1).toFixed(1)}&max=${(ind.percentiles[38].value - 0.1).toFixed(1)}`}>{ind.percentiles[37].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[37].value + 0.1).toFixed(1)}&max=${(ind.percentiles[39].value - 0.1).toFixed(1)}`}>{ind.percentiles[38].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[38].value + 0.1).toFixed(1)}&max=${(ind.percentiles[40].value - 0.1).toFixed(1)}`}>{ind.percentiles[39].value.toFixed(1)}</a>
                                                </StyledTableCell>


                                            </StyledTableRow>

                                            <StyledTableRow
                                            key='9'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                
                                                <StyledTableCell sx={{background:"#34495e",color:"#fff"}} component="th" scope="row">
                                                    <a style={{color:'#fff'}} href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${ind.percentiles[20].value.toFixed(1)}`}>20</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[19].value + 0.1 ).toFixed(1)}&max=${(ind.percentiles[21].value - 0.1 ).toFixed(1)}`}>{ind.percentiles[20].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[20].value + 0.1 ).toFixed(1)}&max=${(ind.percentiles[22].value - 0.1 ).toFixed(1)}`}>{ind.percentiles[21].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[21].value + 0.1 ).toFixed(1)}&max=${(ind.percentiles[23].value - 0.1 ).toFixed(1)}`}>{ind.percentiles[22].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[22].value + 0.1 ).toFixed(1)}&max=${(ind.percentiles[24].value - 0.1 ).toFixed(1)}`}>{ind.percentiles[23].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[23].value + 0.1 ).toFixed(1)}&max=${(ind.percentiles[25].value - 0.1 ).toFixed(1)}`}>{ind.percentiles[24].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[24].value + 0.1 ).toFixed(1)}&max=${(ind.percentiles[26].value - 0.1 ).toFixed(1)}`}>{ind.percentiles[25].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[25].value + 0.1 ).toFixed(1)}&max=${(ind.percentiles[27].value - 0.1 ).toFixed(1)}`}>{ind.percentiles[26].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[26].value + 0.1 ).toFixed(1)}&max=${(ind.percentiles[28].value - 0.1 ).toFixed(1)}`}>{ind.percentiles[27].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[27].value + 0.1 ).toFixed(1)}&max=${(ind.percentiles[29].value - 0.1 ).toFixed(1)}`}>{ind.percentiles[28].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[28].value + 0.1 ).toFixed(1)}&max=${(ind.percentiles[30].value - 0.1 ).toFixed(1)}`}>{ind.percentiles[29].value.toFixed(1)}</a>
                                                </StyledTableCell>


                                            </StyledTableRow>


                                            <StyledTableRow
                                            key='10'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                
                                                <StyledTableCell sx={{background:"#34495e",color:"#fff"}} component="th" scope="row">
                                                    <a style={{color:'#fff'}} href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${ind.percentiles[10].value.toFixed(1)}`}>10</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[9].value + 0.1).toFixed(1)}&max=${(ind.percentiles[11].value - 0.1).toFixed(1)}`}>{ind.percentiles[10].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[10].value + 0.1).toFixed(1)}&max=${(ind.percentiles[12].value - 0.1).toFixed(1)}`}>{ind.percentiles[11].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[11].value + 0.1).toFixed(1)}&max=${(ind.percentiles[13].value - 0.1).toFixed(1)}`}>{ind.percentiles[12].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[12].value + 0.1).toFixed(1)}&max=${(ind.percentiles[14].value - 0.1).toFixed(1)}`}>{ind.percentiles[13].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[13].value + 0.1).toFixed(1)}&max=${(ind.percentiles[15].value - 0.1).toFixed(1)}`}>{ind.percentiles[14].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[14 ].value + 0.1).toFixed(1)}&max=${(ind.percentiles[16].value - 0.1).toFixed(1)}`}>{ind.percentiles[15].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[15].value + 0.1).toFixed(1)}&max=${(ind.percentiles[17].value - 0.1).toFixed(1)}`}>{ind.percentiles[16].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[16].value + 0.1).toFixed(1)}&max=${(ind.percentiles[18].value - 0.1).toFixed(1)}`}>{ind.percentiles[17].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[17].value + 0.1).toFixed(1)}&max=${(ind.percentiles[19].value - 0.1).toFixed(1)}`}>{ind.percentiles[18].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[18].value + 0.1).toFixed(1)}&max=${(ind.percentiles[20].value - 0.1).toFixed(1)}`}>{ind.percentiles[19].value.toFixed(1)}</a>
                                                </StyledTableCell>



                                            </StyledTableRow>

                                            <StyledTableRow
                                            key='11'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                
                                                <StyledTableCell sx={{background:"#34495e",color:"#fff"}} component="th" scope="row">
                                                    <a style={{color:'#fff'}} href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${ind.percentiles[0].value.toFixed(1)}`}>0</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&max=${(ind.percentiles[1].value - 0.1).toFixed(1)}`}>{ind.percentiles[0].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[0].value + 0.1).toFixed(1)}&max=${(ind.percentiles[2].value - 0.1).toFixed(1)}`}>{ind.percentiles[1].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[1].value + 0.1 ).toFixed(1)}&max=${(ind.percentiles[3].value - 0.1).toFixed(1)}`}>{ind.percentiles[2].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[2].value + 0.1).toFixed(1)}&max=${(ind.percentiles[4].value - 0.1).toFixed(1)}`}>{ind.percentiles[3].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[3].value + 0.1).toFixed(1)}&max=${(ind.percentiles[5].value - 0.1).toFixed(1)}`}>{ind.percentiles[4].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[4].value + 0.1).toFixed(1)}&max=${(ind.percentiles[6].value - 0.1).toFixed(1)}`}>{ind.percentiles[5].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[5].value + 0.1).toFixed(1)}&max=${(ind.percentiles[7].value - 0.1).toFixed(1)}`}>{ind.percentiles[6].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[6].value + 0.1).toFixed(1)}&max=${(ind.percentiles[8].value - 0.1).toFixed(1)}`}>{ind.percentiles[7].value.toFixed(1)}</a>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[7].value + 0.1).toFixed(1)}&max=${(ind.percentiles[9].value - 0.1).toFixed(1)}`}>{ind.percentiles[8].value.toFixed(1)}</a>
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    <a href={`https://ge.mobarakandish.com/herd?indicator=${ind.index}&min=${(ind.percentiles[8].value + 0.1).toFixed(1)}&max=${(ind.percentiles[10].value - 0.1).toFixed(1)}`}>{ind.percentiles[9].value.toFixed(1)}</a>
                                                </StyledTableCell>


                                            </StyledTableRow>
                                    </TableBody>


                                </Table>




                            </TabPanel>


                        ))}

                    </SwipeableViews>

                    
                </Paper>
            </Grid>


        </Grid>


     );
}
 
export default PercentileTable;
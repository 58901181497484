import React, { useState , createContext,useEffect } from 'react';
import axios from "axios";
import AuthService from '../Services/AuthService';

export const IndividualDashboardContext = createContext();


const IndividualDashboardContextProvider = (props) => {

    const authservice = new AuthService();

    const [initFlag,setInitFlag] = useState(false);

    const [individualData,setIndividualData] = useState();

    const [Tag,setTag] = useState();
    const [HerdId,setHerdId] = useState();



    const handleInit = (paramHerdId,paramTag) => {
        setTag(paramTag);
        setHerdId(paramHerdId);
        setInitFlag(true);
    }



    const GetIndividualData = async () => {

        var authuser = await authservice.AuthGetUser();

        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authuser.access_token
          };

        await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/IndividualCatalog/Catalog?HerdId=${HerdId}&Tag=${Tag}`,{headers}).then(res => {

            setIndividualData(res.data.data);
            
          });
    
    
    }


    useEffect(() => {

        if(Tag !== undefined && HerdId !== undefined){

            GetIndividualData();
        }

        

    },[initFlag]);

    const [herdContactInfo,setHerdContactInfo] = useState({})
    console.log('webb',herdContactInfo)

    const GetHerdContactData = async () => {
        console.log('sdsds',herdContactInfo)
        var authuser = await authservice.AuthGetUser();

        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authuser.access_token
          };

        await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/GetHerdById?Id=${HerdId}`,{headers}).then(res => {

            setHerdContactInfo(res.data.data);
            
        });
    
    
    }
    
    useEffect(() => {
        console.log('sssss',herdContactInfo)
        if (HerdId !== undefined) {
            GetHerdContactData()
        }
    },[initFlag])
    return ( 

        <IndividualDashboardContext.Provider
    
                value={{
                    individualData,
                    Tag,
                    setTag,
                    HerdId,
                    setHerdId,
                    handleInit,
                    herdContactInfo
                }}
            
            >
                {props.children}
        </IndividualDashboardContext.Provider>

     );
}
 
export default IndividualDashboardContextProvider;
import * as React from 'react';
import HerdIndexGroupsDistribution from './HerdIndexGroupsDistribution';




const NormalDistributionDash = (props) => {

  const request = ["NET_MERIT","FLUID_MERIT"];

  

  return (
      
    <>
      <HerdIndexGroupsDistribution dataset={props.normalDistribution} evalDate={props.evalDate}/>
      
    </>

      
  );

     
}
 
export default NormalDistributionDash;
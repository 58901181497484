import React, { useState , createContext } from 'react';
import axios from "axios";
import AuthService from './../Services/AuthService';

export const GEHerdProgressContext = createContext();

function CalculateProgress(ds){
    var holder = [];
    var decreases = [];
    var increases = [];
    var telorance = [];

    for(var i=0;i<ds.length - 1;i++){

        var rez = (ds[i+1]) - (ds[i]);

        if(rez >= 0){

            
            holder.push(ds[i]);
            increases.push(rez.toFixed(1));
            decreases.push('-');
        }
        else{

            holder.push(ds[i+1]);

            increases.push('-');
            decreases.push(Math.abs(rez).toFixed(1));
        }

        telorance.push(rez);

    }


    return [
        increases,
        decreases,
        telorance,
        holder
    ];
}


const GEHerdProgressContextProvider = (props) => {

    const [progress,setProgress] = useState([]);
    const [years,setYears] = useState([]);
    //const [lnmProgress,setLnmProgress] = useState({});
    //const [indexSelect,setIndexSelect] = useState('LNM');

    const authservice = new AuthService();
    const [HerdIdProgrress,setHerdIdProgrress] = useState();

    React.useEffect( async () => {

        if(typeof HerdIdProgrress !== 'undefined' && HerdIdProgrress != null){

            var authuser = await authservice.AuthGetUser();

            const headers = {
                Accept: 'application/json',
                Authorization: 'Bearer ' + authuser.access_token
            };

            await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/HerdReport/Progress?HerdId=${HerdIdProgrress}`,{headers}).then(res => {
            
            

            var sortedDataSet = res.data.data.sort((a,b) => parseInt(a.year) - parseInt(b.year)).slice(-7);
            setYears(sortedDataSet.map(x=>x.year));

            var LNMValueHolder =  [sortedDataSet.map(x=>x.neT_MERIT).slice(-7)[0]].concat(sortedDataSet.map(x=>x.neT_MERIT).slice(-7));
            var LFMValueHolder =  [sortedDataSet.map(x=>x.fluiD_MERIT).slice(-7)[0]].concat(sortedDataSet.map(x=>x.fluiD_MERIT).slice(-7));
            var PLValueHolder =  [sortedDataSet.map(x=>x.pl).slice(-7)[0]].concat(sortedDataSet.map(x=>x.pl).slice(-7));
            var MILKValueHolder =  [sortedDataSet.map(x=>x.milk).slice(-7)[0]].concat(sortedDataSet.map(x=>x.milk).slice(-7));

            var LNMTelorans = CalculateProgress(LNMValueHolder);
            var LFMTelorans = CalculateProgress(LFMValueHolder);
            var PLTelorans = CalculateProgress(PLValueHolder);
            var MILKTelorans = CalculateProgress(MILKValueHolder);

            const lnmProgressObj =  {

                Index : "LNM",
                ValueHolder : sortedDataSet.map(x=>x.neT_MERIT).slice(-7),
                increase : LNMTelorans[0],
                decrease : LNMTelorans[1],
                telorance : LNMTelorans[2],
                holder : LNMTelorans[3]
                 
            };

            const lfmProgressObj =  {

                Index : "LFM",
                ValueHolder : sortedDataSet.map(x=>x.fluiD_MERIT).slice(-7),
                increase : LFMTelorans[0],
                decrease : LFMTelorans[1],
                telorance : LFMTelorans[2],
                holder : LFMTelorans[3]
                 
            };

            const PLProgressObj =  {

                Index : "PL",
                ValueHolder : sortedDataSet.map(x=>x.pl).slice(-7),
                increase : PLTelorans[0],
                decrease : PLTelorans[1],
                telorance : PLTelorans[2],
                holder : PLTelorans[3]
                 
            };

            const MILKProgressObj =  {

                Index : "MILK",
                ValueHolder : sortedDataSet.map(x=>x.milk).slice(-7),
                increase : MILKTelorans[0],
                decrease : MILKTelorans[1],
                telorance : MILKTelorans[2],
                holder : MILKTelorans[3]
                 
            };





            var progressList = [
                lnmProgressObj,
                lfmProgressObj,
                PLProgressObj,
                MILKProgressObj
            ];
            
            

            setProgress(progressList);
            
            
        });
        }

        


    },[HerdIdProgrress]);



    return ( 

        <GEHerdProgressContext.Provider

            value={{
                progress,
                years,
                setHerdIdProgrress
                
                
                

            }}
        
        >
            {props.children}
        </GEHerdProgressContext.Provider>

     );


}

export default GEHerdProgressContextProvider;

import React, { useContext,useState } from "react";
import { Container, 
  Grid ,
  Button,
  Box,Stack , Paper , CircularProgress } from "@mui/material";

import { AuthContext } from '../Context/AuthContext';
import DairySpermCatalogTable from "../Components/SpermCatalogs/Dairy/DairySpermCatalogTable";

import { DairySpermCatalogContext } from "../Context/SpermCatalogs/DairySpermCatalogContext";
import DairyFilter from "../Components/SpermCatalogs/Dairy/DairyFilter";
import { SearchOffOutlined , ExpandMore, Search } from "@mui/icons-material";

const DairySpermList = () => {

  const DiarySpermTableHeaders = ['NAAB','Reg.No','Breed','Name','LNM','LFM',
    'MILK','ICC','FAT','PROTEIN','SCE','PL','DPR','PTAT','UDC','FLC','FS','FI',
    'TPI','SIRE','MGS','قیمت']

  const {handelPageAccess} = useContext(AuthContext);
  // var access = handelPageAccess("Sperm Catalog");
  // if(!access){
  //     return (<> Access Denied...! </>)
  // }

  const {
    dairySpermList,
    toggleAddSpermDialog,
    handleOpenImportDairySpermDialog,
    handleDeleteAll,
  } = useContext(DairySpermCatalogContext);

  const [drawerStatus,setdrawerStatus] = useState(false)

  const buttonsStyle = 
    {height:'50px',width:'100%',borderRadius:5,color:'#fff',display:'flex'}


  const toggleDrawer = () => {
    if(drawerStatus){
        setdrawerStatus(false)
    }else {
        setdrawerStatus(true)
    }
  }
  return (

      <Container maxWidth="high">
        <Grid>
          <Grid spacing={2} sx={{display : 'flex', width : '100%' , justifyContent:'space-between'}} mb={1}>
            <Grid sx={{justifyContent : 'center'}} Item xs={12} style={buttonsStyle}>
                <Button style={{height:'50px',width:'100%',fontSize:'16px',background:'#3498db'}} variant="contained" startIcon={<Search fontSize="large"/>} onClick={toggleDrawer}>
                  فیلتر 
                </Button>
            </Grid>

          </Grid>
          
          <Grid align="center" item md={12} lg={12} mb={5}>

            <DairyFilter 
              drawerStatus={drawerStatus}
              toggleDrawer={toggleDrawer}
            />
          </Grid>

          <Grid item md={12} lg={12} mb={5} >
              <Paper elevation={3} >
                {dairySpermList.length == 0 ?
                  <Box textAlign='center'>
                    <CircularProgress disableShrink  /> 
                  </Box>
                  :
                  <DairySpermCatalogTable spermList={dairySpermList} SpermTableHeaders={DiarySpermTableHeaders}/>
                }

              </Paper>
          </Grid>

          

        </Grid>

      </Container>

  );
};

export default DairySpermList;

import React, {useState,useEffect} from 'react';
import { Box, Container, Grid, Paper, Typography ,Table } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { useTheme } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import SwipeableViews from 'react-swipeable-views';

import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import HeaderDictionary from '../Helper/HeaderDictionary';



function TabPanel(props) {
    

  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{height:'100%'}}>
          {children}
        </Box>
      )}
    </div>
  );
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#34495e",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const HerdIndexGroupsDistribution = (props) => {

  const indexMap = {
    "NET_MERIT":"LNM$",
    "FLUID_MERIT":"LFM$"
  
  };

  var evalD = props.evalDate;

  const [chartDataTableOpen,setChartDataTableOpen] = useState('');

  const [value, setValue] = React.useState(0);

  const exportToExcel = () => {


    const table = document.getElementById('NormalDistributionTable');
    const worksheet = utils.table_to_sheet(table);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = write(workbook, { type: 'array', bookType: 'xlsx' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, 'Herd__Normal_Distribution_for_'+HeaderDictionary.Headers[props.dataset[value].index]+'.xlsx');
};

useEffect(() => {
    const buttonElement = document.getElementById('NormalDistributionExport');
    if (buttonElement) {
    buttonElement.addEventListener('click', exportToExcel);
    }

},[chartDataTableOpen]);
  
  const setChart = (index) => {

    var NormalDistributionChart = {
      
      title: {
        text: HeaderDictionary.Headers[props.dataset[index].index]+ ' توزیع نرمال {a|            }',
        right:'right',
        subtext: evalD+' ارزیابی ',
        textStyle:{
    
      
          rich: {

            a: {
                backgroundColor: {
                    image: './logo.png'
                },
                height: 50
            },

            
          }
        }
      },
      toolbox: {
        left:'auto',
        show: true,
        feature: {
          dataZoom: {
            yAxisIndex: "none"
          },
          dataView: {
            readOnly: false,
            optionToContent: function(opt){
              
              setChartDataTableOpen(opt);

              var table = "<button id='NormalDistributionExport'> Export excel </button>";

              table += '<table style="width:100%;text-align:center;" id="NormalDistributionTable"><tbody><tr>'
                    + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;">انحراف</th>'
                    + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;">میانگین</th>'
                    + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;">جمعیت</th>'
                    + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;">درصد از جمعیت</th>'
                    + '</tr>'

                    +'<tr style="background:#AC3429">'
                    + '<td> Outlier (-) </td>'
                    + '<td>' + (props.dataset[index].deviationSlices[0]).toFixed(1) +' > mean </td>'
                    +'<td>' + props.dataset[index].deviationSlicesCount[0].populationCount + '</td>'
                    +'<td>' + (props.dataset[index].deviationSlicesCount[0].populationPercentage).toFixed(1)  + ' %</td>'
                    + '</tr>'


                    +'<tr style="background:#D5561B">'
                    + '<td> -3 </td>'
                    + '<td>' + (props.dataset[index].deviationSlices[0]).toFixed(1) +'</td>'
                    +'<td>' + props.dataset[index].deviationSlicesCount[1].populationCount + '</td>'
                    +'<td>' + (props.dataset[index].deviationSlicesCount[1].populationPercentage).toFixed(1)  + ' %</td>'
                    + '</tr>'


                    +'<tr style="background:#D86E35">'
                    + '<td> -2 </td>'
                    + '<td>' + (props.dataset[index].deviationSlices[1]).toFixed(1) +'</td>'
                    +'<td>' + props.dataset[index].deviationSlicesCount[2].populationCount + '</td>'
                    +'<td>' + (props.dataset[index].deviationSlicesCount[2].populationPercentage).toFixed(1)  + ' %</td>'
                    + '</tr>'


                    +'<tr style="background:#DF8B57">'
                    + '<td> -1 </td>'
                    + '<td>' + (props.dataset[index].deviationSlices[2]).toFixed(1) +'</td>'
                    +'<td>' + props.dataset[index].deviationSlicesCount[3].populationCount + '</td>'
                    +'<td>' + (props.dataset[index].deviationSlicesCount[3].populationPercentage).toFixed(1)  + ' %</td>'
                    + '</tr>'


                    +'<tr style="background:#E7EAEF">'
                    + '<td> 0 </td>'
                    + '<td>' + (props.dataset[index].deviationSlices[3]).toFixed(1) +'</td>'
                    +'<td> -- </td>'
                    +'<td> -- </td>'
                    + '</tr>'

                    +'<tr style="background:#67CA9A">'
                    + '<td> +1 </td>'
                    + '<td>' + (props.dataset[index].deviationSlices[4]).toFixed(1) +'</td>'
                    +'<td>' + props.dataset[index].deviationSlicesCount[4].populationCount + '</td>'
                    +'<td>' + (props.dataset[index].deviationSlicesCount[4].populationPercentage).toFixed(1)  + ' %</td>'
                    + '</tr>'


                    +'<tr style="background:#36A26E">'
                    + '<td> +2 </td>'
                    + '<td>' + (props.dataset[index].deviationSlices[5]).toFixed(1) +'</td>'
                    +'<td>' + props.dataset[index].deviationSlicesCount[5].populationCount + '</td>'
                    +'<td>' + (props.dataset[index].deviationSlicesCount[5].populationPercentage).toFixed(1)  + ' %</td>'
                    + '</tr>'

                    +'<tr style="background:#127143">'
                    + '<td> +3 </td>'
                    + '<td>' + (props.dataset[index].deviationSlices[6]).toFixed(1) +'</td>'
                    +'<td>' + props.dataset[index].deviationSlicesCount[6].populationCount + '</td>'
                    +'<td>' + (props.dataset[index].deviationSlicesCount[6].populationPercentage).toFixed(1)  + ' %</td>'
                    + '</tr>'

                    +'<tr style="background:#0984e3">'
                    + '<td> Outlier (+) </td>'
                    + '<td>' + (props.dataset[index].deviationSlices[6]).toFixed(1) +' < mean </td>'
                    +'<td>' + props.dataset[index].deviationSlicesCount[7].populationCount + '</td>'
                    +'<td>' + (props.dataset[index].deviationSlicesCount[7].populationPercentage).toFixed(1)  + ' %</td>'
                    + '</tr>'

                    
                  
                  
              table += '</tbody></table>';
              return table;
            }
          },
          saveAsImage: {
            name: HeaderDictionary.Headers[props.dataset[index].index]+ ' توزیع نرمال'
          }
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '8%',
        top: '20%',
        containLabel: true
      },
      tooltip: {
        trigger: 'none',
        
      },
       visualMap: {
        type: 'piecewise',
        show: true,
        dimension: 0,
        seriesIndex: 0,

        top: '30%',
        orient: 'vertical',
        left: 'left',
        
        pieces: [
          //#2ecc71
          
          {
            
            lt: props.dataset[index].deviationSlices[0],
            color: '#f39c12',
            label: 'Outlier '+ (props.dataset[index].deviationSlicesCount[0].populationPercentage).toFixed(1) + "%"
          },
          {
            
            gt: props.dataset[index].deviationSlices[6],
            color: '#0984e3',
            label: 'Outlier '+ (props.dataset[index].deviationSlicesCount[7].populationPercentage).toFixed(1) + "%"
          },
          {
            gt: props.dataset[index].deviationSlices[0],
            lt: props.dataset[index].deviationSlices[1],
            color: '#C61818',
            label: (props.dataset[index].deviationSlicesCount[1].populationPercentage).toFixed(1) + "%"
          },
          {
            gt: props.dataset[index].deviationSlices[1],
            lt: props.dataset[index].deviationSlices[2],
            color: '#B82D2D',
            label: (props.dataset[index].deviationSlicesCount[2].populationPercentage).toFixed(1) + "%"
          },
          {
            gt: props.dataset[index].deviationSlices[2],
            lt: props.dataset[index].deviationSlices[3],
            color: '#C64C4C',
            label: (props.dataset[index].deviationSlicesCount[3].populationPercentage ).toFixed(1) + "%"
          },
          {
            gt: props.dataset[index].deviationSlices[3],
            lt: props.dataset[index].deviationSlices[4],
            color: '#36B593',
            label: (props.dataset[index].deviationSlicesCount[4].populationPercentage ).toFixed(1) + "%"
          },
          {
            gt: props.dataset[index].deviationSlices[4],
            lt: props.dataset[index].deviationSlices[5],
            color: '#1C9071',
            label: (props.dataset[index].deviationSlicesCount[5].populationPercentage ).toFixed(1) + "%"
          }
          ,
          {
            gt: props.dataset[index].deviationSlices[5],
            lt: props.dataset[index].deviationSlices[6],
            color: '#0D6C53',
            label: (props.dataset[index].deviationSlicesCount[6].populationPercentage ).toFixed(2) + "%"
          },
          
        ]
      },
      xAxis: 
        [{
          type: 'value',
          // prettier-ignore
          boundaryGap: [0, '10%'],
          splitLine : false,
          axisLabel:false,
          axisTick: false,
          
          
         
        },
        
        
        
        
        ]
      ,
      yAxis: 
        {
          type: 'value',
          boundaryGap: [0, '10%'],
          splitLine : false,
          show:false
          
          
          
        }
      ,
      series: [
    
        {
          
          type: 'line',
          smooth: 0.6,
          symbol: 'none',
          lineStyle: {
            color: '#3498db',
          },
          markLine: {
            symbol: ['none', 'none'],
            label: { 
              
              show: true,
              position:'start',
              
              formatter : function (params){
                var myLines = props.dataset[0].deviationSlices;
                
                var dev =  params.dataIndex-3;
                var lbl = "";
                if(dev <= 0){
                  lbl = params.value.toFixed(0) + "\n("+dev+")"
                }
                else{
                  lbl = params.value.toFixed(0) + "\n(+"+dev+")"
                }
                
                
                return lbl;
              }
                
              
              
              
              
            },
            
            
            data: [{ xAxis: props.dataset[index].deviationSlices[0] }, 
                    { xAxis: props.dataset[index].deviationSlices[1] }, 
                    { xAxis: props.dataset[index].deviationSlices[2] }, 
                    { xAxis: props.dataset[index].deviationSlices[3] },
                    { xAxis: props.dataset[index].deviationSlices[4] }, 
                    { xAxis: props.dataset[index].deviationSlices[5] },
                    { xAxis: props.dataset[index].deviationSlices[6] },
            
            ]
          },
          areaStyle: {},
          data : props.dataset[index].pdfDataSet
        }
        
      ]
    };

    return NormalDistributionChart;
  }


  const [normalDistributionChart,setNormalDistributionChart] = React.useState(setChart(0));


  const theme = useTheme();
        


  const handleChange = (event, newValue) => {
    setValue(newValue);
    setNormalDistributionChart(setChart(newValue));
    
  };

  const handleChangeIndex = (index) => {
    setValue(index);
    
  };



  

  return ( 

      <Container sx={{marginTop:5,marginBottom:5}}>

          <Grid container>


            <Grid md={12} item container spacing={2}>
              
              <Grid md={9} lg={9} item>
                  <Paper elevation={3} style={{height:470,paddingTop:5,direction:"ltr"}}>
                    <ReactECharts option={normalDistributionChart} style={{height:'100%'}}/>
                    
                  </Paper>
              </Grid>

              <Grid md={3} lg={3} item>
                  <Paper elevation={3} style={{height:470,paddingTop:5}}>
                  <Tabs value={value} onChange={handleChange} aria-label="tabs example" style={{direction:"ltr"}}>
                
                {props.dataset.map((ind) => (

                  <Tab key={ind.Index+"_HTAB"} label={HeaderDictionary.Headers[ind.index]} />

                ) )}

                </Tabs>
                <SwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                  style={{direction:"ltr"}}
                  >

                  {props.dataset.map((ind,i) => (


                    <TabPanel key={ind.index} value={value} index={i} style={{direction:"ltr"}}>

                      <Table aria-label="progressTable" >
                        
                        <TableBody>
                        
                          

                            <StyledTableRow
                              key={ind.index+"1"}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              style={{direction:"ltr"}}
                            >

                              <StyledTableCell component="th" scope="row" style={{textAlign:"left"}}>
                                  Mean
                              </StyledTableCell>

                              <StyledTableCell component="th" scope="row" style={{textAlign:"left"}}>
                                  {props.dataset[i].mean.toFixed(0)}
                              </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow
                              key={ind.index+"2"}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >

                              <StyledTableCell component="th" scope="row" style={{textAlign:"left"}}>
                                  Median
                              </StyledTableCell>

                              <StyledTableCell component="th" scope="row" style={{textAlign:"left"}}>
                                  {props.dataset[i].median.toFixed(0)}
                              </StyledTableCell>

                            </StyledTableRow>


                            <StyledTableRow
                              key={ind.index+"3"}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >

                              <StyledTableCell component="th" scope="row" style={{textAlign:"left"}}>
                                  Standard Deviation
                              </StyledTableCell>

                              <StyledTableCell component="th" scope="row" style={{textAlign:"left"}}>
                                  {props.dataset[i].standardDeviation.toFixed(0)}
                              </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow
                              key={ind.index+"4"}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >

                              <StyledTableCell component="th" scope="row" style={{textAlign:"left"}}>
                                  Variance
                              </StyledTableCell>

                              <StyledTableCell component="th" scope="row" style={{textAlign:"left"}}>
                                  {props.dataset[i].variance.toFixed(0)}
                              </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow
                              key={ind.index+"4"}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >

                              <StyledTableCell component="th" scope="row" style={{textAlign:"left"}}>
                                  CV
                              </StyledTableCell>

                              <StyledTableCell component="th" scope="row" style={{textAlign:"left"}}>
                                  {props.dataset[i].cv.toFixed(0)}%
                              </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow
                              key={ind.index+"4"}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >

                              <StyledTableCell component="th" scope="row" style={{textAlign:"left"}}>
                                  Skewness
                              </StyledTableCell>

                              <StyledTableCell component="th" scope="row" style={{textAlign:"left"}}>
                                  {props.dataset[i].skewness.toFixed(1)}
                              </StyledTableCell>

                            </StyledTableRow>


                        </TableBody>

                      </Table>

                    </TabPanel>


                  ))};



                </SwipeableViews>

                  </Paper>
              </Grid>

            </Grid>

            



          </Grid>

      </Container>
    );
}
 
export default HerdIndexGroupsDistribution;
import { Container, Grid, Paper, Typography, Table, Stack } from '@mui/material';
import { Box } from '@mui/system';
import React, {useContext} from 'react';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CakeIcon from '@mui/icons-material/Cake';
import ReactECharts from 'echarts-for-react';
import { IndividualDashboardContext } from '../Context/IndividualDashboardContext';


function monthDiff(dateFrom, dateTo) {
  return dateTo.getMonth() - dateFrom.getMonth() + 
    (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
 }

const IndividualInfo = () => {

  const {individualData} = useContext(IndividualDashboardContext); 


  let indImg = "";

  if(individualData.lactation == 0){

    var currentTime = new Date();
    var diff_month = monthDiff(new Date(individualData.birthDate.split('T')[0]),currentTime);

    if(diff_month < 7){

      indImg = "Calves.png"

    }
    else if(diff_month > 6 && diff_month <= 12){

      indImg = "./Calves.png"

    }
    else{
      
      indImg = "./Heifer.png"
    }


  }
  else{

    indImg = "./Cow.png"
  }

    return ( 

      <>

        <Box component="div" style={{background:"#34495e",width:"100%",borderTopLeftRadius:15,borderTopRightRadius:15,paddingTop:10,paddingBottom:10,display:'flex',justifyContent:'center'}}>
            <Typography variant="h6" color="#fff">
            Information 
            </Typography>
        </Box>
        <Paper style={{height:400}}>

          <Grid style={{paddingBottom:20,paddingTop:20,direction:'ltr'}}>
            <Stack>
                <Grid md={12} style={{display:'flex',justifyContent:'center'}}>

                    <img src={indImg} style={{height:'50%'}}/>
                </Grid>
                <Paper style={{height:"100%",background:"#16a085",borderRadius:30,paddingTop:15,paddingBottom:15,paddingRight:15,paddingLeft:15,display:'flex',justifyContent:'space-between',alignItems:'center',marginLeft:15,marginRight:15}}>
                    
                        <Stack direction="row">
                            <LocalOfferIcon style={{fontSize:30}}/>
                            <Typography variant='h6' style={{marginLeft:5,fontWeight:'bold',color:"#fff"}}>
                                {individualData.tag}
                            </Typography>
                        </Stack>

                        <Stack direction="row">
                            <CakeIcon style={{fontSize:30}}/>
                            <Typography variant='h6' style={{marginLeft:5,fontWeight:'bold',color:"#fff"}}>
                              {individualData.birthDate.split('T')[0]}
                            </Typography>
                        </Stack>

                        <Stack direction="row">
                            <Typography variant='h6' style={{marginLeft:5,fontWeight:'bold',color:"#000"}}>
                                LAC
                            </Typography>
                            <Typography variant='h6' style={{marginLeft:5,fontWeight:'bold',color:"#fff"}}>
                              {individualData.lactation}
                            </Typography>
                        </Stack>
                    
                </Paper>

            </Stack>
            
          </Grid>


        </Paper>

      </>

     );
}
 
export default IndividualInfo;
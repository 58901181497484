import React, { useState , createContext,useEffect } from 'react';
import axios from "axios";
import AuthService from './../Services/AuthService';

export const GEHerdPercentileContext = createContext();


const GEHerdPercentileContextProvider = (props) => {
    const authservice = new AuthService();
    const [HerdIdPercentile,setHerdIdHerdIdPercentile] = useState();
    
    const [PercentileDataTable,setPercentileDataTable] = useState([]);
    

    useEffect( async () => {


        if(typeof HerdIdPercentile !== 'undefined' && HerdIdPercentile != null){
            
            var authuser = await authservice.AuthGetUser();

            const headers = {
                Accept: 'application/json',
                Authorization: 'Bearer ' + authuser.access_token
            };


            await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/HerdReport/Percentile?HerdId=${HerdIdPercentile}`,{headers}).then(res => {
            setPercentileDataTable(res.data.data);
            
        });
        
        }
        
    },[HerdIdPercentile]);


    
    return ( 

        <GEHerdPercentileContext.Provider
        
        value={{
            PercentileDataTable,
            setHerdIdHerdIdPercentile
            

        }}
        
        >
            {props.children}
        </GEHerdPercentileContext.Provider>

     );
}
 
export default GEHerdPercentileContextProvider;
import React, { useState , createContext,useEffect } from 'react';
import axios from "axios";
import AuthService from './../Services/AuthService';

export const GEHerdEvaluationBenchmarkContext = createContext();

const GEHerdEvaluationBenchmarkContextProvider = (props) => {
    const authservice = new AuthService();
    const [benchmarkIndexSelect,setBenchmarkIndexSelect] = useState("NET_MERIT");
    const [benchmarkIndexList,setBenchmarkIndexList] = useState([]);

    const [HerdIdEvalBench,setHerdIdHerdIdEvalBench] = useState();

    const [evalDate,setEvalDate] = useState('');

    useEffect( async () => {

        var authuser = await authservice.AuthGetUser();

        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authuser.access_token
        };

        var EvalDate = await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/EvaluationDate`,{headers});

        setEvalDate(`${EvalDate.data.month} ${EvalDate.data.year}`);


        await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/ReportConfiguration?section=BenchmarkReport`,{headers}).then(res => {
            setBenchmarkIndexList(res.data.activeIndex);
            setBenchmarkIndexSelect(res.data.activeIndex[0]);
        });
    },[]);


    const handleChangeBenchmarkIndex = (e) => {
        setBenchmarkIndexSelect(e.target.value);
    }

    


    const [byCategoryGroupList,setByCategoryGroupList] = useState(["BirthYear","Lactation"])
    const [byCategoryGroupSelect,setByCategoryGroupSelect] = useState("BirthYear");


    const handleChangeBenchmarkByCategoryGroup = (e) => {
        setByCategoryGroupSelect(e.target.value);
    }



    const [BenchmarkDataset , setBenchmarkDataset] = useState({})

    const getDataset = async (Id,index,category) => {

        var authuser = await authservice.AuthGetUser();

        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authuser.access_token
        };

        await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/HerdReport/Benchmark?HerdId=${Id}&Index=${index}&Type=${category}`,{headers}).then(res => {

            setBenchmarkDataset(res.data);
        })
    }


    useEffect(() => {
        if(typeof HerdIdEvalBench !== 'undefined' && HerdIdEvalBench != null){

            getDataset(HerdIdEvalBench,benchmarkIndexSelect,byCategoryGroupSelect);
        }
        

    },[benchmarkIndexSelect,byCategoryGroupSelect,HerdIdEvalBench])


    return ( 

        <GEHerdEvaluationBenchmarkContext.Provider

            value={{
                benchmarkIndexList,
                benchmarkIndexSelect,
                setBenchmarkIndexSelect,
                handleChangeBenchmarkIndex,
                byCategoryGroupList,
                byCategoryGroupSelect,
                handleChangeBenchmarkByCategoryGroup,
                BenchmarkDataset,
                setHerdIdHerdIdEvalBench,
                evalDate

            }}
        
        >
            {props.children}
        </GEHerdEvaluationBenchmarkContext.Provider>

     );
}
 
export default GEHerdEvaluationBenchmarkContextProvider;


import { Switch, TextField , Stack , FormControl , InputLabel , Select , MenuItem, Grid} from "@mui/material"
import React , { useContext, useEffect, useState } from "react"
const SpermOrdering = ({selectionMenu , context}) => {
    const { SetOrderingValue } = context

    const [isDescending,setIsDescending] = useState(false)
    const [orderBy,setOrderBy] = useState('')

    const toggleDescending = () => {
        setIsDescending(!isDescending)
    }

    const handleOrderByChange = (e) => {
        setOrderBy(e.target.value)
        console.log(orderBy)
    }

    useEffect(() =>{
        console.log('sss',orderBy,isDescending)
        SetOrderingValue(orderBy,isDescending)
    },[orderBy,isDescending])

    return(
        <Stack spacing={2} >
            <Stack xs={{maxWidth : '90%' , padding : '20px'}}>
                <FormControl> 
                    <InputLabel id="selection-label">
                    ترتیب
                    </InputLabel>
                    <Select
                    autoWidth
                    labelId="selection-label"
                    id="propertySelection"
                    onChange={e => handleOrderByChange(e)}
                    >
                    {selectionMenu.map(item =>(
                        <MenuItem key={item} value={item}>
                        {item}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
            </Stack>

            <Stack direction="row">
                <label>صعودی</label>
                <Switch onChange={toggleDescending}/>
                <label>نزولی</label>
            </Stack>
        </Stack>
    )
}

export default SpermOrdering
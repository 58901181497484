import { Dashboard } from '@mui/icons-material';
import React, {useContext, useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from '../Components/Layout';
import { AuthContext } from '../Context/AuthContext';
import GEHerdDistributionContextProvider from '../Context/GEHerdDistributionContext';
import GEHerdPopulationContextProvider from '../Context/GEHerdPopulationContext';
import GEHerdProgressContextProvider from '../Context/GEHerdProgressContext';
import ProfileContextProvider from '../Context/ProfileContext';
import GE_HerdDashboard from '../Pages/Dashboard';
import GE_HerdView from '../Pages/GE_HerdView';
import GE_HerdEvaluation from '../Pages/Evaluation';
import GEHerdPercentileContextProvider from '../Context/GEHerdPercentileContext';
import GEHerdEvaluationBenchmarkContextProvider from '../Context/GEHerdEvaluationBenchmarkContext';
import GEHerdEvaluationDistributionContextProvider from '../Context/GEHerdEvaluationDistributionContext';
import GEYearLactContextProvider from '../Context/GEYearLactContext';
import Report from '../Pages/Report';
import GESireLineReportContextProvider from '../Context/GESireLineReportContext';
import GEReportContextProvider from '../Context/GEReportContext';
import Herd from '../Pages/Herd';
import HerdIndividualListContextProvider from '../Context/HerdIndividualListContext';
import IndividualDashboardContextProvider from '../Context/IndividualDashboardContext';
import IndividualDashboard from '../Pages/IndividualDashboard';
import BeefSpermCatalogContextProvider, { BeefSpermCatalogContext } from '../Context/SpermCatalogs//BeefSpermCatalogContext';
import BeefSpermCatalog from '../Pages/BeefSpermCatalog'
import DairySpermCatalog from '../Pages/DairySpermCatalog'
import DairySpermCatalogContextProvider from '../Context/SpermCatalogs/DairySpermCatalogContext'
const AnalyticsRout = () => {

    const {userInfo} = useContext(AuthContext);

    if(userInfo == null){
        return (
            <>
            </>
        )
    }
    else{
        return ( 
            <>
            <Router>
                <ProfileContextProvider>
                    <Layout user={userInfo}>
                        <Routes>

                            <Route exact path="/" element={
                                    <GE_HerdView>
                                        <GEHerdPopulationContextProvider>
                                            <GEHerdDistributionContextProvider>
                                                <GEHerdProgressContextProvider>
                                                    <GE_HerdDashboard/>

                                                </GEHerdProgressContextProvider>
                                            </GEHerdDistributionContextProvider>
                                                
                                            
                                        </GEHerdPopulationContextProvider>
                                    </GE_HerdView>
                                
                                
                            } />


                            <Route exact path="/Evaluation" element={
                                    <GE_HerdView>
                                        <GEHerdPercentileContextProvider>
                                            <GEHerdEvaluationDistributionContextProvider>
                                                <GEHerdEvaluationBenchmarkContextProvider>
                                                    <GEYearLactContextProvider>
                                                        <GE_HerdEvaluation/>
                                                    </GEYearLactContextProvider>
                                                </GEHerdEvaluationBenchmarkContextProvider>
                                            </GEHerdEvaluationDistributionContextProvider>
                                        </GEHerdPercentileContextProvider>
                                       
                                    </GE_HerdView>
                                
                                
                            } />


                            <Route exact path="/Report" element={

                                <GESireLineReportContextProvider>
                                    <GEReportContextProvider>
                                        <Report/>
                                    </GEReportContextProvider>
                                </GESireLineReportContextProvider>
                                
                            } />


                            <Route exact path="/Herd" element={

                                <HerdIndividualListContextProvider>
                                    <Herd/>
                                </HerdIndividualListContextProvider>
                                
                                
                            

                            } />

                            <Route
                            exact
                            path="/individual"
                            element={


                                    <IndividualDashboardContextProvider>
                                        <IndividualDashboard/>
                                    </IndividualDashboardContextProvider>

                            }
                            />

                            <Route
                            exact
                            path="/sperms/beef"
                            element={


                                    <BeefSpermCatalogContextProvider>
                                        <BeefSpermCatalog/>
                                    </BeefSpermCatalogContextProvider>

                            }
                            />

                            <Route
                            exact
                            path="/sperms/dairy"
                            element={
                                <DairySpermCatalogContextProvider>
                                    <DairySpermCatalog/>
                                </DairySpermCatalogContextProvider>
                            }
                            />
                        </Routes>
                    </Layout>
                </ProfileContextProvider>
                
            </Router>




        </>  
            
         );
    }
    

    
}
 
export default AnalyticsRout;
import React , { useState , useContext } from "react"
import { Stack , TextField , Button , Grid} from "@mui/material";

const BeefStringFilters = ({context}) => {

    const {
      SetStringFilters,
      spermFilters
    } = context


    //const [changes,setChanges] = useState(stringObjects)
    const handleChange = (e) => {
      const value = e.target.value;
      const name = e.target.name;
      //setChanges();
      SetStringFilters(name,value)
    }
    
    //console.log(changes)

    return(
        <Stack spacing={2} sx={{maxWidth : '90%' , padding : '20px'}}>
            <Stack direction="row" spacing={2}>
              <TextField value={spermFilters.RegNo} name="RegNo" label="RegNo" variant="outlined" onChange={ (e) => handleChange(e)}/>
              <TextField value={spermFilters.Name} name="Name" label="Name" variant="outlined" onChange={ (e) => handleChange(e)}/>
            </Stack>
            <Stack direction="row" spacing={2}>
              <TextField value={spermFilters.Gender}name="Gender" label="Gender " variant="outlined" onChange={ (e) =>handleChange(e)}/>
              <TextField value={spermFilters.MGS} name="MGS" label="Mgs " variant="outlined" onChange={ (e) => handleChange(e)}/>
            </Stack>
            <Stack direction="row" spacing={2}>
              <TextField value={spermFilters.Breed} name="Breed" label="Breed" variant="outlined" onChange={ (e) => handleChange(e)}/>
              <TextField value={spermFilters.QuantityStatus} name="QuantityStatus" label="QuantityStatus" variant="outlined" onChange={ (e) => handleChange(e)}/>
            </Stack>
            <Stack direction="row" spacing={2}>
              <TextField value={spermFilters.Sire} name="Sire" label="Sire" variant="outlined" onChange={ (e) => handleChange(e)}/>
            </Stack>
        </Stack>
    )
}

export default BeefStringFilters
import React, { useState , createContext,useEffect } from 'react';
import axios from "axios";
import AuthService from './../Services/AuthService';

export const GEHerdEvaluationDistributionContext = createContext();

const GEHerdEvaluationDistributionContextProvider  = (props) => {
    const authservice = new AuthService();
    const [DistributionIndexSelect,setDistributionIndexSelect] = useState("NET_MERIT");
    const [DistributionIndexList,setDistributionIndexList] = useState([]);

    const [HerdIdEvalDistribution,setHerdIdEvalDistribution] = useState();

    const [evalDate,setEvalDate] = useState('');

    useEffect( async () => {
        var authuser = await authservice.AuthGetUser();

        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authuser.access_token
        };

        var EvalDate = await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/EvaluationDate`,{headers});

        setEvalDate(`${EvalDate.data.month} ${EvalDate.data.year}`);

        await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/ReportConfiguration?section=NormalDistributionReport`,{headers}).then(res => {
            setDistributionIndexList(res.data.activeIndex);
            setDistributionIndexSelect(res.data.activeIndex[0]);
        });
    },[]);


    const [DistributionYearSelect,setDistributionYearSelect] = useState("ALL");
    const [DistributionYearList,setDistributionYearList] = useState([]);

    useEffect( async () => {

        var authuser = await authservice.AuthGetUser();

        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authuser.access_token
        };


        await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/HerdReport/herdAllBirthYear?HerdId=${HerdIdEvalDistribution}`,{headers}).then(res => {
            setDistributionYearList(res.data);

            setDistributionYearSelect(res.data[0]);
        });
    },[HerdIdEvalDistribution]);



    const [DistributionLacSelect,setDistributionLacSelect] = useState("ALL");
    const [DistributionLacList,setDistributionLacList] = useState([
        "ALL", "CALVES-1", "CALVES-2", "HEIFER", "LACT-1", "LACT-2", "LACT-3", "LACT-4", "LACT>4"
        
    ]);


    const handleChangeDistributionIndex = (e) => {
        setDistributionIndexSelect(e.target.value);
    }


    const handleChangeDistributionYear = (e) => {
        setDistributionYearSelect(e.target.value);
    }


    const handleChangeDistributionLactation = (e) => {
        setDistributionLacSelect(e.target.value);
    }


    const [DistributionDataset,setDistributionDataset] = useState();

    const getDataset = async (Id,index,Year,Lactation) => {


        var authuser = await authservice.AuthGetUser();

        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authuser.access_token
        };


        await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/HerdReport/NormalDistribution?HerdId=${Id}&Index=${index}&Year=${Year}&Lactation=${Lactation}`,{headers}).then(res => {

            setDistributionDataset(res.data.data);
        })
    }


    useEffect(() => {

        if(typeof HerdIdEvalDistribution !== 'undefined' && HerdIdEvalDistribution != null){
            getDataset(HerdIdEvalDistribution,DistributionIndexSelect,DistributionYearSelect,DistributionLacSelect);

        }

    },[DistributionIndexSelect,DistributionYearSelect,DistributionLacSelect,HerdIdEvalDistribution])
    return ( 

        <GEHerdEvaluationDistributionContext.Provider

            value={{

                DistributionIndexSelect,
                DistributionIndexList,
                DistributionYearSelect,
                DistributionYearList,
                DistributionLacSelect,
                DistributionLacList,
                handleChangeDistributionIndex,
                handleChangeDistributionYear,
                handleChangeDistributionLactation,
                DistributionDataset,
                setHerdIdEvalDistribution,
                evalDate

            }}
        
        >
            {props.children}
        </GEHerdEvaluationDistributionContext.Provider>
     );
}
 
export default GEHerdEvaluationDistributionContextProvider ;
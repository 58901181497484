import { Grid, Container, Paper, Box, Stack ,FormControl,MenuItem,InputLabel,Select, Typography} from '@mui/material';
import React,{useState ,useContext,useEffect} from 'react';
import ReactECharts from 'echarts-for-react';
import { GEHerdEvaluationBenchmarkContext } from './../Context/GEHerdEvaluationBenchmarkContext';

import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import HeaderDictionary from '../Helper/HeaderDictionary';

const BenchmarkComparsion = (props) => {
    
    const {
        benchmarkIndexList,
        benchmarkIndexSelect,
        handleChangeBenchmarkIndex,
        byCategoryGroupList,
        byCategoryGroupSelect,
        handleChangeBenchmarkByCategoryGroup,
        BenchmarkDataset,
        setHerdIdHerdIdEvalBench,
        evalDate

    } = useContext(GEHerdEvaluationBenchmarkContext);

    setHerdIdHerdIdEvalBench(props.HerdId);

    let BenchmarkDataValue = BenchmarkDataset.benchmarkYAxis;
    let TopmarkDataValue = BenchmarkDataset.topmarkYAxis;
    let HerdMeans = BenchmarkDataset.herdYaxisMeanValues;

    let BenchmarkDataValueLine = [];
    let TopmarkDataValueLine = [];
    let HerdMeansLine = [];

    if(typeof BenchmarkDataValue !== 'undefined'){
        BenchmarkDataValue = BenchmarkDataset.benchmarkYAxis.map(x=> x.toFixed(2))
        BenchmarkDataValueLine = BenchmarkDataValue.slice(0, -1);
    }

    if(typeof TopmarkDataValue !== 'undefined'){
        TopmarkDataValue = BenchmarkDataset.topmarkYAxis.map(x=> x.toFixed(2))
        TopmarkDataValueLine = TopmarkDataValue.slice(0, -1);
    }

    if(typeof HerdMeans !== 'undefined'){
        HerdMeans = BenchmarkDataset.herdYaxisMeanValues.map(x=> x.toFixed(2))
        HerdMeansLine = HerdMeans.slice(0, -1);
    }


    var index = benchmarkIndexSelect;

    var byCategory = "Birth Year";
    var bycat = 'سال تولد';
    var evalD = evalDate;

    if(byCategoryGroupSelect == "Lactation"){
        byCategory = "Lactation";
        bycat = "شکم"
    }

    const [chartDataTableOpen,setChartDataTableOpen] = useState('');

    const exportToExcel = () => {


        const table = document.getElementById('benchmarkTable');
        const worksheet = utils.table_to_sheet(table);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = write(workbook, { type: 'array', bookType: 'xlsx' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(data, 'Herd_'+byCategoryGroupSelect+'_Benchmark_in_'+benchmarkIndexSelect+'.xlsx');
    };

    useEffect(() => {
        const buttonElement = document.getElementById('BenchmarkExport');
        if (buttonElement) {
        buttonElement.addEventListener('click', exportToExcel);
        }

    },[chartDataTableOpen]);

    let option = {
        title: {
            right:'right',
            text: HeaderDictionary.Headers[index]+ ' بنچمارک {a|            }',
            subtext: evalD+' ارزیابی ',
            textStyle:{
        
          
              rich: {
  
                a: {
                    backgroundColor: {
                        image: './logo.png'
                    },
                    height: 50
                },
  
                
              }
            }
          },
          toolbox: {
            left:'auto',
            show: true,
            feature: {
              dataZoom: {
                yAxisIndex: "none"
              },
              dataView: {
                readOnly: false,
                optionToContent: function(opt){
            
                  var axisData = opt.xAxis[0].data;
                  var axis2data = opt.xAxis[1].data;
                  var series = opt.series;

                  setChartDataTableOpen(opt.series);
                  var table = "<button id='BenchmarkExport'> Export excel </button>";

                  table += '<table style="width:100%;text-align:center;" id="benchmarkTable"><tbody><tr>'
                         + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;">'+bycat+'</th>'
                         + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;">جمعیت</th>'

                         + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;">' + HeaderDictionary.Headers[index] + '</th>'
                         + '<th style="background:#e67e22;color:#fff;padding-top:10px;padding-bottom:10px;">' + HeaderDictionary.Headers[index] + ' Benchmark</th>'
                         + '<th style="background:#3498db;color:#fff;padding-top:10px;padding-bottom:10px;"> Top ' + HeaderDictionary.Headers[index] + '</th>'
                         + '</tr>';
                  for (var i = 0, l = axisData.length; i < l; i++) {
                        table += '<tr style="background:#E7EAEF">'
                         + '<td>' + axisData[i] + '</td>'
                         + '<td>' + axis2data[i] + '</td>'
                         +'<td>' + series[1].data[i] + '</td>'
                         +'<td style="background:#ea9a52">' + series[2].data[i] + '</td>'
                         + '<td style="background:#83d7ef">' + series[0].data[i] + '</td>'
                         + '</tr>';
                      }
                      
                  table += '</tbody></table>';
                  return table;
                }
              },
              saveAsImage: {
                name: byCategoryGroupSelect+' '+benchmarkIndexSelect+ ' بنچمارک'
              }
            }
          },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '8%',
            top: '18%',
            containLabel: true
        },
        legend: {
            data: ['Top', 'Herd', 'Benchmark' , 'Top Trend', 'Herd Trend', 'Benchmark Trend' , 'Deviation', 'Outliers'],
            top: 0,
            left: 'center',
            textStyle: {
                color: "#34495e"
            },
            itemWidth: 12,
            itemHeight: 10,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: [
          {
            type: 'category',
            data: BenchmarkDataset.herdXAxisYearLac,
            axisTick: {
              alignWithLabel: false
            }
          },
          {
            type: 'category',
            data: BenchmarkDataset.herdXAxisPopulation,
            position : 'top',
            splitLine : false,
            axisTick: false
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitArea: {
                show: true
            }
          }
        ],
        series: [
            {
                name: 'Top',
                type: 'bar',
                barWidth: '15%',
                itemStyle: {
                    normal: {
                        color: "#3498db",
                        barBorderRadius: 11,
                    }
                },
                label: {
                    normal: {
                        show: true,
                        position: 'inside',
                        rotate: 90,
                        color: '#fff'
                    }
                },
                data: TopmarkDataValue
              },

            {
                name: 'Herd',
                type: 'bar',
                barWidth: '30%',
                itemStyle: {
                    normal: {
                        color: "#27ae60",
                        barBorderRadius: 11,
                    }
    
                },
                label: {
                    normal: {
                        show: true,
                        position: 'inside',
                        rotate: 90,
                        color: '#fff'
                    }
                },
                data: HerdMeans
              },

          
          {
            name: 'Benchmark',
            type: 'bar',
            barWidth: '15%',
            itemStyle: {
                normal: {

                    color: "#e67e22",
                    barBorderRadius: 12,
                },
            },
            label: {
                normal: {

                    show: true,
                    position: 'inside',
                    rotate: 90,
                    color: '#fff'
                }
            },
            
            data: BenchmarkDataValue
          },
          {
            name: 'Herd Trend',
            type: 'line',
            stack: 'Herd',
            symbolSize: 7,
            symbol: 'circle',
            itemStyle: {
                normal: {
                    color: "#27ae60",
                    barBorderRadius: 0

                }
            },
            data: HerdMeansLine
          },
          {
            name: 'Top Trend',
            type: 'line',
            stack: 'Top',
            symbolSize: 7,
            symbol: 'circle',
            itemStyle: {
                normal: {
                    color: "#3498db",
                    barBorderRadius: 0

                }
            },
            data: TopmarkDataValueLine
        },
        {
            name: 'Benchmark Trend',
            type: 'line',
            stack: 'Benchmark',
            symbolSize: 7,
            symbol: 'circle',
            itemStyle: {
                normal: {
                    color: "#e67e22",
                    barBorderRadius: 0

                }
            },
            data: BenchmarkDataValueLine
        },
          
          
        ]
      };

    
    return ( 


        <Grid container spacing={2} sx={{marginTop:5}} style={{direction:"ltr"}}>
        <Box component="div" style={{background:"#34495e",width:"100%",borderTopLeftRadius:15,borderTopRightRadius:15}}>
                <Grid sm={12} md={12} style={{width:"100%",display:"flex",justifyContent:"center",paddingTop:10,paddingBottom:10}}>
                <Typography variant="h6" color="#fff">
                    روند ژنتیکی
                </Typography>
                </Grid>
                <Grid spacing={2} sm={12} md={12} style={{width:"100%",display:"flex",justifyContent:"space-between",background:"#fff",paddingTop:10}}>
                <FormControl style={{borderColor:"#fff",padding:5}} fullWidth >
                    <InputLabel id="demo-simple-select-label">شاخص</InputLabel>
                    <Select
                        
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={benchmarkIndexSelect}
                        label="شاخص"
                        
                        onChange={handleChangeBenchmarkIndex}
                    >
                        
                        {
                            benchmarkIndexList.map((val) => {
                                return (
                                    <MenuItem style={{direction:'ltr'}} key={val} value={val}>
                                        {HeaderDictionary.Headers[val]}
                                    </MenuItem>
                                );
                            })
                        }

                        
                        
                    </Select>
                </FormControl>
                

                <FormControl style={{borderColor:"#fff",padding:5}} fullWidth >
                    <InputLabel id="demo-simple-select-label">دسته بندی</InputLabel>
                    <Select
                        
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={byCategoryGroupSelect}
                        label="دسته بندی"
                        
                        onChange={handleChangeBenchmarkByCategoryGroup}
                    >
                        
                        <MenuItem value={byCategoryGroupList[0]}>گروه سنی</MenuItem>
                        <MenuItem value={byCategoryGroupList[1]}>گروه شکمی</MenuItem>
                        
                    </Select>
                </FormControl>

                

                </Grid>
            </Box>
            <Grid md={12}>
                <Paper elevation={3} style={{height:600,paddingTop:5}}>
                    <ReactECharts option={option} style={{height:'100%'}}/>
                </Paper>

            </Grid>


        </Grid>
        

     );


    }
 
export default BenchmarkComparsion;
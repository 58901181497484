import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { Avatar, Container, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputLabel, MenuItem, NativeSelect, Radio, RadioGroup, Select, Stack, Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useContext } from 'react';
import { Link } from "react-router-dom";
import { HerdIndividualListContext } from '../Context/HerdIndividualListContext';

function monthDiff(dateFrom, dateTo) {
  return dateTo.getMonth() - dateFrom.getMonth() + 
    (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
 }


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#555555',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


const IndividualCattleTable = (props) => {

  const {HandleChangePage,HandleChangeRowPerPage,currentPage} = new useContext(HerdIndividualListContext);

  const HandleForwardPage = () => {
    var newPage = currentPage + 1;
    HandleChangePage(newPage);
  }


  const HandleBackwardPage = () => {
    var newPage = currentPage - 1;
    HandleChangePage(newPage);
  }

  return ( 

      <>
        <div>
        <TableContainer sx={{width:'100%'}} component={Paper}>

            <Stack direction="row" spacing={2} style={{direction:'ltr',display:"flex",justifyContent:"space-evenly",alignItems:"center"}}>
                          
                          <Typography style={{marginRight:20}}>
                            Total : {props.totalRecords}
                          </Typography>
      
                          <Stack direction="row" spacing={2} style={{marginRight:20}}>
                            <Typography>
                              Record Per Page  
                            </Typography>
                            <NativeSelect
                              defaultValue={50}
                              style={{marginRight:20}}
                              onChange={(e) => {HandleChangeRowPerPage(e.target.value)}}
                            >
                              <option value={20}>20</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </NativeSelect>
                          </Stack>
                            
                          
                          
      
                          <div style={{marginRight:20}}>
                            <Typography>
                              Page : {props.currentPage}
                            </Typography>
                          </div>
      
      
                          
                          <Stack direction="row" spacing={10} style={{marginRight:20}}>
      
                            <IconButton sx={{color:'#57606f'}} onClick={() => {
                              HandleBackwardPage();
                            }}>
                              <ArrowBackIosNewIcon />
                            </IconButton>
      
                            <IconButton sx={{color:'#57606f'}} onClick = {() => {
                                HandleForwardPage();
                            }}>
                              <ArrowForwardIosIcon />
                            </IconButton>
                            
      
                          </Stack>
      
            </Stack>

            <Table sx={{width:'100%'}} aria-label="customized table">
                      
                <TableHead>

                
                <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>TAG</StyledTableCell>
                    <StyledTableCell>تاریخ تولد</StyledTableCell>
                    <StyledTableCell>گروه شکمی</StyledTableCell>
                    <StyledTableCell>DAM</StyledTableCell>
                    <StyledTableCell>SIRE</StyledTableCell>
                    <StyledTableCell>MGS</StyledTableCell>
                    <StyledTableCell>MGGS</StyledTableCell>
                    <StyledTableCell align="center"></StyledTableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                


                    {props.dataTable.map((row) => {
                      
                      var LactGroup = "";

                      var CattleLacTypeImage = "";

                      
                      if(row.lactation == 0){

                        var currentTime = new Date();
                        var diff_month = monthDiff(new Date(row.birthDate.split("T")[0]),currentTime);

                        if(diff_month < 7){
                          CattleLacTypeImage = "./Calves.png";
                          LactGroup = row.lactation + " [ Calve- < 6 month ]";

                        }
                        else if(diff_month > 6 && diff_month <= 12){
                          CattleLacTypeImage = "./Calves.png";
                          LactGroup = row.lactation + " [ Calve- > 6 month ]";

                        }
                        else{
                          CattleLacTypeImage = "./Heifer.png";
                          LactGroup = row.lactation + " [ HEIFER ]";
                          
                        }


                      }
                      else{
                        CattleLacTypeImage = "./Cow.png";
                        LactGroup = row.lactation
                      }


                      return(
                        <StyledTableRow>
                          <StyledTableCell>
                            <img src={CattleLacTypeImage} alt=""  style={{width:'50px' , height:'50px'}}/>
                          </StyledTableCell>
                          <StyledTableCell>
                              {row.tag}
                          </StyledTableCell>
                          <StyledTableCell>{row.birthDate.split("T")[0]}</StyledTableCell>
                          <StyledTableCell>{LactGroup}</StyledTableCell>
                          <StyledTableCell>X</StyledTableCell>
                          <StyledTableCell>{row.sire}</StyledTableCell>
                          <StyledTableCell>{row.mgs}</StyledTableCell>
                          <StyledTableCell>{row.mggs}</StyledTableCell>
                          <StyledTableCell align="center" >

                              <Stack direction="row" spacing={1} sx={{justifyContent:'center'}}>
                                  
                                  <IconButton sx={{color:'#57606f'}}  button component={Link} to={`/individual?Tag=${row.tag}`} target="_blank">
                                    <VisibilityOutlinedIcon />
                                  </IconButton>
                              </Stack>
                          </StyledTableCell>
                        </StyledTableRow>
                      )

                })}


                </TableBody>
                <TableFooter>
             
                </TableFooter>
            </Table>
            <Stack direction="row" spacing={2} style={{direction:'ltr',display:"flex",justifyContent:"space-evenly",alignItems:"center"}}>
                          
                          <Typography style={{marginRight:20}}>
                            Total : {props.totalRecords}
                          </Typography>
      
                          <Stack direction="row" spacing={2} style={{marginRight:20}}>
                            <Typography>
                              Record Per Page  
                            </Typography>
                            <NativeSelect
                              defaultValue={50}
                              style={{marginRight:20}}
                              onChange={(e) => {HandleChangeRowPerPage(e.target.value)}}
                            >
                              <option value={20}>20</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </NativeSelect>
                          </Stack>
                            
                          
                          
      
                          <div style={{marginRight:20}}>
                            <Typography>
                              Page : {props.currentPage}
                            </Typography>
                          </div>
      
      
                          
                          <Stack direction="row" spacing={10} style={{marginRight:20}}>
      
                            <IconButton sx={{color:'#57606f'}} onClick={() => {
                              HandleBackwardPage();
                            }}>
                              <ArrowBackIosNewIcon />
                            </IconButton>
      
                            <IconButton sx={{color:'#57606f'}} onClick = {() => {
                                HandleForwardPage();
                            }}>
                              <ArrowForwardIosIcon />
                            </IconButton>
                            
      
                          </Stack>
      
            </Stack>
        </TableContainer>
        </div>
      
      </>

    );
}
 
export default IndividualCattleTable;
import React , {useState , useContext } from 'react';
import {
    List, Box, Stack,
    ListItem , Drawer,
    Accordion , AccordionSummary ,
    Typography , AccordionDetails , Button,
    Paper,
    Container
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BeefStringFilters from './BeefStringFilters';
import SpermsRangeFilter from '../SpermsRangeFilter';
import SpermOrdering from '../SpermOrdering';

import { BeefSpermCatalogContext } from '../../../Context/SpermCatalogs/BeefSpermCatalogContext'; 
import { BorderAll } from '@mui/icons-material';
const BeefFilter = ({drawerStatus,toggleDrawer}) => {

      const selectionMenu = ['SCE','CR','DM','PCAR','RDT','CONF','COUL','GRAS','IAB','ICRC']
      const{
        HandleMaxValueFilter,
        HandleMinValueFilter,
        HandleIndexFilter,
        AddRangeFilterToFilters,
        FilterSperms,
        rangeIndexList,
        RemoveFromRangeIndexList,
        ResetAllValues,
        SetStringFilters,
        spermFilters,
        SetOrderingValue
      } = useContext(BeefSpermCatalogContext)

    const resetRangeFilters = () => {
        var minIndex = document.querySelector('#indexmin')
        var maxIndex =  document.querySelector('#indexmax')

        minIndex.value = ''
        maxIndex.value = ''

        }
    return (
        

        <div dir="ltr">
            <Drawer 
            anchor='right'
            open={drawerStatus} 
            onClose={toggleDrawer} 
            >
                <Box>
                    <List >
                        <ListItem sx={{width : '100%'}}>
                            <Container>
                            <Stack spacing={4} align="center">
                                    <h1>فیلتر ها</h1>
                                    {/* string filters */}
                                    <Paper elevation={6}>
                                        <Accordion>
                                        <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        >
                                        <Typography variant='h5'>تنظیم مقادیر</Typography>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <BeefStringFilters context={{
                                                    SetStringFilters,
                                                    spermFilters
                                                }}/>
                                        </AccordionDetails>
                                        </Accordion>
                                    </Paper>
                                    
                                    {/* <Divider sx={{maxWidth : '85%'}}/> */}
                                    <Paper elevation={6}>
                                        <Accordion>
                                            <AccordionSummary
                                            expandIcon={<ArrowDropDownIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            >
                                            <Typography variant='h5'>تنظیم ترتیب</Typography>
                                            </AccordionSummary>
                                            
                                            <AccordionDetails>
                                                    <SpermOrdering selectionMenu={selectionMenu} context={{SetOrderingValue}}/>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Paper>


                                    <Paper elevation={6}>
                                        <Accordion>
                                            <AccordionSummary
                                            expandIcon={<ArrowDropDownIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            >
                                            <Typography variant='h5'>تنظیم بازه</Typography>
                                            </AccordionSummary>
                                            
                                            <AccordionDetails>
                                                <SpermsRangeFilter selectionMenu={selectionMenu} context={
                                                    {
                                                        HandleMaxValueFilter,
                                                        HandleMinValueFilter,
                                                        HandleIndexFilter,
                                                        AddRangeFilterToFilters,
                                                        rangeIndexList,
                                                        RemoveFromRangeIndexList
                                                    }
                                                }/>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Paper>
                                    


                                    <Stack spacing={3} align='center' mt={2} >
                                        <Button variant="contained" onClick={ () => FilterSperms()}> فیلتر </Button>
                                        <Button variant="outlined" onClick={() =>{
                                            resetRangeFilters()
                                            ResetAllValues()
                                            }}>ریست</Button>
                                    </Stack>
                                </Stack>
                            </Container>

                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </div >
    );
}
export default BeefFilter;
import React from 'react'
import { WebStorageStateStore } from 'oidc-client-ts';
import { UserManager } from 'oidc-react';

const SigninCallBack = () => {
    var config = {
        userStore: new WebStorageStateStore({store: window.localStorage}),
        authority: process.env.REACT_APP_IDENTITY_BASE_URL,
        client_id: process.env.REACT_APP_CLIENT_ID,
        redirect_uri: `${process.env.REACT_APP_CLIENT_URL}/signincallback`,
        client_secret: process.env.REACT_APP_CLIENT_CODE,
        response_type: "code",
        scope:"openid profile HerdManagementApi",
        post_logout_redirect_uri : `${process.env.REACT_APP_CLIENT_URL}/logout`,
        loadUserInfo: true
        };
 
    

    var userManager = new UserManager(config);

    userManager.signinCallback().then(res => {
        
        window.location.href = '/';
    });
}
 
export default SigninCallBack;

import { AppBar, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, Avatar, Stack } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState } from 'react';
import { Box } from '@mui/system';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ArchiveIcon from '@mui/icons-material/Archive';
import { Window } from '@mui/icons-material';
import { Icon } from '@iconify/react';
import PersonIcon from '@mui/icons-material/Person';
import FeedIcon from '@mui/icons-material/Feed';
import GridViewIcon from '@mui/icons-material/GridView';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import IranSans from '../font/IranSans.ttf';
import DrawerList from './DrawerList';
import SettingsIcon from '@mui/icons-material/Settings';
import BadgeIcon from '@mui/icons-material/Badge';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AuthService from './../Services/AuthService';
import LogoutIcon from '@mui/icons-material/Logout';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';


import { Link } from "react-router-dom";
import { ProfileContext } from '../Context/ProfileContext';
import { useContext } from 'react';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ProfileEditDialog from './ProfileEditDialog';



//-----------------------------------

  const fontTheme = createTheme({
    typography: {
      fontFamily: 'IranSans',
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'IranSans';
            src: local('IranSans'), url(${IranSans}) format('truetype');
          }
        `,
      },
    },
  });

const theme = createTheme({
    direction: 'rtl',
  });

// Create rtl cache
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });
  
  function RTL(props) {
    return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>;
  }


//-----------------------------------

const drawWidth = 230



const useStyle = makeStyles({
    page:{
        background: '#f9f9f9',
        width: '100%'
    },
    draw:{
        width: drawWidth
    },
    drawPaper:{
        width: drawWidth
    },
    root:{
        display : 'flex' 
    },
    active:{
        background:'#f4f4f4'
    }

})


const Layout = ({children}) => {

    const {username,userImage,herdName,handleOpenEditProfileDialog,
        herdLogo} = useContext(ProfileContext);


    const authservice = new AuthService();

    const classes = useStyle()
    const drawWidth = 230

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            
          <Toolbar sx={{justifyContent:'center',flexDirection:'column'}}>
            <Typography theme={fontTheme} variant='h6' sx={{color:"#fff",marginTop:'15px',marginBottom:'15px'}}>
                {username}
            </Typography>

            <div>
                <div>
                    {userImage != "" ? <Avatar  alt={username} src={userImage} sx={{width:'100px' , height:'100px'}}/> : <Avatar  alt={username} src="/DefaultUser.png" sx={{width:'100px' , height:'100px'}}/>}

                </div>

                
                <Box sx={{marginTop:"-30px !important",marginRight:"-7px !important"}}>
                    {herdLogo != "" ? <Avatar  alt={herdName} src={herdLogo} sx={{width:'50px' , height:'50px'}}/> : <Avatar  alt={herdName} sx={{width:'50px' , height:'50px'}}> <MapsHomeWorkIcon/> </Avatar>}
                </Box>

            </div>


            <Typography theme={fontTheme} variant='body1' sx={{color:"#fff",marginTop:'15px',marginBottom:'15px'}}>
                {herdName}
            </Typography>
           

            
            <Stack direction="Horizontal">
                <IconButton >
                    <LogoutIcon onClick={() => {window.location.href = `${process.env.REACT_APP_CLIENT_URL}/logout`;}}/>
                    
                </IconButton>
                <IconButton >
                    <ManageAccountsIcon onClick={handleOpenEditProfileDialog}/>
                </IconButton>
                
                
            </Stack>   

          </Toolbar>
          
          <Divider />

           <DrawerList/> 
          
        </div>
      );


    return ( 
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
            <CssBaseline />

                <AppBar
                    position="fixed"
                    sx={{
                    width: { md: `calc(100% - ${drawWidth}px)` },
                    background:"#00a594",
                    ml: { sm: `${drawWidth}px` },
                    
                    }}
                    >
                    <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    


                    </Toolbar>
                </AppBar>
                <Toolbar />

                <Box
                    
                    component="nav"
                    sx={{overflow: 'hidden', width: { md: drawWidth }, flexShrink: { md: 0 } }}
                    aria-label="mailbox folders"
                >
                    
                    <Drawer
                        
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        sx={{
                            
                            display: { xs: 'block', md: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawWidth , backgroundColor:"#00a594"},
                        }}
                        >
                        {drawer}
                    </Drawer>


                    <Drawer
                        variant="permanent"
                        sx={{
                            
                            display: { xs: 'none', md: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawWidth , backgroundColor:"#00a594" },
                        }}
                        open
                        >
                        {drawer}
                    </Drawer>



                </Box>
                
                <Box
                    
                    component="main"
                    sx={{ flexGrow: 1, width: { md: `calc(100% - ${drawWidth}px)` } , marginTop : 7 , overflowX:'hidden',background:'f4f4f4',paddingTop:5}}
                >

                    {children}

                </Box>

                <ProfileEditDialog/>

            </Box>
        </ThemeProvider>
    </CacheProvider>
     );
}
 
export default Layout;
import React , {useContext} from 'react';
import ReactECharts from 'echarts-for-react';
import { IndividualDashboardContext } from '../Context/IndividualDashboardContext';
import { Container, Grid, Paper, Typography, Table, Stack , Box } from '@mui/material';
const IndividualPedigree = () => {

    const {individualData} = useContext(IndividualDashboardContext); 

    let Sire = "Y [No Sire Found]";
    let Mgs = "Y [No Mgs Found]";
    let Mggs = "Y [No Mggs Found]";

    if(individualData.sirE_FLAG){
        Sire = individualData.sire;
      }
    
      if(individualData.mgS_FLAG){
        Mgs = individualData.mgs;
      }
    
      if(individualData.mggS_FLAG){
        Mggs = individualData.mggs;
      }

    const treeoption = {

        tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove'
          },
          series: [
            {
              type: 'tree',
              data: [{
                name:'Tag: '+individualData.tag,
                collapsed: false,
                children:[
                    
                    { 
                      name: 'Sire : '+Sire,
                      collapsed:false,
                      
                      
                    },
                    
                    {
                      name: 'DAM : X',
                      collapsed:false,
                      children:[
                          {
                            name: 'MGS : '+Mgs,
                            collapsed:false,
                            
                          },
                          {
                            name:'DAM : X',
                            collapsed:false,
                            children:[
                                {
                                  name:'MGGS : '+Mggs,
                                  collapsed:false
                                },
                                {
                                  name: 'DAM : X',
                                  collapsed:false
                                }
                                
                              ]
                          }
                        
                        ]
                      
                    }
                    
                  ]
                
                
              }],
              top: '1%',
              left: '20%',
              bottom: '1%',
              right: '35%',
              symbolSize: 30,
              symbol:'circle',
              lineStyle: {
                width: 10,
                color:"#27ae60"
              },
              label: {
                position: 'left',
                verticalAlign: 'middle',
                align: 'right',
                fontSize: 18
              },
              leaves: {
                label: {
                  position: 'right',
                  verticalAlign: 'middle',
                  align: 'left'
                }
              },
              emphasis: {
                focus: 'descendant'
              },
              expandAndCollapse: true,
              animationDuration: 550,
              animationDurationUpdate: 750
            }
          ]
      }
    return(
        <>
            <Box component="div" style={{background:"#34495e",width:"100%",borderTopLeftRadius:15,borderTopRightRadius:15,paddingTop:10,paddingBottom:10,display:'flex',justifyContent:'center'}}>
                <Typography variant="h6" color="#fff">
                Pedigree   
                </Typography>
            </Box>
            <Paper style={{height:400}}>

                <Grid>
                    

                    <Grid  md={12}  style={{height:400,paddingLeft:20}}>
                        <ReactECharts option={treeoption} style={{height:'100%',width:'100%',direction:'ltr'}}/>
                    </Grid>
                    

                </Grid>

            </Paper>
        </>
    )
}

export default IndividualPedigree
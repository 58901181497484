import React, { useState, setState , createContext } from 'react';
import axios from "axios";
import AuthService from './../Services/AuthService';

export const GEHerdInfoContext = createContext();

const GEHerdInfoContextProvider = (props) => {

    //const [herdId,setHerdId] = useState();
    const [herdName,setHerdName] = useState();
    const [herdGroup,setHerdGroup] = useState();
    const [herdPopulation,setHerdPopulation] = useState();
    const [evaluationDate,setEvaluationDate] = useState("");



    const GetInfo = async (herdId) => {


        const authservice = new AuthService();
        var authuser = await authservice.AuthGetUser();
        
        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authuser.access_token
        };

        var herdpopulation = await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/GeneticsData/GeneticDataCountByHerd?HerdId=${herdId}`,{headers});
        setHerdPopulation(herdpopulation.data);

        var herdInfo = await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/GetHerdById?Id=${herdId}`,{headers});

        var EvalDate = await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/EvaluationDate`,{headers});

        setEvaluationDate(`${EvalDate.data.month} ${EvalDate.data.year}`);
        
        setHerdName(herdInfo.data.data.name);
        setHerdGroup(herdInfo.data.data.group);

    }


    return(
    <GEHerdInfoContext.Provider

            value={{
                evaluationDate,
                herdName,
                herdGroup,
                herdPopulation,
                GetInfo

            }}
        
        >
            {props.children}
        </GEHerdInfoContext.Provider>
    )
}
export default GEHerdInfoContextProvider;


import React, {useContext, useEffect, useState} from 'react';
import ReactECharts from 'echarts-for-react';
import { CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { GESireLineReportContext } from '../Context/GESireLineReportContext';
import IranSans from '../font/IranSans.ttf';
import { ThemeProvider, createTheme } from "@mui/material/styles";

import HeaderDictionary from '../Helper/HeaderDictionary';

import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#34495e",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


  const fontTheme = createTheme({
    typography: {
      fontFamily: 'IranSans',
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'IranSans';
            src: local('IranSans'), url(${IranSans}) format('truetype');
          }
        `,
      },
    },
  });





function createTableData(year,SireCount , DaughterCount, Mean ) {
    return { year, SireCount, DaughterCount, Mean };
  }

const GEReportSireLine = (props) => {

  const [chartDataTableOpen,setChartDataTableOpen] = useState('');

    const {IndexSelected,HandleChangeIndexSelect,tableData,evalDate} = useContext(GESireLineReportContext);

    var evalD = evalDate;

    const exportToExcel = () => {


      const table = document.getElementById('SireLineTable');
      const worksheet = utils.table_to_sheet(table);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = write(workbook, { type: 'array', bookType: 'xlsx' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, 'SireLineTable_in_'+IndexSelected.toUpperCase()+'.xlsx');
  };

  useEffect(() => {
      const buttonElement = document.getElementById('SireLineExport');
      if (buttonElement) {
      buttonElement.addEventListener('click', exportToExcel);
      }

  },[chartDataTableOpen]);


    const SireLineChartOptions = {
    
        tooltip: {
          trigger: 'axis',
          
        },

        title: {
          right:'right',
          text: HeaderDictionary.SecondaryHeader[IndexSelected]+' خط پدری در {a|            }',
          subtext: evalD+' ارزیابی ',
          textStyle:{
      
        
            rich: {

              a: {
                  backgroundColor: {
                      image: './logo.png'
                  },
                  height: 50
              },

              
            }
          }
        },
        toolbox: {
          left:'auto',
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none"
            },
            dataView: {
              readOnly: false,
              optionToContent: function(opt){

                setChartDataTableOpen(opt);

                var table = "<button id='SireLineExport'> Export excel </button>";

                table += '<table style="width:100%;text-align:center;" id="SireLineTable"><tbody><tr>'
                + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;"> سال تولد </th>'
                + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;"> تعداد پدر </th>'
                + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;"> تعداد دختر </th>'
                + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;"> میانگین </th>'


                + '</tr>';
                
                for (var i = 0, l = tableData.length; i < l; i++) {
                      table += '<tr style="background:#E7EAEF">'
                        + '<td>' + tableData[i].year + '</td>'
                        + '<td>' + tableData[i].SireCount + '</td>'
                        +'<td>' + tableData[i].DaughterCount + '</td>'
                        +'<td>' + tableData[i].Mean  + '</td>'
                        + '</tr>';
                    }
                    
                table += '</tbody></table>';

                return table;
              }
            },
            saveAsImage: {
              name: IndexSelected.toUpperCase()+' خط پدری در'
            }
          }
        },
        
        xAxis: {
          type: 'category',
          splitLine: { show: false },
          data: tableData.map(x=>x.year)
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '8%',
          top: '20%',
          containLabel: true
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '20%']
          
        },
        series: [
          {
            name: 'Mean',
            type: 'line',
            data: tableData.map(x=>x.Mean)
          },

          
          
        ]
      };

    


    return ( 

        <Box component="div" style={{background:"#34495e",width:"100%",borderTopLeftRadius:15,borderTopRightRadius:15}} theme={fontTheme}>
            <Grid style={{width:"100%",display:"flex",justifyContent:"center",paddingTop:10,paddingBottom:10}}>
                <Typography variant="h6" color="#fff" theme={fontTheme}>
                    گزارش خط پدری
                </Typography>
            </Grid>


            {props.SireLineData !== undefined ? 
            
            
            <Grid container>

            <Grid item md={6} >
                <Paper elevation={3} style={{height:500,paddingTop:10}}>
                    <Grid item component="div" sx={{marginLeft:3,marginRight:3}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">شاخص</InputLabel>
                            <Select
                                fullWidth
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={IndexSelected}
                                label="شاخص"
                                
                                onChange={(e) => {HandleChangeIndexSelect(e.target.value)}}
                            >
                                 <MenuItem style={{direction:'ltr'}} value={"neT_MERIT"}>LNM$</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"fluiD_MERIT"}>LFM$</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"cheesE_MERIT"}>CM$</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"gm"}>GM</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"milk"}>MILK</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"fat"}>FAT</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"protein"}>PROTEIN</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"faT_P"}>FAT %</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"proteiN_P"}>PROTEIN %</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"scs"}>SCS</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"pl"}>PL</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"dpr"}>DPR</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"sce"}>SCE</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"dce"}>DCE</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"ssb"}>SSB</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"dsb"}>DSB</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"ccr"}>CCR</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"hcr"}>HCR</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"earlyFirstCalving"}>EFC</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"cowLivAbility"}>CLIV</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"heiferLivability"}>HLIV</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"feedSaved"}>FSAV</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"bodyComposite"}>BWC</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"gestationLenght"}>GL</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"milkFever"}>MFEV</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"displacedAbomasum"}>DA</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"ketosis"}>KETO</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"mastitis"}>MAST</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"metritis"}>METR</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"retainedPlacenta"}>RETP</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"ptat"}>PTAT</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"udc"}>UDC</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"foot_Legs_Comp"}>FLC</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"stature"}>Stature</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"strength"}>Strength</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"body_Depth"}>Body Depth</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"dairy_Form"}>Dairy Form</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"rump_Angle"}>Rump Angle</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"thurl_Width"}>Thurl Width</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"rear_Legs_Side_View"}>RSLV</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"foot_Angle"}>Foot Angle</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"fore_Uder_Attachment"}>Fore Udder Attachment</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"rear_Udder_Hight"}>Rear Udder Hight</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"rear_Udder_Width"}>Rear Udder Width</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"uder_Cleft"}>Udder Cleft</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"udder_Depth"}>Udder Depth</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"teat_Lenghth"}>Teat Lenghth</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"rear_Legs_Rear_view"}>RLRV</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"dairyComposite"}>Dairy Composite</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"footLegsCompScore"}>FLS</MenuItem>
                                <MenuItem style={{direction:'ltr'}} value={"foreTeatPlacement"}>Fore Teat Placement</MenuItem>
                                
                                
                                
                            </Select>
                        </FormControl>
                    </Grid>
                    
                    <Grid item component="div" sx={{paddingRight:1,paddingLeft:1,paddingTop:'5px',overflowY:"scroll",maxHeight:"430px"}}>
                        <Table aria-label="simple table" sx={{paddingRight:1,paddingLeft:1}}>

                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell theme={fontTheme}>سال تولد</StyledTableCell>
                                    <StyledTableCell theme={fontTheme}>تعداد پدر</StyledTableCell>
                                    <StyledTableCell theme={fontTheme}>تعداد دختر</StyledTableCell>
                                    <StyledTableCell theme={fontTheme}>میانگین</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>


                              {tableData.map((row,index) => (

                                  <StyledTableRow
                                  key={index}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                      
                                      <StyledTableCell component="th" scope="row">
                                          {row.year}
                                      </StyledTableCell>

                                      <StyledTableCell component="th" scope="row">
                                      {row.SireCount}
                                      </StyledTableCell>

                                      <StyledTableCell component="th" scope="row">
                                      {row.DaughterCount}
                                      </StyledTableCell>

                                      <StyledTableCell component="th" scope="row" style={{direction:"ltr"}}>
                                      {row.Mean}
                                      </StyledTableCell>

                                  </StyledTableRow>
                                  

                              ))}

                                

                                

                                
                            </TableBody>

                        </Table>
                    </Grid>

                    
                    

                

                </Paper>

            </Grid>

            <Grid item md={6} style={{direction:"ltr"}}>
                <Paper elevation={3} style={{height:500,paddingTop:5}}>
                <ReactECharts option={SireLineChartOptions} style={{height:'100%'}}/>
                </Paper>
            </Grid>

        </Grid>
            
            
            : 
            
            
            <Box textAlign='center'>
                <CircularProgress disableShrink />
            </Box>
            
            
            }


            
            
        
        </Box>

     );
}
 
export default GEReportSireLine;

import React, { useState, setState , createContext , useEffect, useContext} from 'react';
import axios from "axios";
import AuthService from '../Services/AuthService'


export const GESireLineReportContext = createContext();

const GESireLineReportContextProvider = (props) => {
    const authservice = new AuthService();

    const [evalDate,setEvalDate] = useState('');

    function createTableData(year,SireCount , DaughterCount, Mean ) {
        return { year, SireCount, DaughterCount, Mean };
      }

    const [SireLineData, setSireLineData] = useState();
    const [IndexSelected,setIndexSelected] = useState("neT_MERIT");
    const [SireLineHerdId,setSireLineHerdId] = useState();
    const [tableData,setTableData] = useState([]);




    const HandleChangeIndexSelect = (Index) => {
        setIndexSelected(Index);

        if(SireLineData !== undefined){
            console.log(Index);
        }

    }


    useEffect(() => {

        if(SireLineData !== undefined){

            let tableArr = [];


            SireLineData.map((row,index) => {

                
                tableArr.push(createTableData(row.year,row.sireCount,row.daughterCount,row[IndexSelected].toFixed(1)));
            });
            setTableData(tableArr);

        }

        

    },[IndexSelected,SireLineHerdId,SireLineData]);


    const GetSireLineData = async () =>{

        if(SireLineHerdId !== undefined){

            var authuser = await authservice.AuthGetUser();

            const headers = {
                Accept: 'application/json',
                Authorization: 'Bearer ' + authuser.access_token
            };

            var EvalDate = await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/EvaluationDate`,{headers});

            setEvalDate(`${EvalDate.data.month} ${EvalDate.data.year}`);



            await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/ImportSireLine?HerdId=${SireLineHerdId}`,{headers}).then(res => {
                
                setSireLineData(res.data.data);
            });

        }
    }



    useEffect(() => {

        GetSireLineData();

    },[SireLineHerdId]);



    return ( 

        <GESireLineReportContext.Provider

            value={{
                SireLineData,
                IndexSelected,
                setIndexSelected,
                setSireLineHerdId,
                HandleChangeIndexSelect,
                tableData,
                evalDate
            }}
        
        >
            {props.children}
        </GESireLineReportContext.Provider>


     );

}
export default GESireLineReportContextProvider;
import { Container , Grid, Stack , Button , Box, Paper,Typography} from '@mui/material';
import React from 'react';
import SendIcon from '@mui/icons-material/Send';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Icon } from '@iconify/react';
import { AuthContext } from './../Context/AuthContext';
import { Link } from "react-router-dom";
import { useContext } from 'react';

import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { GEHerdInfoContext } from './../Context/GEHerdInfoContext';

import IranSans from '../font/IranSans.ttf';
import { ThemeProvider, createTheme } from "@mui/material/styles";

const fontTheme = createTheme({
    typography: {
      fontFamily: 'IranSans',
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'IranSans';
            src: local('IranSans'), url(${IranSans}) format('truetype');
          }
        `,
      },
    },
  });


const GE_HerdView = ({children}) => {

    const {userInfo,handelPageAccess} = useContext(AuthContext);
    // var access = handelPageAccess("Herd Analytics");

    const {
        evaluationDate,
        herdName,
        herdGroup,
        herdPopulation,
        GetInfo
    } = useContext(GEHerdInfoContext);

    // if(!access){
    //     return (<> Access Denied...! </>)
    // }



    GetInfo(userInfo.Herd);


    return ( 
        <>
            <Container>

                <Grid container>
                    
                    <Grid sm={12} md={12} lg={12} style={{display:"flex",justifyContent:"space-evenly",marginBottom:30}}>

                        <Paper elevation={3} sx={{marginBottom:'5px',height:100,width:250}}>

                            <Grid container style={{height:"100%"}}>
                                <Grid md={4} lg={4}  style={{background:"#3498db",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                    <MapsHomeWorkIcon style={{width:50,height:50,color:"#004c44"}}/>
                                </Grid>

                                <Grid md={8} lg={8} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                    
                                    <Typography sx={{fontSize:'16px' , fontWeight:'bold'}} variant='h1' theme={fontTheme}>
                                    {herdName}
                                    </Typography>
                                </Grid>

                            </Grid>
                            

                        </Paper>

                        <Paper elevation={3} sx={{marginBottom:'5px',height:100,width:250}}>

                            <Grid container style={{height:"100%"}}>
                                <Grid md={4} lg={4}  style={{background:"#2ecc71",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                    <Icon icon="healthicons:animal-cow" color="#004c44" width="50" height="50" />
                                </Grid>

                                <Grid md={8} lg={8} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                    <Typography sx={{fontSize:'16px' , fontWeight:'bold'}} variant='h1'>
                                        {herdPopulation}
                                    </Typography>
                                </Grid>

                            </Grid>
                            

                        </Paper>


                        <Paper elevation={3} sx={{marginBottom:'5px',height:100,width:250}}>

                            <Grid container style={{height:"100%"}}>
                                <Grid md={4} lg={4}  style={{background:"#9b59b6",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                    <Icon icon="ion:calendar-sharp" color="#004c44" width="45" height="45" />
                                </Grid>

                                <Grid md={8} lg={8} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                    <Typography sx={{fontSize:'16px' , fontWeight:'bold'}} variant='h1'>
                                    {evaluationDate}
                                    </Typography>
                                </Grid>

                            </Grid>
                            

                        </Paper>


                    </Grid>

                </Grid>

                

                    {children}

                


            </Container>
        
        </>
     );
}
 
export default GE_HerdView;
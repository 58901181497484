import React, { useContext } from 'react';
import Paper from '@mui/material/Paper';
import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material';
import IndividualCattleTable from '../Components/IndividualCattleTable';
import { HerdIndividualListContext } from '../Context/HerdIndividualListContext';
import { AuthContext } from '../Context/AuthContext';
import IndividualCattleFilter from '../Components/IndividualCattleFilter';





const Herd = () => {

    let search = window.location.search;
    let params = new URLSearchParams(search);

    let percentileIndex = params.get('indicator');
    let percentileMinValue = params.get('min');
    let percentileMaxValue = params.get('max');

    const {userInfo} = useContext(AuthContext);

    const {
        setHerdId,
        totalRecords,
        rowPerPage,
        currentPage,
        tableData,
        setPercentileIndex,
        setPercentileMin,
        setPercentileMax} = new useContext(HerdIndividualListContext);

    setHerdId(userInfo.Herd);
    setPercentileIndex(percentileIndex);
    setPercentileMin(percentileMinValue);
    setPercentileMax(percentileMaxValue);



    return ( 

        <>
        
            <Container>
                <Grid container spacing={2}>

                    <Grid item sm={12} md={12} lg={12}>


                        <IndividualCattleFilter/>

                    </Grid>

                    <Grid item sm={12} md={12} lg={12}>
                        <Paper elevation={3} >
                            
                            {tableData !== undefined ? <IndividualCattleTable totalRecords={totalRecords} rowPerPage = {rowPerPage} currentPage={currentPage} dataTable={tableData}/> 
                            : 
                            <Box textAlign='center'>
                                <CircularProgress disableShrink />
                            </Box>}

                            

                        </Paper>
                    </Grid>



                </Grid>
            </Container>
            

        </>
     );
}
 
export default Herd;
import React, { useState , createContext , useEffect} from 'react';
import axios from "axios";
import AuthService from '../../Services/AuthService'

export const DairySpermCatalogContext = createContext()

const DairySpermCatalogContextProvider = (props) => {

    const authservice = new AuthService();

    const [dairySpermList,setDairySpermList] = useState([]);


    const GetSpermList = async () => {


        var authuser = await authservice.AuthGetUser();

        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authuser.access_token
          };

        await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/SpermCatalog/Dairy/List`,{headers}).then(result => {
            
            setDairySpermList(result.data);

        })


        
    } 


    useEffect(async () => {

        GetSpermList();


    },[]);


    //--------------search values
    const [minFilter,setMinFilter] = useState();


    const HandleMinValueFilter = (e) =>{
        setMinFilter(e.target.value)
    }

    const [maxFilter,setMaxFilter] = useState();

    const HandleMaxValueFilter = (e) =>{
        setMaxFilter(e.target.value)
    }


    const [indexFilter,setIndexFilter] = useState();
    const HandleIndexFilter = (e) =>{
        setIndexFilter(e.target.value)
    }
    //-------range filters---------
    const rangeFilterInitState = {Filters : [],Category : 'ma'}
    const [rangeIndexList,setRangeIndexList] = useState(rangeFilterInitState);
    
    const AddRangeFilterToFilters =() =>{
        var rangeObj = {
            Index : indexFilter,
        }

        if (minFilter != '') {
            rangeObj.MinValue = parseFloat(minFilter)
        }

        if(maxFilter != '') {
            rangeObj.MaxValue = parseFloat(maxFilter)
        }

        //validate if values are null
        if ((typeof maxFilter == 'undefined' && typeof minFilter == 'undefined')
            || (minFilter == '' && maxFilter == '') 
            || typeof indexFilter == 'undefined') {

            alert('مقداری یافت نشد')
            return;
        }

        if(rangeIndexList.Filters.length !== 0)
        {
            if(rangeIndexList.Filters.some(x => x.Index == indexFilter))
            {
                alert(`${indexFilter} وجود دارد ! `)
            }
            else{
                setRangeIndexList({
                    Filters : [...rangeIndexList.Filters,rangeObj],
                    Category : rangeIndexList.Category
                })
            }
        }
        else {
            setRangeIndexList({Filters :[rangeObj],Category : 'ma'})
        }

    }

    const RemoveFromRangeIndexList = (index) => {
        var ExistingRangeIndexes = rangeIndexList.Filters.filter(item =>
            !index || !item.Index.toString()
            .toLowerCase()
            .includes(index.toString().toLowerCase())
        )

        if (ExistingRangeIndexes.length != 0) {
            setRangeIndexList({ ...rangeIndexList , Filters : ExistingRangeIndexes})
        }
        else{
            setRangeIndexList({ ...rangeIndexList , Filters : []})
        }
    }

    const spermFiltersInitState = {
        RegNo : '',
        NAAB_CODE : '',
        Name : '',
        Sire : '',
        MGS : '',
        Breed : '',
        Gender : '',
        QuantityStatus : '',
        IsDescending : false,
        OrderBy : '',
        Range : ''
    }
    const [spermFilters,setSpermFilters] = useState(spermFiltersInitState)

    const SetStringFilters = (name,value) => {
        setSpermFilters({
            ...spermFilters,
            [name]: value
          })
    }

    //-------ordering---------
    const SetOrderingValue = (orderBy,isDescending) => {
        console.log(orderBy,isDescending)
        spermFilters.OrderBy = orderBy,
        spermFilters.IsDescending = isDescending
    }
    //-------filter-----------
    const FilterSperms = async () => {
        var authuser = await authservice.AuthGetUser();
        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authuser.access_token
          };

          

        //const spermFilters = spermFilters
        
        spermFilters.Range = JSON.stringify(rangeIndexList)
        
        let queryString = new URLSearchParams(spermFilters).toString();

        await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/SpermCatalog/Dairy/List?${queryString}`,{headers}).then(result => {
            
            
                console.log(result)
                setDairySpermList(result.data);
                console.log(result.data);

        }).catch(e =>{
            alert('داده ای با این مشخصات یافت نشد')
        })

    }

    const ResetAllValues = () =>
    {
        //Send request
        GetSpermList()
        //Reset States values
        setSpermFilters(spermFiltersInitState)
        setRangeIndexList(rangeFilterInitState)
        setListOrdering({})


    }

    return(

        <DairySpermCatalogContext.Provider
        
            value={{
                dairySpermList,
                HandleMaxValueFilter,
                HandleMinValueFilter,
                HandleIndexFilter,
                AddRangeFilterToFilters,
                FilterSperms,
                rangeIndexList,
                RemoveFromRangeIndexList,
                SetStringFilters,
                ResetAllValues,
                spermFilters,
                SetOrderingValue
            }}
        
        >
            {props.children}
        </DairySpermCatalogContext.Provider>

    );

}

export default DairySpermCatalogContextProvider;
import React, { useState , createContext, useEffect } from 'react';
import axios from "axios";
import AuthService from './../Services/AuthService';

export const GEHerdDistributionContext = createContext();

const GEHerdDistributionContextProvider = (props) => {

    const [normDistribution,setNormDistribution] = useState([]);
    const [HerdIdDistributionDash,setHerdIdDistributionDash] = useState();

    const authservice = new AuthService();

    const [evalDate,setEvalDate] = useState('');

    React.useEffect( async () => {


        var authuser = await authservice.AuthGetUser();

        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authuser.access_token
          };

          var EvalDate = await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/EvaluationDate`,{headers});

          setEvalDate(`${EvalDate.data.month} ${EvalDate.data.year}`);
        

            if(typeof HerdIdDistributionDash !== 'undefined' && HerdIdDistributionDash != null){
                await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/HerdReport/NormalDistributionByIndexList?Id=${HerdIdDistributionDash}&Year=ALL&Lactation=ALL&Index=NET_MERIT&Index=FLUID_MERIT`,{headers}).then(res => {

                setNormDistribution(res.data.data);
            
            });
        }
        

        

    },[HerdIdDistributionDash]);

    return ( 

        <GEHerdDistributionContext.Provider

            value={{
                normDistribution,
                HerdIdDistributionDash,
                setHerdIdDistributionDash,
                evalDate
                

            }}
        
        >
            {props.children}
        </GEHerdDistributionContext.Provider>

     );


}

export default GEHerdDistributionContextProvider;

import React , {useContext} from "react"

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Grid, TextField, 
    FormControl, InputLabel,Select, MenuItem, IconButton, Chip,
    Box , Stack , Button, 
    Divider} from '@mui/material'

const SpermsRangeFilter = ({selectionMenu , context}) => {

    const {
        HandleMaxValueFilter,
        HandleMinValueFilter,
        HandleIndexFilter,
        AddRangeFilterToFilters,
        rangeIndexList,
        RemoveFromRangeIndexList,
      } = context


    return (
        <Stack sx={{maxWidth : '90%' , padding : '20px'}} spacing={2}>
            <Divider style={{color : '#00000066' }}/>
            <Stack mb={3} >
                <FormControl> 
                    <InputLabel id="selection-label">
                    شاخص / صفت
                    </InputLabel>
                    <Select
                    autoWidth
                    labelId="selection-label"
                    id="propertySelection"
                    onChange={e => HandleIndexFilter(e)}
                    >
                    {selectionMenu.map(item =>(
                        <MenuItem key={item} value={item}>
                        {item}
                        </MenuItem>
                    ))}

                    </Select>
                </FormControl>

                {/* Add min and max value number */}
            </Stack>
            <Stack direction="row" spacing={2}>
                <TextField  type='number' id="indexmin" label="Min" 
                    variant="outlined" onChange={e => HandleMinValueFilter(e)}/>

                <TextField  type='number' id="indexmax" label="Max" 
                    variant="outlined" onChange={e => HandleMaxValueFilter(e)}/>
                <IconButton onClick={e => AddRangeFilterToFilters()}>
                    <AddCircleIcon/>
                </IconButton>
            </Stack>
            <Grid style={{maxWidth : '600px'}}  spacing={2}>
            
                {rangeIndexList.Filters.map(item => (
                    <Chip key={item.Index} label={`
                        index : ${item.Index}    
                        min : ${item.MinValue ? item.MinValue : '-' }
                        max : ${item.MaxValue ? item.MaxValue : '-' } 
                        `} 
                        style={{margin : '10px'}}
                        onDelete={() => {RemoveFromRangeIndexList(item.Index)}}/>
                    ))}
            </Grid>
        </Stack>
    )
}

export default SpermsRangeFilter
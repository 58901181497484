import React from 'react'
import { UserManager } from 'oidc-react';
import { WebStorageStateStore } from 'oidc-client-ts';
import axios from "axios";


const Logout = () => {

    localStorage.clear();
    window.location.href = `https://auth.mobarakandish.com/auth/logout`;

    return ( 
        <>
        
        </>
     );
}
 
export default Logout;

import React, {useContext, useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Layout from "../components/Layout";

import SigninCallBack from '../Pages/SigninCallBack';
import Logout from '../Pages/Logout';



const GenaralRoutes = () => {



    return ( 
        
        <>
            <Router>

                <Routes>

                    <Route exact path="/signincallback" element={<SigninCallBack />} />
                    
                    
                    <Route exact path="/logout" element={<Logout />} />


                    
                </Routes>
            </Router>




        </>

                
            
      
     );
    


 
}
 
export default GenaralRoutes;
import React, { useState , useContext, useEffect} from 'react'
import { Grid, Container, Paper, Box, Stack ,FormControl,MenuItem,InputLabel,Select, Typography} from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { GEYearLactContext } from './../Context/GEYearLactContext';

import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import HeaderDictionary from '../Helper/HeaderDictionary';




const YearLactationComparsion = (props) => {

  const {yearLacData,yearLacIndexSelect,yearLacIndexList,yearLacYearsList,yearLacYearSelect,handleChangeYearLacYears,handleChangeYearLacIndex,evalDate} = useContext(GEYearLactContext);

    let datavalue = yearLacData.lacGroupValue;
    


    const [chartDataTableOpen,setChartDataTableOpen] = useState('');

    const exportToExcel = () => {
  
  
        const table = document.getElementById('yearlacTable');
        const worksheet = utils.table_to_sheet(table);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = write(workbook, { type: 'array', bookType: 'xlsx' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(data, 'Herd_'+yearLacYearSelect+'_Years_Lactations_in_'+yearLacIndexSelect+'.xlsx');
    };
  
    useEffect(() => {
      const buttonElement = document.getElementById('YearLactExport');
      if (buttonElement) {
        buttonElement.addEventListener('click', exportToExcel);
      }
  
    },[chartDataTableOpen]);

    if(typeof datavalue !== 'undefined'){
      datavalue = yearLacData.lacGroupValue.map(x=> x.toFixed(2))
    }

    var Index = yearLacIndexSelect;
    var Year = yearLacYearSelect;

    if(yearLacYearSelect == "ALL"){
      Year = "";
    }

    var evalD = evalDate;

    let option = {
      title: {
        right:'right',
        text: HeaderDictionary.Headers[Index]+' '+Year+' {a|            }',
        subtext: evalD+' ارزیابی ',
        textStyle:{
    
      
          rich: {

            a: {
                backgroundColor: {
                    image: './logo.png'
                },
                height: 50
            },

            
          }
        }
      },
      toolbox: {
        left:'auto',
        show: true,
        feature: {
          dataZoom: {
            yAxisIndex: "none"
          },
          dataView: {
            readOnly: false,
            optionToContent: function(opt){
        
              var axisData = opt.xAxis[0].data;
              var axis2data = opt.xAxis[1].data;
              var series = opt.series;


              setChartDataTableOpen(opt.series);

              var table = "<button id='YearLactExport'> Export excel </button>";

              table += '<table style="width:100%;text-align:center;" id="yearlacTable"><tbody><tr>'
                     + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;">شکم</th>'
                     + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;">جمعیت</th>'
                     + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;">' + HeaderDictionary.Headers[Index] + '</th>'
                     + '</tr>';
              for (var i = 0, l = axisData.length; i < l; i++) {
                    table += '<tr style="background:#E7EAEF">'
                     + '<td>' + axisData[i] + '</td>'
                     + '<td>' + axis2data[i] + '</td>'
                     + '<td>' + series[0].data[i] + '</td>'
                     + '</tr>';
                  }
                  
              table += '</tbody></table>';
              return table;
            }
          },
          magicType: {
            type: ["line", "bar"]
          },
          saveAsImage: {
            name: Index+' '+Year
          }
        }
      },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '8%',
          top: '18%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: yearLacData.lacGroups,
            axisTick: {
              alignWithLabel: false
            }
          },
          {
            type: 'category',
            data: yearLacData.lacGroupPopulation,
            position : 'top',
            splitLine : false,
            axisTick: false
            
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitArea: {
                show: true
            }
          }
        ],
        series: [
            
            {
                
                type: 'bar',
                barWidth: '50%',
                itemStyle: {
                    normal: {
                        color: "#27ae60",
                        barBorderRadius: 11,
                    }
    
                },
                label: {
                    normal: {
                        show: true,
                        position: 'inside',
                        
                        color: '#fff'
                    }
                },
                data: datavalue
              },

        ]
      };


    return ( 


      <Grid container sx={{marginTop:5}} style={{direction:"ltr"}}>
        
        <Box component="div" style={{background:"#34495e",width:"100%",borderTopLeftRadius:15,borderTopRightRadius:15}}>
          <Grid item sm={12} md={12} style={{width:"100%",display:"flex",justifyContent:"center",paddingTop:10,paddingBottom:10}}>
            <Typography variant="h6" color="#fff">
                میانگین گروه های تولیدی در هر گروه سنی
            </Typography>
          </Grid>
          <Grid item sm={12} md={12} style={{width:"100%",display:"flex",justifyContent:"space-between",background:"#fff",paddingTop:15}}>
            <FormControl style={{borderColor:"#fff",padding:5}} fullWidth >
                <InputLabel id="demo-simple-select-label">شاخص</InputLabel>
                <Select
                    
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={yearLacIndexSelect}
                  label="شاخص"
                  
                  onChange={handleChangeYearLacIndex}
                >
                    
                    
                  {
                      yearLacIndexList.map((val) => {

                        return(
                          
                          <MenuItem style={{direction:'ltr'}} key={val} value={val}>{HeaderDictionary.Headers[val]}</MenuItem>

                        );

                      })
                    
                  }
                    
                    
                </Select>
            </FormControl>
            

            <FormControl style={{borderColor:"#fff",padding:5}} fullWidth >
                <InputLabel id="demo-simple-select-label">سال تولد</InputLabel>
                <Select
                    
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={yearLacYearSelect}
                    label="سال تولد"
                    
                    onChange={handleChangeYearLacYears}
                >
                    
                    {
                      yearLacYearsList.map((val) => {

                        return(
                          
                          <MenuItem key={val} value={val}>{val}</MenuItem>

                        );

                      })
                    }
                </Select>
            </FormControl>

            

          </Grid>
        </Box>
        <Grid item md={12}>
            <Paper elevation={3} style={{height:600}}>

              <ReactECharts option={option} style={{height:'100%'}}/>
            </Paper>

        </Grid>


      </Grid>


     );
}
 
export default YearLactationComparsion;
import React, { useContext,useState } from "react";
import { Container, 
  Grid ,
  Button,
  Box , CircularProgress,Stack , Paper } from "@mui/material";

import { AuthContext } from '../Context/AuthContext';
import BeefSpermCatalogTable from "../Components/SpermCatalogs/Beef/BeefSpermCatalogTable";

import { BeefSpermCatalogContext } from "../Context/SpermCatalogs/BeefSpermCatalogContext"; 
import BeefFilter from "../Components/SpermCatalogs/Beef/BeefFilter";
import { SearchOffOutlined , ExpandMore, Search } from "@mui/icons-material";

const BeefSpermList = () => {

  const beefSpermTableHeaders = ['Reg.No','Breed','Name','SCE','CR',
    'DM','PCAR','RDT','CONF','COUL','GRAS','IAB','ICRC','SIRE','MGS','قیمت']

  const {handelPageAccess} = useContext(AuthContext);
  // var access = handelPageAccess("Sperm Catalog");

  // if(!access){
  //     return (<> Access Denied...! </>)
  // }

  const {
    beefSpermList,
  } = useContext(BeefSpermCatalogContext);

  const [drawerStatus,setdrawerStatus] = useState(false)

  const buttonsStyle = 
    {height:'50px',width:'100%',borderRadius:5,color:'#fff',display:'flex'}


  const toggleDrawer = () => {
    if(drawerStatus){
        setdrawerStatus(false)
    }else {
        setdrawerStatus(true)
    }
  }
  return (

      <Container maxWidth="high">
        <Grid>
          <Grid spacing={2} sx={{display : 'flex', width : '100%' , justifyContent:'space-between'}} mb={2}>
            <Grid sx={{justifyContent : 'center'}} Item xs={12} style={buttonsStyle}>
                <Button style={{height:'50px',width:'100%',fontSize:'16px',background:'#3498db'}} variant="contained" startIcon={<Search fontSize="large"/>} onClick={toggleDrawer}>
                  فیلتر 
                </Button>
            </Grid>
          </Grid>
          
          <Grid align="center" item md={12} lg={12} mb={5}>

            <BeefFilter 
              drawerStatus={drawerStatus}
              toggleDrawer={toggleDrawer}
            />
          </Grid>

          <Grid item md={12} lg={12} mb={5} >
              <Paper elevation={3} >
                {beefSpermList.length == 0 ?
                  <Box textAlign='center'>
                    <CircularProgress disableShrink  /> 
                  </Box>
                  :
                  <BeefSpermCatalogTable spermList={beefSpermList} SpermTableHeaders={beefSpermTableHeaders}/>
                }
              </Paper>
          </Grid>

          

        </Grid>
      </Container>

  );
};

export default BeefSpermList;

import React, { useState, setState , createContext } from 'react';
import axios from "axios";
import { useEffect } from 'react';
import AuthService from '../Services/AuthService';

export const HerdIndividualListContext = createContext();

const HerdIndividualListContextProvider = (props) => {

    
    const authservice = new AuthService();


    const [rangeIndexList,setRangeIndexList] = useState([]);

    const AppendToRangeIndexList = (item) => {

        var isExist = rangeIndexList.some(x => x.Index == item.Index);

        if(!isExist){
            setRangeIndexList([...rangeIndexList,item]);
        }
        else{
            console.log("exist range for"+  item.Index);
        }

        
    }

    const RemoveFromRangeIndexList = (index) => {
        var isExist = rangeIndexList.some(x => x.Index == index);

        if(isExist){
            setRangeIndexList(rangeIndexList.filter(x=> x.Index !== index));
        }

    }

    const [percentileIndex,setPercentileIndex] = useState();
    const [percentileMin,setPercentileMin] = useState();
    const [percentileMax,setPercentileMax] = useState();

    const [herdId,setHerdId] = useState();
    const [tableData,setTableData] = useState();
    const [totalRecords,setTotalRecords] = useState(0);
    const [rowPerPage,setRowPerPage] = useState(50);
    const [currentPage,setCurrentPage] = useState(1);

    const [filterTag,setFilterTag] = useState('');
    const [filterSire,setFilterSire] = useState('');
    const [filterMgs,setFilterMgs] = useState('');
    const [filterMggs,setFilterMggs] = useState('');
    const [filterLac,setFilterLac] = useState();
    const [filterBirthYear,setFilterBirthYear] = useState();

    const HandleChangePage = (page) => {
        setCurrentPage(page);
    }


    const HandleChangeRowPerPage = (NewRowPerPage) => {

        setRowPerPage(NewRowPerPage);
    }


    const getData = async () => {

        var authuser = await authservice.AuthGetUser();

        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authuser.access_token
          };

          var rangeFilter = JSON.stringify(rangeIndexList);

          if(percentileIndex !== null && percentileMin !== null && percentileMax !== null && rangeIndexList.length == 0){
              
              AppendToRangeIndexList({
                  Index : percentileIndex,
                  MinValue: parseFloat(percentileMin),
                  MaxValue: parseFloat(percentileMax)
              });
  
              
              rangeFilter = JSON.stringify([{Index : percentileIndex,
                  MinValue: parseFloat(percentileMin),
                  MaxValue: parseFloat(percentileMax)}]);
            }
            else if(percentileIndex !== null && percentileMin !== null && rangeIndexList.length == 0){
                AppendToRangeIndexList({
                    Index : percentileIndex,
                    MinValue: parseFloat(percentileMin),
                    MaxValue: null,
                });
            
    
                
                rangeFilter = JSON.stringify([{Index : percentileIndex,
                    MinValue: parseFloat(percentileMin),
                    MaxValue: null}]);
                
            }
            else if(percentileIndex !== null && percentileMax !== null && rangeIndexList.length == 0){
                AppendToRangeIndexList({
                    Index : percentileIndex,
                    MinValue: null,
                    MaxValue: parseFloat(percentileMax),
                });
    
                
                rangeFilter = JSON.stringify([{Index : percentileIndex,
                    MinValue: null,
                    MaxValue: parseFloat(percentileMax)}]);
            }




          await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/GeneticsData/HerdList`,
          {
  
              params : {
                  HerdId : herdId,
                  Tag : filterTag,
                  Sire : filterSire,
                  Mgs : filterMgs,
                  Mggs : filterMggs,
                  Lac : filterLac,
                  BirthYear : filterBirthYear,
                  IndexRange: rangeFilter,
                  pageSize : rowPerPage,
                  currentPage : currentPage,
                  OrderBy : 'Tag',
                  OrderAcs : 'acs'
                  
                  
              }
              ,
              headers : headers
          },
          
          
          ).then(res => {
              setTableData(res.data.data);
              setTotalRecords(res.data.totalRecordes);
              
          });



    }


    useEffect(() => {
        if(typeof herdId !== 'undefined' && herdId != null){
            getData();
        }
        else{
        }
        
    },[herdId,rowPerPage,currentPage,percentileIndex]);


    const ApplyFilter = () => {
        getData();
    }

    return (  

        <HerdIndividualListContext.Provider

            value={{
                tableData,
                totalRecords,
                rowPerPage,
                currentPage,
                setHerdId,
                tableData,
                HandleChangePage,
                HandleChangeRowPerPage,
                filterTag,
                setFilterTag,
                filterSire,
                setFilterSire,
                filterMgs,
                setFilterMgs,
                filterMggs,
                setFilterMggs,
                filterLac,
                setFilterLac,
                filterBirthYear,
                setFilterBirthYear,
                ApplyFilter,
                rangeIndexList,
                AppendToRangeIndexList,
                RemoveFromRangeIndexList,
                percentileIndex,
                setPercentileIndex,
                percentileMin,
                setPercentileMin,
                percentileMax,
                setPercentileMax

            }}
        
        >
            {props.children}
        </HerdIndividualListContext.Provider>


    );
}
 
export default HerdIndividualListContextProvider;
import { Container, Grid, Paper, Typography, Table, Stack } from '@mui/material';
import { Box } from '@mui/system';
import React ,{useContext} from 'react';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import IndividualTipLine from './IndividualTipLine';
import { IndividualDashboardContext } from '../Context/IndividualDashboardContext';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#34495e",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      width:"50%"
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));



const IndividualTipInfo = () => {

  const {individualData} = useContext(IndividualDashboardContext); 



    return ( 

        <>
          <Grid>

          
            <Box component="div" style={{background:"#34495e",width:"100%",borderTopLeftRadius:15,borderTopRightRadius:15,paddingTop:10,paddingBottom:10,display:'flex',justifyContent:'center'}}>
                <Typography variant="h6" color="#fff">
                  Conformation
                </Typography>
            </Box>
            <Paper>
                <Table aria-label="simple table" sx={{paddingRight:1,paddingLeft:1}}>
                  <TableBody>
                      <StyledTableRow
                      key='1'
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      > 
                          <StyledTableCell style={{textAlign:"left"}}>PTAT</StyledTableCell>
                          <StyledTableCell style={{textAlign:"center"}}>{individualData.ptat.toFixed(1)}</StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow
                      key='1'
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      > 
                          <StyledTableCell style={{textAlign:"left"}}>UDC</StyledTableCell>
                          <StyledTableCell style={{textAlign:"center"}}>{individualData.udc.toFixed(1)}</StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow
                      key='1'
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      > 
                          <StyledTableCell style={{textAlign:"left"}}>FLC</StyledTableCell>
                          <StyledTableCell style={{textAlign:"center"}}>{individualData.foot_Legs_Comp.toFixed(1)}</StyledTableCell>
                      </StyledTableRow>


                      <StyledTableRow
                      key='1'
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      > 
                          <StyledTableCell style={{textAlign:"left"}}>GM</StyledTableCell>
                          <StyledTableCell style={{textAlign:"center"}}>{individualData.gm.toFixed(1)}</StyledTableCell>
                      </StyledTableRow>
                  </TableBody>
              </Table>
            </Paper>
          </Grid>

            <Grid md={12} style={{paddingTop:20,direction:'ltr'}}>            
                <Box component="div" style={{background:"#34495e",width:"100%",borderTopLeftRadius:15,borderTopRightRadius:15,paddingTop:10,paddingBottom:10,display:'flex',justifyContent:'center'}}>
                  <Typography variant="h6" color="#fff">
                    Linear Traits
                  </Typography>
                </Box>

                <Paper component="div" sx={{width:'100%',height:1000,direction:'ltr'}}>
                  <IndividualTipLine/>
                </Paper>
            </Grid>
            
        </>

     );
}
 
export default IndividualTipInfo;
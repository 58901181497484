import { Container , Box , Paper} from '@mui/material';
import React, {useContext} from 'react';
import ReactECharts from 'echarts-for-react';
//import {   } from '@mui/system';
import { IndividualDashboardContext } from '../Context/IndividualDashboardContext';



const labelRight = {
    position: 'right'
  };


const IndividualTipLine = () => {

  const {individualData} = useContext(IndividualDashboardContext); 

  const option = {
    
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      top: 80,
      bottom: 30
    },
    xAxis: {
      type: 'value',
      position: 'top',
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color:'#000'
        }
      },
      min:-3,
      max:3
    },
    yAxis: {
      type: 'category',
      axisLine: { show: true },
      axisLabel: { show: false },
      axisTick: { show: true },
      splitLine: { show: true },
      data: [
        'F. Teat Place',
        'Teat Length',
        'Udder Depth',
        'Udder Cleft',
        'R. Udder Wid.',
        'R. Udder Ht.',
        'F. Udder Att.',
        'Foot Angle',
        'R. Legs-R View',
        'R. Legs-S View',
        'Thurl Width',
        'Rump Angle',
        'Dairy form',
        'Body Depth',
        'Strength',
        'Stature'
      ],
      splitArea: {
        show: true
        }
      
    },
    series: [
      {
        
        type: 'bar',
        stack: 'Total',
        label: {
          show: true,
          formatter: '{b}',
          color:"#000",
          fontWeight:'bold'
        },
        itemStyle: {
            normal: {

                color: "#3498db",
                barBorderRadius: 8,
            },
        },
        data: [
          individualData.foreTeatPlacement.toFixed(1),
          individualData.teat_Lenghth.toFixed(1),
          individualData.udder_Depth.toFixed(1),
          individualData.uder_Cleft.toFixed(1),
          individualData.rear_Udder_Width.toFixed(1),
          individualData.rear_Udder_Hight.toFixed(1),
          individualData.fore_Uder_Attachment.toFixed(1),
          individualData.foot_Angle.toFixed(1),
          individualData.rear_Legs_Rear_view.toFixed(1),
          individualData.rear_Legs_Side_View.toFixed(1),
          individualData.thurl_Width.toFixed(1),
          individualData.rump_Angle.toFixed(1),
          individualData.dairy_Form.toFixed(1),
          individualData.body_Depth.toFixed(1),
          individualData.strength.toFixed(1),
          individualData.stature.toFixed(1)
          
          
        ]
      }
    ]
  };
    return ( 
        <>
          <Paper component="div" sx={{width:'100%',height:1100,direction:'ltr'}}>
            <ReactECharts option={option} style={{height:'100%',width:'100%',direction:'ltr'}}/>
          </Paper>
        </>
        

     );
}
 
export default IndividualTipLine;
import { Button, CircularProgress, Grid, Paper, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, {useState} from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell , { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';

import DescriptionIcon from '@mui/icons-material/Description';
import { useContext } from 'react';
import { GEReportContext } from '../Context/GEReportContext';
import GE_ViewReportPaperDialog from './GE_ViewReportPaperDialog';
import IranSans from '../font/IranSans.ttf';
import { ThemeProvider, createTheme } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#555555',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


  const fontTheme = createTheme({
    typography: {
      fontFamily: 'IranSans',
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'IranSans';
            src: local('IranSans'), url(${IranSans}) format('truetype');
          }
        `,
      },
    },
  });


const GeReportPaperList = (props) => {

    const {handleOpenReportEditorDialog,
        reportPaperList,
        handleOpenViewReportDialog,
        handleOpenReportPaperDeleteDialog} = useContext(GEReportContext);

    
      console.log(reportPaperList)
    return ( 

        <>
        
            <Box component="div" style={{background:"#34495e",width:"100%",borderTopLeftRadius:15,borderTopRightRadius:15}}>
                <Grid style={{width:"100%",display:"flex",justifyContent:"center",paddingTop:10,paddingBottom:10}}>
                    <Typography variant="h6" color="#fff" theme={fontTheme}>
                        لیست گزارشات کتبی
                    </Typography>
                </Grid>
                <Grid spacing={2} md={12} style={{width:"100%",display:"flex",justifyContent:"space-between",background:"#34495e",marginBottom:30}}>
                
                {reportPaperList !== undefined ? 
                <TableContainer sx={{width:'100%'}} component={Paper}>
                      <Table sx={{width:'100%'}} aria-label="customized table">
                          <TableHead>
                          <TableRow>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell>عنوان</StyledTableCell>
                                <StyledTableCell align="right">تاریخ انتشار</StyledTableCell>
                                <StyledTableCell align="center">
                                </StyledTableCell>
                          </TableRow>
                          </TableHead>
                          <TableBody>

                            
                            {reportPaperList.length !== 0 ?
                            reportPaperList.map((row,index) => (

                                <StyledTableRow key={index}>
                                <StyledTableCell component="th" scope="row">
                                    <DescriptionIcon sx={{color:'#57606f',width:35,height:42}}/>
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {row.title}
                                </StyledTableCell>
                                <StyledTableCell align="right">{row.publishDate.split('T')[0]}</StyledTableCell>
                                <StyledTableCell align="center" >

                                    <Stack direction="row" spacing={1} sx={{justifyContent:'center'}}>
                                        


                                        <IconButton sx={{color:'#57606f'}} onClick={() => {handleOpenViewReportDialog(row._Id)}}>
                                            <VisibilityIcon />
                                        </IconButton>




                                    </Stack>

                                    
                                </StyledTableCell>
                              </StyledTableRow>

                            )) 
                            : <Box textAlign='center'>
                                <Typography variant="h6" theme={fontTheme}>داده ای یافت نشد</Typography>
                              </Box>
                            }
                        
                                
                        
                            
                        </TableBody>
                          
                      </Table>
                  </TableContainer>
                  : 
                  <Box textAlign='center'>
                    <CircularProgress disableShrink />
                  </Box>
                  }
                </Grid>

            </Box>


            <GE_ViewReportPaperDialog/>

        
        </>
     );
}
 
export default GeReportPaperList;
import React, { useState , createContext } from 'react';
import axios from "axios";
import { useEffect } from 'react';
import AuthService from './../Services/AuthService';

export const ProfileContext = createContext();

const ProfileContextProvider = (props) => {


    const authservice = new AuthService();

    const [openEditProfileDialog,setopenEditProfileDialogDialog] = useState(false);
    
    //const [profileUserId,setColleguaId] = useState('0');
    const [profileUserName,setprofileUserName] = useState('');
    const [profileUserLastName,setprofileUserLastName] = useState('');
    const [profileUserEmail,setprofileUserEmail] = useState('');
    const [profileUserPassword,setprofileUserPassword] = useState('');
    const [profileUserProfilePic,setprofileUserProfilePic] = useState('');

    const [username,setUsername] = useState('');
    const [userImage,setUserImage] = useState('');
    const [userPos,setUserPos] = useState('');

    const [herdName,setHerdName] = useState();
    const [herdLogo,setHerdLogo] = useState();



    const [successAlertPop,setSuccessAlertPop] = useState(false);

    const [SuccessMessage,setSuccessMessage] = useState('');
    const [messageSeverity,setMessageSeverity] = useState('success');

    const handleCloseSuccessAlertPop = () => {
        
        setSuccessAlertPop(false);
        // setSuccessMessage('');
        // setMessageSeverity('success');
    }

    const handleOpenSuccessAlertPop = (Severity,Message) => {
        setSuccessMessage(Message);
        setMessageSeverity(Severity);
        setSuccessAlertPop(true);
    }


    const isPasswordValid = (pass) => {
        const hasLowerCase = /[a-z]/.test(pass);
        const hasDigit = /\d/.test(pass);
        const isLengthValid = pass.length >= 6;
    
        return hasLowerCase && hasDigit && isLengthValid;
    };


    
    useEffect(async () => {
        var authuser = await authservice.AuthGetUser();

        setprofileUserName(authuser.profile.Name);
        setprofileUserLastName(authuser.profile.LastName);
        setprofileUserEmail(authuser.profile.email);
        setprofileUserPassword('');

        setUsername(authuser.profile.Name+" "+authuser.profile.LastName);
        setUserPos(authuser.profile.Position);

        // if(authuser.profile.ProfilePic != '' || authuser.profile.ProfilePic !== undefined){
        //     setprofileUserProfilePic(authuser.profile.ProfilePic);
        //     setUserImage(authuser.profile.ProfilePic);
        // }

        var profileimage = await axios.get(`${process.env.REACT_APP_IDENTITY_BASE_URL}api/UserComon?email=${authuser.profile.email}`);
        if(profileimage.data != ""){
            setUserImage(profileimage.data);
        }


        await GetInfo(authuser.profile.Herd);

    },[]);


    const handleOpenEditProfileDialog = () =>{
        
        setopenEditProfileDialogDialog(true);
    }

    const handleCloseEditProfileDialog = () =>{
        setopenEditProfileDialogDialog(false);
    }



    const GetProfileData = async () => {
        var authuser = await authservice.AuthGetUser();

        setUsername(authuser.profile.Name+" "+authuser.profile.LastName);
        setUserPos(authuser.profile.Position);

        var profileimage = await axios.get(`${process.env.REACT_APP_IDENTITY_BASE_URL}api/UserComon?email=${authuser.profile.email}`);

        if(profileimage.data != ""){
            setUserImage(profileimage.data);
        }

    }


    const GetInfo = async (herdId) => {

        var authuser = await authservice.AuthGetUser();
        
        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authuser.access_token
        };


        var herdInfo = await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/GetHerdById?Id=${herdId}`,{headers});
        setHerdName(herdInfo.data.data.name);
        setHerdLogo(herdInfo.data.data.logo);

        

    }


    const [isTypingPassword,setIsTypingPassword] = useState(false);

    useEffect(() => {
        
        if(profileUserPassword.length > 0){
            setIsTypingPassword(true);
        }
        else{
            setIsTypingPassword(false);
        }

    },[profileUserPassword]);


    const EditUserProfileData = async (userObj) => {


        try{

            var validationFlag = true;

            if(profileUserPassword.length > 0){

                if(!isPasswordValid(profileUserPassword)){

                    validationFlag = false;

                    handleOpenSuccessAlertPop("error","رمز عبور غیر قابل قبول ..!");


                }

            }

            if(validationFlag){
                var rez = await axios.put(`${process.env.REACT_APP_IDENTITY_BASE_URL}api/HerdAccount/updateherduser`,userObj);

                GetProfileData();
                handleCloseEditProfileDialog();

                handleOpenSuccessAlertPop("success","کاربر ویرایش شد.");

            }


        }
        catch(e){
            handleOpenSuccessAlertPop("error","خطا..!");

            console.log(e);
        }



    }

    return (

        <ProfileContext.Provider

            value={{
                openEditProfileDialog,
                handleOpenEditProfileDialog,
                handleCloseEditProfileDialog,
                profileUserName,
                setprofileUserName,
                profileUserLastName,
                setprofileUserLastName,
                profileUserEmail,
                setprofileUserEmail,
                profileUserPassword,
                setprofileUserPassword,
                profileUserProfilePic,
                setprofileUserProfilePic,
                EditUserProfileData,
                username,
                userImage,
                userPos,
                herdName,
                herdLogo,
                isTypingPassword,
                SuccessMessage,
                messageSeverity,
                handleCloseSuccessAlertPop,
                handleOpenSuccessAlertPop,
                successAlertPop,
            }}
        
        >
            {props.children}
        </ProfileContext.Provider>

    );

}

export default ProfileContextProvider;

import React, { useContext, useState } from 'react';
import Paper from '@mui/material/Paper';
import {  Grid, Stack, Typography, FormGroup, FormControlLabel, TextField, FormControl, FormLabel, InputLabel,Select, MenuItem, IconButton, Chip } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { HerdIndividualListContext } from '../Context/HerdIndividualListContext';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import IranSans from '../font/IranSans.ttf';


const fontTheme = createTheme({
    typography: {
      fontFamily: 'IranSans',
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'IranSans';
            src: local('IranSans'), url(${IranSans}) format('truetype');
          }
        `,
      },
    },
  });

  const IndividualCattleFilter = () => {

    console.log("F1");

    const {
        ApplyFilter,
        filterTag,
        setFilterTag,
        filterSire,
        setFilterSire,
        filterMgs,
        setFilterMgs,
        filterMggs,
        setFilterMggs,
        filterLac,
        setFilterLac,
        filterBirthYear,
        setFilterBirthYear,
        rangeIndexList,
        AppendToRangeIndexList,
        RemoveFromRangeIndexList
    } = useContext(HerdIndividualListContext); 


    const [indexFilter,setIndexFilter] = useState("NET_MERIT");
    const [minFilter,setMinFilter] = useState();
    const [maxFilter,setMaxFilter] = useState();


    const AddToRangeIndexFilter = () => {

        var rangeObj = {
            Index : indexFilter,
            MinValue: parseFloat(minFilter),
            MaxValue: parseFloat(maxFilter)

        };

        setMinFilter('');
        setMaxFilter('');

        AppendToRangeIndexList(rangeObj);


    }


    return (

        <>
        
        <Accordion>

        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="filter-content"
            id="filter-header"
            >
    <Typography variant='h6' style={{fontWeight:'bold'}} theme={fontTheme}>فیلتر</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Stack spacing={4}>
                <Stack direction="row" spacing={2}>
                    <TextField value={filterTag} onChange={(e)=>setFilterTag(e.target.value)} id="tag" label="Ear TAG" variant="outlined" />
                    <TextField value={filterSire} onChange={(e)=>setFilterSire(e.target.value)}  id="sire" label="SIRE" variant="outlined" />
                    <TextField value={filterMgs} onChange={(e)=>setFilterMgs(e.target.value)}  id="mgs" label="MGS" variant="outlined" />
                    <TextField value={filterMggs} onChange={(e)=>setFilterMggs(e.target.value)}  id="mggs" label="MGGS" variant="outlined" />
                </Stack>
                <Stack direction="row" spacing={2}>
                    <TextField value={filterLac} onChange={(e)=>setFilterLac(e.target.value)} type='number' id="lactNo" label="شکم" variant="outlined" />
                    <TextField value={filterBirthYear} onChange={(e)=>setFilterBirthYear(e.target.value)} type='number' id="byear" label="سال تولد" variant="outlined" />
                </Stack>
                <Stack direction="row" spacing={2}>
                    <FormControl fullWidth theme={fontTheme}>
                        <InputLabel id="demo-simple-select-label" style={{background:"#fff"}}>
                        شاخص / صفت
                        </InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="استان"
                        value={indexFilter}
                        defaultValue={indexFilter}
                        onChange={(e) => { setIndexFilter(e.target.value) }}

                        >
                            <MenuItem
                                key="NET_MERIT"
                                fullWidth
                                theme={fontTheme}
                                value="NET_MERIT"
                            >
                                LNM
                            </MenuItem>

                            <MenuItem
                                key="FLUID_MERIT"
                                fullWidth
                                theme={fontTheme}
                                value="FLUID_MERIT"
                            >
                                LFM
                            </MenuItem>

                            <MenuItem
                                key="MILK"
                                fullWidth
                                theme={fontTheme}
                                value="MILK"
                            >
                                MILK
                            </MenuItem>

                            <MenuItem
                                key="PL"
                                fullWidth
                                theme={fontTheme}
                                value="PL"
                            >
                                PL
                            </MenuItem>

                            <MenuItem
                                key="DPR"
                                fullWidth
                                theme={fontTheme}
                                value="DPR"
                            >
                                DPR
                            </MenuItem>

                            <MenuItem
                                key="SCE"
                                fullWidth
                                theme={fontTheme}
                                value="SCE"
                            >
                                SCE
                            </MenuItem>

                            <MenuItem
                                key="PTAT"
                                fullWidth
                                theme={fontTheme}
                                value="PTAT"
                            >
                                PTAT
                            </MenuItem>

                        </Select>
                    </FormControl>
                    <TextField value={minFilter} onChange={(e)=>setMinFilter(e.target.value)} type='number' id="indexmin" label="Min" variant="outlined" />
                    <TextField value={maxFilter} onChange={(e)=>setMaxFilter(e.target.value)} type='number' id="indexmax" label="Max" variant="outlined" />
                    <IconButton onClick={AddToRangeIndexFilter}>
                        <AddCircleIcon/>
                    </IconButton>
                </Stack>
                <Grid style={{display:"flex",flexWrap:"wrap",}} spacing={2}>

                    {rangeIndexList.map((range) => (
                        <Chip key={range.Index} style={{margin:2}} label={`${range.Index}    min: ${range.MinValue}  max: ${range.MaxValue}`} onDelete={() => {RemoveFromRangeIndexList(range.Index)}}/>
                    ))}

                </Grid>
                <Grid>
                    <Button variant="contained" onClick={ApplyFilter} >Filter</Button>
                    <Button variant="text">Reset</Button>
                </Grid>

            </Stack>
        </AccordionDetails>

        </Accordion>
    </>


    );

  }

  export default IndividualCattleFilter;
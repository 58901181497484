import { UserManager } from 'oidc-react';
import { WebStorageStateStore } from 'oidc-client-ts';
import axios from 'axios';

export default class AuthService {


    UserManager;

    constructor() {

        var config = {
            userStore: new WebStorageStateStore({store: window.localStorage}),
            authority: process.env.REACT_APP_IDENTITY_BASE_URL,
            client_id: process.env.REACT_APP_CLIENT_ID,
            redirect_uri: `${process.env.REACT_APP_CLIENT_URL}/signincallback`,
            client_secret: process.env.REACT_APP_CLIENT_CODE,
            response_type: "code",
            scope:"openid profile HerdManagementApi",
            post_logout_redirect_uri : `${process.env.REACT_APP_CLIENT_URL}/logout`,
            loadUserInfo: true
            };

        this.UserManager = new UserManager(config);

        this.UserManager.events.addSilentRenewError((e) => {
            console.log("silent renew error", e.message);
        });

        this.UserManager.events.addAccessTokenExpired(() => {
            console.log("token expired");
            this.signinSilent();
        });


    }



    AuthGetUser = async () => {
        
        const user = await this.UserManager.getUser();
        if (!user) {
            return await this.UserManager.signinRedirectCallback();
        }

        return user;
    }

    signinSilent = () => {
        this.UserManager.signinSilent({scope: "openid profile HerdManagementApi", response_type: "refresh_token",client_id:'GEClient'})
            .then((user) => {
                console.log("signed in", user);
            })
            .catch((err) => {
                console.log(err);
                localStorage.clear();
                return this.UserManager.signinRedirect();
                
            });
    };
    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback();
    };


    logout = () => {


        this.UserManager.signoutRedirect({
            id_token_hint: localStorage.getItem("id_token")
        });
        this.UserManager.clearStaleState();
    };

    signoutRedirectCallback = () => {

        this.UserManager.signoutRedirectCallback().then(() => {
            localStorage.clear();
            window.location.replace(`${process.env.REACT_APP_CLIENT_URL}`);
        });
        this.UserManager.clearStaleState();
    };


}
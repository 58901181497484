export default class HeaderDictionary {


    static Headers = {
        NET_MERIT : "LNM$",
        FLUID_MERIT : "LFM$",
        CHEESE_MERIT : "CM$",
        GM: "GM$",
        MILK: "MILK",
        FAT: "FAT",
        PROTEIN : "PROTEIN",
        FAT_P : "FAT%",
        PROTEIN_P : "PROTEIN%",
        SCS: "SCS",
        PL: "PL",
        DPR: "DPR",
        SCE: "SCE",
        DCE: "DCE",
        SSV: "SSB",
        DSB: "DSB",
        CCR: "CCR",
        HCR: "HCR",
        EarlyFirstCalving: "EFC",
        CowLivAbility: "CLIV",
        HeiferLivability: "HLIV",
        FeedSaved: "FSAV",
        BodyComposite: "BWC",
        GestationLenght: "GL",
        MilkFever: "MFEV",
        DisplacedAbomasum: "DA",
        Ketosis: "KETO",
        Mastitis: "MAST",
        Metritis: "METR",
        RetainedPlacenta: "RETP",
        PTAT: "PTAT",
        UDC: "UDC",
        Foot_Legs_Comp: "FLC",
        Stature: "Stature",
        Strength: "Strength",
        Body_Depth: "Body Depth",
        Dairy_Form: "Dairy Form",
        Rump_Angle: "Rump Angle",
        Thurl_Width: "Thurl Width",
        Rear_Legs_Side_View: "RSLV",
        Foot_Angle: "Foot Angle",
        Fore_Uder_Attachment: "Fore Udder Attachment",
        Rear_Udder_Hight: "Rear Udder Hight",
        Rear_Udder_Width: "Rear Udder Width",
        Uder_Cleft: "Udder Cleft",
        Udder_Depth: "Udder Depth",
        Fore_Teat_Placement: "Fore Teat Placement",
        Teat_Lenghth: "Teat Lenghth",
        Rear_Legs_Rear_view: "RLRV",
        DairyComposite: "Dairy Composite",
        FootLegsCompScore: "FLS",
        ForeTeatPlacement: "Fore Teat Placement"


    }

    static SecondaryHeader = {neT_MERIT:"LNM$",
    fluiD_MERIT:"LFM$",
    cheesE_MERIT:"CM$",
    gm:"GM",
    milk:"MILK",
    fat:"FAT",
    protein:"PROTEIN",
    faT_P:"FAT %",
    proteiN_P:"PROTEIN %",
    scs:"SCS",
    pl:"PL",
    dpr:"DPR",
    sce:"SCE",
    dce:"DCE",
    ssb:"SSB",
    dsb:"DSB",
    ccr:"CCR",
    hcr:"HCR",
    earlyFirstCalving:"EFC",
    cowLivAbility:"CLIV",
    heiferLivability:"HLIV",
    feedSaved:"FSAV",
    bodyComposite:"BWC",
    gestationLenght:"GL",
    milkFever:"MFEV",
    displacedAbomasum:"DA",
    ketosis:"KETO",
    mastitis:"MAST",
    metritis:"METR",
    retainedPlacenta:"RETP",
    ptat:"PTAT",
    udc:"UDC",
    foot_Legs_Comp:"FLC",
    stature:"Stature",
    strength:"Strength",
    body_Depth:"Body Depth",
    dairy_Form:"Dairy Form",
    rump_Angle:"Rump Angle",
    thurl_Width:"Thurl Width",
    rear_Legs_Side_View:"RSLV",
    foot_Angle:"Foot Angle",
    fore_Uder_Attachment:"Fore Udder Attachment",
    rear_Udder_Hight:"Rear Udder Hight",
    rear_Udder_Width:"Rear Udder Width",
    uder_Cleft:"Udder Cleft",
    udder_Depth:"Udder Depth",
    teat_Lenghth:"Teat Lenghth",
    rear_Legs_Rear_view:"RLRV",
    dairyComposite:"Dairy Composite",
    footLegsCompScore:"FLS",
    foreTeatPlacement:"Fore Teat Placement"
    }


}
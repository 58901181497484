import React, {useState,useEffect} from 'react';
import { Box, Container, Grid, Paper, Typography, Table } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { useTheme } from '@mui/material/styles';

import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import SwipeableViews from 'react-swipeable-views';

import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';



  function TabPanel(props) {
    

    const { children, value, index, ...other } = props;
  

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box style={{height:'100%'}}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#34495e",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));




const DifferemceProgress = (props) => {

  var evalD = props.evalDate;

  const [value, setValue] = React.useState(0);

  const [chartDataTableOpen,setChartDataTableOpen] = useState('');

  const exportToExcel = () => {


      const table = document.getElementById('progressChartTable');
      const worksheet = utils.table_to_sheet(table);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = write(workbook, { type: 'array', bookType: 'xlsx' });
      const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(data, 'Herd_'+props.ProgressDs[value].Index+'_Progress.xlsx');
  };

    useEffect(() => {
      const buttonElement = document.getElementById('progresschartExport');
      if (buttonElement) {
        buttonElement.addEventListener('click', exportToExcel);
      }

    },[chartDataTableOpen]);

  

  const setChart = (index) => {


    

    const WaterFallOption = {
      title: {
        text: props.ProgressDs[index].Index+' پیشرفت {a|            }',
        right:'right',
        subtext: evalD+' ارزیابی ',
        textStyle:{
    
          rich: {

            a: {
                backgroundColor: {
                    image: './logo.png'
                },
                height: 50
            },

          }
        }
      },

      toolbox: {
        left:'auto',
        show: true,
        feature: {
          dataZoom: {
            yAxisIndex: "none"
          },
          dataView: {
            readOnly: false,
            optionToContent: function(opt){

              setChartDataTableOpen(props.ProgressDs[index].Index);
              var table = "<button id='progresschartExport'> Export excel </button>";
              table += '<table style="width:100%;text-align:center;" id="progressChartTable"><tbody><tr>'
                     + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;">سال تولد</th>'
                     + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;">'+props.ProgressDs[index].Index+'</th>'
                     + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;">پیشرفت</th>'
                     + '</tr>';
              for (var i = 0; i < props.years.length; i++) {

                    if(i == 0){
                      table += '<tr style="background:#E7EAEF">'
                      + '<td>' + props.years[i] + '</td>'
                      + '<td>' + props.ProgressDs[index].ValueHolder[i].toFixed(1) + '</td>'
                      + '<td> - </td>'
                      + '</tr>';
                    }
                    else if(props.ProgressDs[index].telorance[i] > 0){
                      table += '<tr style="background:#E7EAEF">'
                      + '<td>' + props.years[i] + '</td>'
                      + '<td>' + props.ProgressDs[index].ValueHolder[i].toFixed(1) + '</td>'
                      + '<td style="color:#0F8E28">+' + props.ProgressDs[index].telorance[i].toFixed(1) + '</td>'
                      + '</tr>';
                    }
                    else if(props.ProgressDs[index].telorance[i] < 0){
                      table += '<tr style="background:#E7EAEF">'
                      + '<td>' + props.years[i] + '</td>'
                      + '<td>' + props.ProgressDs[index].ValueHolder[i].toFixed(1) + '</td>'
                      + '<td style="color:#E4271D">' + props.ProgressDs[index].telorance[i].toFixed(1) + '</td>'
                      + '</tr>';
                    }
                    else{
                      table += '<tr style="background:#E7EAEF">'
                      + '<td>' + props.years[i] + '</td>'
                      + '<td>' + props.ProgressDs[index].ValueHolder[i].toFixed(1) + '</td>'
                      + '<td>' + props.ProgressDs[index].telorance[i].toFixed(1) + '</td>'
                      + '</tr>';
                    }


                  }
                  
              table += '</tbody></table>';
              return table;
            }
          },
          saveAsImage: {
            name: props.ProgressDs[index].Index+' پیشرفت',
          }
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: function (params) {
          let tar;
  
          var indexName = params[0].seriesName;
          var arr = props.ProgressDs.filter(x=>x.Index == indexName)[0].ValueHolder;

          

          if (params[1].value !== '-') {
            tar = arr[params[0].dataIndex];
          } else {
            tar = arr[params[0].dataIndex];
          }

          return  indexName + " : " + tar.toFixed(1);
        }
      },
      legend: {
        data: ['Increase', 'Decrease']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '8%',
        top: '25%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: props.years
      },
      yAxis: {
        type: 'value'
      },
      series: [
        
        {
          name: props.ProgressDs[index].Index,
          type: 'bar',
          stack: 'Total',
          itemStyle: {
            borderColor: 'transparent',
            color: 'transparent'
          },
          emphasis: {
            itemStyle: {
              borderColor: 'transparent',
              color: 'transparent'
            }
          },
          data: props.ProgressDs[index].holder
        },
        {
          name: 'Increase',
          type: 'bar',
          stack: 'Total',
          label: {
            show: true,
            position: 'top'
          },
          itemStyle: {
            normal: {

                
                barBorderRadius: 12,
            },
          },
          color:'#27ae60',
          data: props.ProgressDs[index].increase
        },
        {
          name: 'Decrease',
          type: 'bar',
          stack: 'Total',
          label: {
            show: true,
            position: 'bottom'
          },
          itemStyle: {
            normal: {

                
                barBorderRadius: 12,
            },
          },
          color:'#e74c3c',
          data: props.ProgressDs[index].decrease
        }
      ]
    };

    return WaterFallOption;

  }


  const [waterfallChart,setWaterfallChart] = React.useState(setChart(0));

  

  const theme = useTheme();
        


  const handleChange = (event, newValue) => {
    setValue(newValue);
    setWaterfallChart(setChart(newValue));
    
  };

  const handleChangeIndex = (index) => {
    setValue(index);
    
  };

  



  return ( 

      <Container sx={{marginTop:5,marginBottom:5}} style={{direction:"ltr"}}>

          <Grid container spacing={2}>

              <Grid md={8} item>

                  <Paper elevation={3} style={{height:500,paddingTop:5}}>
                      
                    <ReactECharts option={waterfallChart} style={{height:'100%'}}/>

                  </Paper>
              </Grid>

              <Grid md={4} item>
                  <Paper elevation={3} style={{height:500,paddingTop:5}}>
                    

                    <Tabs value={value} onChange={handleChange} aria-label="tabs example">
                
                    {props.ProgressDs.map((ind) => (

                      <Tab key={ind.Index+"_HTAB"} label={ind.Index} />

                    ) )}

                    </Tabs>
                    <SwipeableViews
                      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                      index={value}
                      onChangeIndex={handleChangeIndex}
                      >

                      {props.ProgressDs.map((ind,i) => (


                        <TabPanel key={ind.index} value={value} index={i} dir={theme.direction}>

                          <Table aria-label="progressTable" >
                            <TableHead>
                              <StyledTableRow>
                                <StyledTableCell>متولدین</StyledTableCell>
                                <StyledTableCell>پیشرفت</StyledTableCell>
                              </StyledTableRow>

                            </TableHead>
                            <TableBody>

                              {props.ProgressDs.filter(x=>x.Index == ind.Index).map(x=>x.telorance)[0].map((record,j) => (

                                <StyledTableRow
                                  key={ind.Index+j}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >

                                  <StyledTableCell component="th" scope="row">
                                      {props.years[j]}
                                  </StyledTableCell>

                                  <StyledTableCell component="th" scope="row">
                                      {record.toFixed(1)}
                                  </StyledTableCell>

                                </StyledTableRow>

                              ))}

                              




                              






                            </TableBody>

                          </Table>

                        </TabPanel>


                      ))};



                    </SwipeableViews>



                    

                  </Paper>
              </Grid>

          </Grid>

      </Container>


    );
}
 
export default DifferemceProgress;
import { Box , Typography , Paper , Avatar, Container , Grid , Chip, ListItemText} from "@mui/material";
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import React , {useContext} from "react";
import { IndividualDashboardContext } from '../Context/IndividualDashboardContext';

const HerdContactInfo = () => {

    const {herdContactInfo} = useContext(IndividualDashboardContext)
    console.log('contactInfo', herdContactInfo)
    console.log(herdContactInfo)
    return(
        <>
            <Paper sx={{direction:'rtl',padding:'10px'}}>
                <Container>
                    <Grid container spacing={2} direction="row">
                        <Grid item>
                            <Box component="div">
                                {herdContactInfo.logo != "" || herdContactInfo.logo != null ? <Avatar  alt="Remy Sharp" src={herdContactInfo.logo} sx={{width:'50px' , height:'50px'}}/> : <Avatar  alt={herdContactInfo.name} sx={{width:'50px' , height:'50px'}}> <MapsHomeWorkIcon/> </Avatar>}
                            </Box>
                        </Grid>
                        <Grid item spacing={2}>
                            <Typography>
                                Name : {herdContactInfo.name ? herdContactInfo.name : '-'}
                            </Typography>
                            <Typography>
                                Address : {herdContactInfo.address ? herdContactInfo.address : '-'}
                            </Typography>
                            <Typography>
                                contacts : {Array.isArray(herdContactInfo.contactList) && herdContactInfo.contactList.length != 0 
                                ? herdContactInfo.contactList.map((item,i) => (
                                    <Chip key={i} label={item.title + ' : ' + item.contact} style={{margin : '5px', direction : 'ltr'}}/>
                                )) : <>-</>}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </>
                

    )
}

export default HerdContactInfo
import React , {useContext, useState, useEffect}  from 'react';
import { Paper, Box, Typography, Stack, Grid, TextField, Avatar,Snackbar, FormControl, FormLabel, InputLabel, Select, MenuItem, Switch, FormGroup, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Container } from '@mui/material';
import IranSans from '../font/IranSans.ttf';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useDropzone } from "react-dropzone";
import { height } from '@mui/system';
import PersonIcon from '@mui/icons-material/Person';
import { ProfileContext } from '../Context/ProfileContext';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

var Image64Base = "";

const convertBase64 = (f) => {
    const file = f;
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }


  const handleFileRead = async (f) => {
    const file = f
    const base64 = await convertBase64(file)
    Image64Base = base64;
  }


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


//-----------image preview--------

const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    height: "100%",
};
  
const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    width: "115px",
    height: "115px",
    padding: 4,
    boxSizing: "border-box",
    borderRadius: '60px'
  
};
  
const thumbInner = {
    display: "flex",
    width:'100%',
    overflow: "hidden",
    alignItems: "center",
    justifyContent : "center"
};
  
const img = {
    display: "block",
    width: "100%",
    height: "auto",
    margin: "auto",
};
  

function urltoFile(url, filename, mimeType){
    return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename,{type:mimeType});})
    );
}

  
function Previews(props) {
    const [files, setFiles] = useState([""]);
    

    const { getRootProps, getInputProps } = useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });
  
    const thumbs = files.map((file) => (

        
      <div style={thumb} key={file.name}>
        
        <div style={thumbInner}>
          {/* <img src={file.preview} style={img} /> */}

            {props.profilePic == null || props.profilePic == '' ? 
            
            <Avatar  alt="Logo" src={file.preview} sx={{width:'100px' , height:'100px'}}/>
            :
            <Avatar  alt="Logo" src={file.preview} sx={{width:'100px' , height:'100px'}}>

              <img src={props.profilePic} id="test" style={{width:'100px' , height:'100px'}}/>

            </Avatar>
            }

            
        </div>
      </div>
    ));
  
    useEffect(() => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => {URL.revokeObjectURL(file.preview);handleFileRead(file);});
      

    }, [files]);
  
    return (
      <section
        className="container"
        style={{
          //border: "dashed 1px #000",
          height: "fit-content",
          width: "fit-content",
          position: "relative",
          borderRadius : "60px",
          marginTop : "-80px",
          justifyContent : 'center',
          background : '#fff'
        }}
      >
        <div
          style={{
            textAlign: "center",
            height: "100%",
            width: "100%",
            position: "absolute",
            
            zIndex: 10000
          }}
          {...getRootProps({ className: "dropzone" })}
        >
          <input {...getInputProps()} />
          
        </div>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </section>
    );
  }
  
  


const ProfileEditDialog = (props) => {

    const {openEditProfileDialog,
        handleCloseEditProfileDialog,
        profileUserName,
        setprofileUserName,
        profileUserLastName,
        setprofileUserLastName,
        profileUserEmail,
        setprofileUserEmail,
        profileUserPassword,
        setprofileUserPassword,
        profileUserProfilePic,
        EditUserProfileData,
        userImage,
        isTypingPassword,
        SuccessMessage,
        messageSeverity,
        handleCloseSuccessAlertPop,
        successAlertPop} = useContext(ProfileContext);


    const EditProfile = () => {

      var colleagueobj = {
        Id : "0",
        Name : profileUserName,
        LastName : profileUserLastName,
        Position : "",
        Email : profileUserEmail,
        Password : profileUserPassword,
        ProfilePic : Image64Base,
        AccessList : []
      };


      EditUserProfileData(colleagueobj);

    }


    return ( 

        <Dialog
            fullScreen
            open={openEditProfileDialog}
            onClose={handleCloseEditProfileDialog}
            TransitionComponent={Transition}
            PaperProps={{
                style: {
                backgroundColor: '#f4f4f4',
                
                },
            }}
        >

            <AppBar sx={{ position: "relative", background: "#00a594"}}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleCloseEditProfileDialog}
                    aria-label="close"
                    >
                    <CloseIcon />
                    </IconButton>
                    <Typography
                    
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                    >
                       ویرایش حساب کاربری
                    </Typography>
                    <Button
                        
                        autoFocus
                        color="inherit"
                        onClick={EditProfile}
                        >
                        ثبت
                    </Button>
                </Toolbar>
            </AppBar>


            <Container  component="form" noValidate autoComplete="off" sx={{paddingTop:7}}>
                

                
            <Grid container spacing={3} sx={{paddingTop:3}}>

                <Grid item xs={12} sm={12} md = {7} lg={7} xl={7}>

                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <Paper sx={{padding:3,display:'flex'}}>

                            <Grid container>
                                <Grid xs={12} sm={12} md={12} lg={12} xl ={12} item sx={{width:'100%',display:'flex',justifyContent:'center'}}>

                                <Previews profilePic={userImage}/>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl ={12} sx={{paddingTop:2}}>

                                <Stack spacing={3}>
                                    <TextField value={profileUserName} id="outlined-basic" label="نام" variant="outlined" onChange={(e)=>setprofileUserName(e.target.value)}/>

                                    <TextField value={profileUserLastName} id="outlined-basic" label="نام خانوادگی" variant="outlined" onChange={(e)=>setprofileUserLastName(e.target.value)}/>
                                    
                                    
                                </Stack>


                                </Grid>

                            </Grid>

                            </Paper>
                        </Grid>
                    </Grid>



                </Grid>


                <Grid item xs={12} sm={12} md = {5} lg={5} xl={5}>

                    <Paper sx={{padding:3,display:'flex'}}>

                        <Stack spacing={3} sx={{width:'100%',display:'flex',justifyContent:'center'}}>
                            <TextField onChange={(e)=>setprofileUserEmail(e.target.value)} value={profileUserEmail} id="outlined-basic" label="پست الکترونیک" variant="outlined" />
                            <TextField onChange={(e)=>setprofileUserPassword(e.target.value)} value={profileUserPassword}  id="outlined-basic" label="رمز عبور" variant="outlined" />
                            {isTypingPassword ?    
                            <ul>
                              <li style={{ color: profileUserPassword.length >= 6 ? 'green' : 'red' }}>
                                رمز عبور باید حداقل 6 کاراکتر باشد
                              </li>
                              <li style={{ color: /[a-z]/.test(profileUserPassword) ? 'green' : 'red' }}>
                                رمز عبور باید شامل حروف باشد
                              </li>
                              <li style={{ color: /\d/.test(profileUserPassword) ? 'green' : 'red' }}>
                                رمز عبور باید شامل عدد باشد
                              </li>
                                      
                            </ul>

                            : <></>  }
                        </Stack>

                    </Paper>

                </Grid>
                


            </Grid>
                
            <Snackbar open={successAlertPop} autoHideDuration={6000} onClose={handleCloseSuccessAlertPop}>
                <Alert onClose={handleCloseSuccessAlertPop} severity={messageSeverity} sx={{ width: '100%' }}>
                 {SuccessMessage}
                </Alert>
            </Snackbar>
        </Container>
    </Dialog>


    );
}
 
export default ProfileEditDialog;
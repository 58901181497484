import React, { useState, setState , createContext , useEffect, useContext} from 'react';
import axios from "axios";
import AuthService from '../Services/AuthService';

export const GEReportContext = createContext()


const GEReportContextProvider = (props) => {
    const authservice = new AuthService();

    const [reportHerdId,setReportHerdId] = useState();

    const [openReportEditorDialog,setOpenReportEditorDialog] = useState(false);
    
    const [ReportEditFlag,setRepotEditFlag] = useState(false);
    const [ReportEditContent,setReportEditContent] = useState('');
    const [ReportToEdit,setReportToEdit] = useState('');
    const [ReportToEditTitle,setReportToEditTitle] = useState('');

    const handleOpenReportEditorDialog = (flag,content,id,title) => {
        if(flag){
            setRepotEditFlag(true);
            setReportEditContent(content);
            setReportToEdit(id);
            setReportToEditTitle(title);
        }
        setOpenReportEditorDialog(true);
    }

    const handleCloseReportEditorDialog = () => {

        setOpenReportEditorDialog(false);

        setRepotEditFlag(false);
        setReportEditContent('');
        setReportToEdit('');
        setReportToEditTitle('');
    }

    const [reportPaperList,setReportPaperList] = useState();


    const [SelectedReport,setSelectedReport] = useState();

    const [openViewReportDialog,setOpenViewReportDialog] = useState(false);
    
    const handleOpenViewReportDialog = (reportId) => {
        
        let report = reportPaperList.filter(x => x._Id == reportId);
        setSelectedReport(report);
        setOpenViewReportDialog(true);
    }

    const handleCloseViewReportDialog = () => {
        setSelectedReport();
        setOpenViewReportDialog(false);
    }

    
    
    



    const AddReportPaper = async (reportObj) => {

        

        if(reportObj !== undefined){
            var authuser = await authservice.AuthGetUser();

            const headers = {
                Accept: 'application/json',
                Authorization: 'Bearer ' + authuser.access_token
            };

            if(!ReportEditFlag){
                

                var response = await axios.post(`${process.env.REACT_APP_GATEWAY_BASE_URL}/HerdReport/addreport`,reportObj,{headers});

            }
            else{

                var removeresponse = await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/HerdReport/removereport?ReportId=${ReportToEdit}`,{headers}).then(async () => {
                    var editresponse = await axios.post(`${process.env.REACT_APP_GATEWAY_BASE_URL}/HerdReport/addreport`,reportObj,{headers});

                });

                setRepotEditFlag(false);
                setReportEditContent('');
                setReportToEdit('');
                setReportToEditTitle('');


            }

            
            await GetReportPaperList();

            handleCloseReportEditorDialog();

        }

    }


    //-----------remove report paper
    const [ReportToDelete,setReportToDelete] = useState('');
    const [OpenReportPaperDeleteDialog,setOpenReportPaperDeleteDialog] = useState(false);

    const handleOpenReportPaperDeleteDialog = (ReportId) => {
        setReportToDelete(ReportId);
        setOpenReportPaperDeleteDialog(true);
    }

    const handleCloseReportPaperDeleteDialog = () => {
        setOpenReportPaperDeleteDialog(false);
    }

    const RemoveReport = async () => {
        var authuser = await authservice.AuthGetUser();

        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authuser.access_token
        };
        var response = await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/HerdReport/removereport?ReportId=${ReportToDelete}`,{headers});
        setReportToDelete('');
        GetReportPaperList();
        handleCloseReportPaperDeleteDialog();
    }


    //----get list
    const GetReportPaperList = async () => {

        var authuser = await authservice.AuthGetUser();

            const headers = {
                Accept: 'application/json',
                Authorization: 'Bearer ' + authuser.access_token
            };
        await axios.get(`${process.env.REACT_APP_GATEWAY_BASE_URL}/HerdReport/reportlist?HerdId=${reportHerdId}`,{headers}).then(res => {
            setReportPaperList(res.data);
        });
    }


    





    useEffect(() => {
        
        if(reportHerdId !== undefined){
            
            GetReportPaperList();

        }

    },[reportHerdId]);

    return ( 

        <GEReportContext.Provider

            value={{
                openReportEditorDialog,
                handleOpenReportEditorDialog,
                handleCloseReportEditorDialog,
                ReportEditFlag,
                ReportEditContent,
                ReportToEdit,
                ReportToEditTitle,
                reportHerdId,
                setReportHerdId,
                reportPaperList,
                AddReportPaper,
                openViewReportDialog,
                handleOpenViewReportDialog,
                handleCloseViewReportDialog,
                SelectedReport,
                setReportToDelete,
                OpenReportPaperDeleteDialog,
                handleOpenReportPaperDeleteDialog,
                handleCloseReportPaperDeleteDialog,
                RemoveReport


            }}
        
        >
            {props.children}
        </GEReportContext.Provider>


     );
}
 
export default GEReportContextProvider;

